import {
  faChalkboardTeacher,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { useHistory } from "react-router";
import { fetchApiGet } from "../api/api";
import { GetListResponse, Module } from "../api/types";
import { Button } from "./Buttons";

export default function ResourceHint(props: {
  className?: string;
  forAdmin?: boolean;
}): ReactElement {
  const moduleQuery = useGetIntroductionModule();
  const className = props.className != null ? props.className : "";
  const history = useHistory();
  const text = props.forAdmin
    ? {
        firstParagraph:
          "Ytterligare föreläsningar och workshops för fördjupad kunskap.",
        secondParagraph: `Utöver modulerna finns det fristående föreläsningar. Läs mer i ”Introduktionsmodulen” och på hemsidan.`,
      }
    : {
        firstParagraph:
          "Det finns ytterligare föreläsningar som rör innehållet i den här modulen.",
        secondParagraph: `Läs mer längst ner i "Introduktionsmodulen" och på hemsidan.`,
      };

  if (moduleQuery.status === "success" && moduleQuery.data.items.length > 0) {
    if (
      moduleQuery.data.items[0]?.all_media == null ||
      !("show-resource-hints" in moduleQuery.data.items[0].all_media)
    ) {
      return <></>;
    }

    return (
      <div
        className={`bg-fresh-mint bg-opacity-10 flex flex-col sm:flex-row items-start 2xl:items-center gap-6 p-4 ${className}`}
      >
        <div className="hidden sm:block bg-fresh-mint bg-opacity-30 flex px-6 py-4">
          <FontAwesomeIcon icon={faChalkboardTeacher} />
        </div>
        <div className="flex flex-col lg:flex-row md:items-start gap-4 w-full">
          <div className="lg:flex lg:flex-col w-full ">
            <span className="font-bold mr-1">{text.firstParagraph}</span>
            <span>{text.secondParagraph}</span>
          </div>
          <div className="flex flex-wrap sm:flex-nowrap gap-4">
            <Button
              content="Introduktionsmodulen"
              size="medium"
              onClick={() =>
                history.push(`/modul/${moduleQuery.data.items[0].moduleID}`)
              }
            />
            <Button
              content={
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="flex gap-2 items-center"
                  href="https://forskoleutveckling.nu/tillgangliga-resurser/"
                >
                  Hemsidan <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
              }
              size="medium"
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

function useGetIntroductionModule(): UseQueryResult<
  GetListResponse<Module>,
  Error
> {
  return useQuery<GetListResponse<Module>, Error>(
    ["modules", "m_number", "0"],
    () => fetchApiGet<GetListResponse<Module>>("modules?filter[m_number]=0")
  );
}
