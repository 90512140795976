import * as yup from "yup";
export const userRolesSchema = yup.array(
  yup.object().shape({
    role_name: yup.string(),
    role_userID: yup.number(),
    role_organizationID: yup.number().when("role_name", {
      is: (val: { label: string; value: string }) => val.value === "regUser",
      then: yup.number().required(),
      otherwise: yup.number().nullable(),
    }),
    role_clientID: yup.number().when(["role_name", "role_organizationID"], {
      is: (
        roleName: { label: string; value: string },
        roleOrgID: number | undefined
      ) =>
        roleName.value === "clientOrgAdmin" ||
        roleName.value === "admin" ||
        (roleName.value === "orgAdmin" && roleOrgID == null),
      then: yup.number().required(),
      otherwise: yup.number().nullable(),
    }),
  })
);
export const schema = yup.object().shape({
  userRoles: userRolesSchema.when("$required", (required: boolean) =>
    required
      ? userRolesSchema.min(1, "Minst 1 roll måste läggas till")
      : userRolesSchema
  ),
  usr_firstname: yup.string().required("Förnamn är obligatoriskt"),
  usr_lastname: yup.string().required("Efternamn är obligatoriskt"),
  usr_email: yup
    .string()
    .email("E-post kräver en gilltig adress")
    .required("E-post är ett obligatoriskt fält"),
  usr_login_blocked_until: yup.string().nullable().notRequired(),
  usr_password_plain: yup
    .string()
    .notRequired()
    .matches(/^(?=.{8,}$)(?=.*[A-Za-z])(?=.*\d).*$/, {
      message: "Lösenordet måste innehålla minst 8 tecken därav en siffra",
      excludeEmptyString: true,
    }),
  usr_confirmed_password: yup
    .string()
    .oneOf(
      [yup.ref("usr_password_plain"), null],
      "Lösenorden stämmer inte överens"
    ),
});
