import { ReactElement, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { hasRole } from "../../api/Auth";
import { Client, Organization } from "../../api/types";
import { Button } from "../../components/Buttons";
import FormError from "../../components/formLayout/FormError";
import FormInputWrapper from "../../components/formLayout/FormInputWrapper";
import FormLabel from "../../components/formLayout/FormLabel";
import { useAuth } from "../../hooks/ProvideAuth";
import { PrefilledUserRole } from "./user-form-types";

export default function UserRolesSelector(props: {
  organizations: Organization[];
  clients: Client[];
  userRoleOptions: { value: string; label: string }[];
  prefilledUserRole?: PrefilledUserRole;
  onClick: (
    clientIds: { label: string; value: number }[],
    orgIds: { label: string; value: number }[],
    roleNames: { label: string; value: string }
  ) => void;
}): ReactElement | null {
  const auth = useAuth();

  const organizationsOptions = useMemo(
    () =>
      props.organizations.map((o: Organization) => ({
        value: o.organizationID,
        label: o.org_name,
      })),
    [props.organizations]
  );

  const clientOptions = useMemo(
    () =>
      props.clients.map((c: Client) => ({
        value: c.clientID,
        label: c.cl_name,
      })),
    [props.clients]
  );

  const defaultRoleName = useMemo(
    () =>
      props.userRoleOptions.find(
        (o) => o.value === props.prefilledUserRole?.roleName
      ),
    [props.userRoleOptions, props.prefilledUserRole?.roleName]
  );

  const defaultClient = useMemo(
    () =>
      props.prefilledUserRole?.clientID != null
        ? clientOptions.find(
            (co) => co.value === props.prefilledUserRole?.clientID
          )
        : undefined,
    [clientOptions, props.prefilledUserRole?.clientID]
  );

  const defaultOrganization = useMemo(
    () =>
      props.prefilledUserRole?.orgId != null
        ? organizationsOptions.find(
            (co) => co.value === props.prefilledUserRole?.orgId
          )
        : undefined,
    [organizationsOptions, props.prefilledUserRole?.orgId]
  );

  const [clientIds, setClientIds] = useState<
    { label: string; value: number }[]
  >(defaultClient != null ? [defaultClient] : []);
  const [orgIds, setOrgIds] = useState<{ label: string; value: number }[]>(
    defaultOrganization != null ? [defaultOrganization] : []
  );
  const [roleName, setRoleName] = useState<{
    label: string;
    value: string;
  } | null>(defaultRoleName != null ? defaultRoleName : null);
  const [userRoleSelectorErrors, setUserRoleSelectorErrors] = useState<{
    roleName?: string[];
    orgIds?: string[];
    clientIds?: string[];
  }>({});

  useEffect(() => {
    if (props.clients.length === 1) {
      setClientIds([
        { value: props.clients[0].clientID, label: props.clients[0].cl_name },
      ]);
    }
  }, [props.clients]);
  useEffect(() => {
    if (props.organizations.length === 1) {
      setOrgIds([
        {
          value: props.organizations[0].organizationID,
          label: props.organizations[0].org_name,
        },
      ]);
    }
  }, [props.organizations]);

  useEffect(() => {
    switch (roleName?.value) {
      case "regUser":
        if (props.clients.length > 1) setClientIds([]);
        break;
      case "clientOrgAdmin":
        if (props.organizations.length > 1) setOrgIds([]);
        break;
      default:
        if (props.clients.length > 1) setClientIds([]);
        if (props.organizations.length > 1) setOrgIds([]);
        break;
    }
  }, [roleName, props.clients, props.organizations]);

  if (auth?.userRoles == null || auth.highestRole === "regUser") return <></>;

  const resetValues = (): void => {
    // Only reset clients and organizations if they have more than one element. We want the existing one to be prepopulated
    if (props.clients.length > 1) setClientIds([]);
    if (props.organizations.length > 1) setOrgIds([]);

    // reset selected role
    setRoleName(null);
    setUserRoleSelectorErrors({});
  };

  const validateUserRole = (): boolean => {
    setUserRoleSelectorErrors({ roleName: undefined });
    let hasErrors = false;
    switch (roleName?.value) {
      case "regUser":
        if (orgIds.length < 1) {
          hasErrors = true;
          setUserRoleSelectorErrors({
            orgIds: ["Minst en organisation måste väljas"],
          });
        } else {
          setUserRoleSelectorErrors({ orgIds: undefined });
        }
        break;
      case "orgAdmin":
      case "clientOrgAdmin":
        if (roleName.value === "orgAdmin" && orgIds.length > 0) {
          break;
        }
        if (clientIds.length < 1) {
          hasErrors = true;
          setUserRoleSelectorErrors({
            clientIds: ["Minst en uppdragsgivare måste väljas"],
          });
        } else {
          setUserRoleSelectorErrors({ clientIds: undefined });
        }
        break;
      case "admin":
        break;
      default:
        hasErrors = true;
        setUserRoleSelectorErrors({ roleName: ["Minst en roll måste väljas"] });
        break;
    }
    return !hasErrors;
  };

  const handleSubmit = (): void => {
    if (validateUserRole()) {
      if (roleName != null) {
        switch (roleName.value) {
          case "regUser":
            props.onClick([], orgIds, roleName);
            break;
          case "orgAdmin":
            props.onClick(clientIds, orgIds, roleName);
            break;
          case "admin":
            props.onClick([], [], roleName);
            break;
          default:
            props.onClick(clientIds, [], roleName);
            break;
        }
        resetValues();
      }
    }
  };

  return (
    <div className="flex flex-row space-x-6 mt-12 mb-4 w-4/5 items-end">
      <div className="flex-1 flex space-x-8">
        <div className="flex-1">
          <FormInputWrapper>
            <FormLabel htmlFor="userRoleNames" label="Roll" />
            <Select
              name="userRoleNames"
              options={
                props.organizations.length < 1
                  ? props.userRoleOptions.filter((r) =>
                      ["admin", "clientOrgAdmin", "orgAdmin"].includes(r.value)
                    )
                  : props.userRoleOptions
              }
              placeholder="Välj roll(er)"
              noOptionsMessage={() => "Inga alternativ"}
              value={roleName}
              onChange={(e) =>
                setRoleName(e as { label: string; value: string })
              }
            />
            {userRoleSelectorErrors.roleName?.map((e) => (
              <FormError key={e} message={e} />
            ))}
          </FormInputWrapper>
        </div>

        <div className="flex-1">
          <FormInputWrapper>
            <FormLabel htmlFor="userRoleOrgs" label="Organisation" />
            <Select
              isDisabled={
                roleName?.value === "admin" ||
                roleName?.value === "clientOrgAdmin" ||
                (hasRole(auth.userRoles, ["admin"]) && clientIds.length > 0)
              }
              name="userRoleOrgs"
              isMulti
              closeMenuOnSelect={false}
              options={organizationsOptions}
              placeholder="Välj organisation(er)"
              noOptionsMessage={() => "Inga alternativ"}
              value={orgIds}
              onChange={(e) =>
                setOrgIds(e as { label: string; value: number }[])
              }
            />
            {userRoleSelectorErrors.orgIds?.map((e) => (
              <FormError key={e} message={e} />
            ))}
          </FormInputWrapper>
        </div>
        {hasRole(auth.userRoles, ["admin"]) ? (
          <div className="flex-1">
            <FormInputWrapper>
              <FormLabel htmlFor="userRoleClients" label="uppdragsgivare" />

              <Select
                isDisabled={
                  roleName?.value === "regUser" ||
                  roleName?.value === "admin" ||
                  (hasRole(auth.userRoles, ["admin"]) && orgIds.length > 0)
                }
                name="userRoleClients"
                isMulti
                closeMenuOnSelect={false}
                options={clientOptions}
                placeholder="Välj uppdragsgivare"
                noOptionsMessage={() => "Inga alternativ"}
                value={clientIds}
                onChange={(e) =>
                  setClientIds(e as { label: string; value: number }[])
                }
              />
              {userRoleSelectorErrors.clientIds?.map((e) => (
                <FormError key={e} message={e} />
              ))}
            </FormInputWrapper>
          </div>
        ) : null}
      </div>
      <div className="w-32 my-6">
        <Button
          // disabled={!validUserRole}
          type="button"
          width="w-56"
          onClick={handleSubmit}
          content="Lägg till roll/roller"
          size="medium"
        />
      </div>
    </div>
  );
}
