import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router";
import { isNotEmpty } from "../util";

type Alert = {
  message?: string;
  color?: "warning" | "error" | "success" | "primary";
  link?: string;
  timeoutLength: number;
  updateAlert: (
    message: string,
    color?: "warning" | "error" | "success" | "primary",
    link?: string,
    timeoutLength?: number
  ) => void;
  clearAlert: () => void;
};

const AlertContext = createContext<Alert | null>(null);
export function useAlert(): Alert | null {
  return useContext(AlertContext);
}

export function AlertProvider(props: { children: ReactNode }): ReactElement {
  const alert = useAlertProvider();
  return (
    <AlertContext.Provider value={alert}>
      {props.children}
    </AlertContext.Provider>
  );
}

function validateColor(
  input: string | undefined
): input is "warning" | "error" | "success" | "primary" | undefined {
  const colors = ["warning", "error", "success", "primary"];
  return input != null && isNotEmpty(input) && colors.some((c) => c === input);
}

function useAlertProvider(): Alert | null {
  const location = useLocation<
    | {
        alert?: {
          message: string;
          color?: string;
          link?: string;
          timeoutLength?: number;
        };
      }
    | undefined
  >();

  //  State -------------------------- /
  const [message, setMessage] = useState<string | undefined>(
    location.state?.alert?.message
  );
  const [color, setColor] = useState<
    "warning" | "error" | "success" | "primary" | undefined
  >(undefined);
  const [link, setLink] = useState<string | undefined>(undefined);
  const [timeoutLength, setTimeoutLength] = useState<number>(8000);

  useEffect(() => {
    if (location.state?.alert != null) {
      const c = validateColor(location.state.alert.color)
        ? location.state.alert.color
        : undefined;
      updateAlert(
        location.state.alert.message,
        c,
        location.state.alert.link,
        location.state.alert.timeoutLength
      );
      delete location.state.alert;
      window.history.replaceState(location.state, document.title);
    }
  }, [location]);

  // Methods ------------------------ /
  const updateAlert = (
    message: string,
    color?: "warning" | "error" | "success" | "primary",
    link?: string,
    timeoutLength?: number
  ): void => {
    setMessage(message);
    if (color !== undefined) {
      setColor(color);
    } else {
      setColor("primary");
    }

    if (timeoutLength != null) {
      setTimeoutLength(timeoutLength);
    }
    if (isNotEmpty(link)) {
      setLink(link);
    }
  };

  const clearAlert = (): void => {
    setMessage(undefined);
    setColor(undefined);
    setLink(undefined);
  };

  return { message, color, link, timeoutLength, updateAlert, clearAlert };
}
