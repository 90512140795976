import { ReactElement, ReactNode, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import { deleteData, putData, useGetEditUser } from "../api/endpoints";
import { PostResponse, User } from "../api/types";
import { ApiError } from "../api/ApiError";
import Header from "../components/layout/Header";
import UserForm from "../forms/UserForm";
import { isEmpty, isNotEmpty } from "../util";
import EditSubtitle from "../components/layout/EditSubtitle";
import { useConfirmDialog } from "../hooks/ProvideConfirmDialog";
import Loader from "../components/layout/Loader";
import { SimpleLayout } from "../components/layout/SimpleLayout";

import { schema } from "../forms/Users/users-schema";
import UserFormUserRoles from "../forms/Users/UserFormUserRoles";
import UserFormFields from "../forms/Users/UserFormFields";
import FormActionButtons from "../components/formLayout/FormActionButtons";
import { getValidationGenericErrorMessages } from "../util";
import { useAuth } from "../hooks/ProvideAuth";
import { hasRole } from "../api/Auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import UseQueryError from "../components/UseQueryError";
import { UserFormData } from "../forms/Users/user-form-types";
import FormError from "../components/formLayout/FormError";
import { invalidateUsers } from "../api/InvalidateQueriesHelper";

export default function EditUser(): ReactElement {
  const params: { id: string } = useParams();
  const userId: string = "id" in params ? params["id"] : "";

  const query = useGetEditUser(userId);

  if (userId === "") {
    return <div>Ingen användare hittad </div>;
  }

  switch (query.status) {
    case "error":
      return <UseQueryError />;
    case "success":
      return <EditUserForm user={query.data} />;
    default:
      return <Loader />;
  }
}

function EditUserForm(props: { user: User }): ReactElement {
  const auth = useAuth();
  const confirmationDialog = useConfirmDialog();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [errors, setErrors] = useState<string[]>([]);
  const mutation = useMutation((formData: string) =>
    putData<User | PostResponse>(`users/${props.user.userID}`, formData)
  );

  const formDefaults = { defaultValues: {} };
  const userRoles = props.user.userRoles != null ? props.user.userRoles : [];
  formDefaults.defaultValues = {
    usr_firstname: props.user.usr_firstname,
    usr_lastname: props.user.usr_lastname,
    usr_email: props.user.usr_email,
    ...(isNotEmpty(props.user.usr_login_blocked_until) && {
      usr_login_blocked_until: props.user.usr_login_blocked_until,
    }),
    ...(userRoles.length > 0 && {
      userRoles: userRoles.map((ur) => {
        return {
          role_name: ur.role_name,
          role_organizationID:
            ur.role_organizationID != null ? ur.role_organizationID : null,
          role_userID: ur.role_userID,
          role_clientID: ur.role_clientID,
          previouslySaved: true,
        };
      }),
    }),
  };

  const methods = useForm<UserFormData>({
    ...formDefaults,
    resolver: yupResolver(schema),
    context: { required: true },
  });

  const handleFormSubmit = async (formData: UserFormData): Promise<void> => {
    if (
      ("usr_password_plain" in formData &&
        isEmpty(formData["usr_password_plain"])) ||
      ("usr_confirmed_password" in formData &&
        isEmpty(formData["usr_confirmed_password"]))
    ) {
      delete formData["usr_password_plain"];
      delete formData["usr_confirmed_password"];
    }

    if (formData.userRoles.length > 0)
      formData.userRoles = formData.userRoles.filter(
        (role) => role.previouslySaved == null || !role.previouslySaved
      );

    await mutation
      .mutateAsync(JSON.stringify(formData))
      .then((res) => {
        if ("errorsItemized" in res) {
          setErrors(getValidationGenericErrorMessages(res.errorsItemized));
        } else {
          void queryClient.invalidateQueries([
            "users",
            props.user.userID.toString(),
          ]);
          void queryClient.invalidateQueries({
            predicate: invalidateUsers,
          });
          history.goBack();
        }
      })
      .catch((error: ApiError) => {
        setErrors(
          getValidationGenericErrorMessages(error.details.errorsItemized)
        );
      });
  };

  const handleDeleteButtonClicked = async (): Promise<void> => {
    await confirmationDialog
      .getConfirmation({
        title: "Varning!",
        message: "Är du säker på att du vill ta bort användaren?",
      })
      .then((confirmed: boolean) => {
        if (confirmed) {
          deleteData<void>(`users/${props.user.userID}`)
            .then(() => {
              void queryClient.invalidateQueries({
                predicate: invalidateUsers,
              });
              void queryClient.removeQueries([
                "users",
                props.user.userID.toString(),
              ]);
              history.goBack();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
  };

  return (
    <SimpleLayout>
      <EditSubtitle onDeletedClicked={handleDeleteButtonClicked} />
      <Header title={props.user.usr_firstname}></Header>
      <UserForm>
        {isNotEmpty(props.user.usr_login_blocked_until) && (
          <Controller
            control={methods.control}
            name="usr_login_blocked_until"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <div className="bg-soft-mint rounded flex flex-col p-2 py-4 space-y">
                <div className="flex items-baseline space-x-4">
                  <h4>Blockerad</h4>
                  <div>
                    <ToggleButtonsWrapper
                      isTrue={value !== ""}
                      className=""
                      onClick={() =>
                        onChange(() =>
                          value === "" ? props.user.usr_login_blocked_until : ""
                        )
                      }
                    >
                      <ToggleButton label="Ja" isTrue isActive={value !== ""} />
                      <ToggleButton label="Nej" isActive={value === ""} />
                    </ToggleButtonsWrapper>
                  </div>
                </div>
                <p className="pt-2">
                  För många inloggningsförsök har utförts. Blockerad till och
                  med: {props.user.usr_login_blocked_until}
                </p>
              </div>
            )}
          />
        )}
        {auth?.userRoles != null &&
          hasRole(auth.userRoles, ["admin", "clientOrgAdmin", "orgAdmin"]) && (
            <UserFormUserRoles methods={methods} />
          )}
        <UserFormFields methods={methods} editForm={true} errors={errors} />

        <div>
          {errors.map((e) => (
            <FormError key={e} message={e} />
          ))}
        </div>

        <FormActionButtons
          methods={methods}
          onFormSave={handleFormSubmit}
          onFormCancel={() => history.goBack()}
        />
      </UserForm>
    </SimpleLayout>
  );
}

function ToggleButtonsWrapper(props: {
  children: ReactNode;
  className?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  isTrue: boolean; // Is the active value the true/yes value
}): ReactElement {
  let classes = "border rounded-lg flex p-1 ";

  if (props.isTrue) {
    classes = classes.concat("bg-ocean-blue");
  } else {
    classes = classes.concat("bg-white");
  }

  if (isNotEmpty(props.className)) {
    classes = classes.concat(" " + props.className);
  }

  return (
    <button type="button" onClick={props.onClick} className={classes}>
      {props.children}
    </button>
  );
}

function ToggleButton(props: {
  isActive: boolean;
  label: string;
  isTrue?: boolean; // Is this the true/yes button
}): ReactElement {
  return (
    <div
      className={`flex-initial w-1/2 mx-1 self-center ${
        props.isTrue ? "text-white" : "text-ocean-blue"
      }`}
    >
      {props.isActive ? (
        props.label
      ) : (
        <div className="bg-white px-1 border rounded-lg text-white border-ocean-blue">
          {props.label}
        </div>
      )}
    </div>
  );
}
