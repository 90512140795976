import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardEvent, MouseEvent, ReactElement, useState } from "react";
import { FilledButton } from "./Buttons";
import FormInputWrapper from "./formLayout/FormInputWrapper";
import FormLabel from "./formLayout/FormLabel";

/**
 * Handle Search
 * @param props onSearch - callback function that returs the currently printed word
 */
export default function Search(props: {
  serverSideSearch?: boolean;
  onSearch: (search: string) => void;
  defaultValue?: string;
}): ReactElement {
  const [searchString, setSearchString] = useState(
    props.defaultValue != null ? props.defaultValue : ""
  );

  const handleClearSearchClicked = (): void => {
    setSearchString("");
    props.onSearch("");
  };

  const handleInputKeyUp = (e: KeyboardEvent<HTMLInputElement>): void => {
    e.preventDefault();
    if (e.key === "Enter" || e.keyCode === 13) {
      props.onSearch(searchString);
    }
  };
  const handleSeachBtnClicked = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    props.onSearch(searchString);
  };

  return (
    <div className="search-box w-full md:pr-4">
      <FormInputWrapper>
        <FormLabel label="Sök" htmlFor="search" />
        <div className="flex">
          <div className="flex-1 relative">
            <input
              type="text"
              name="search"
              value={searchString}
              placeholder="Sök här"
              onChange={(e) => setSearchString(e.target.value)}
              onKeyUp={handleInputKeyUp}
            />
            <button
              className="absolute top-3 right-4"
              onClick={handleClearSearchClicked}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <FilledButton
            className="self-center ml-2"
            content="Sök"
            size="medium"
            onClick={handleSeachBtnClicked}
          />
        </div>
      </FormInputWrapper>
    </div>
  );
}
