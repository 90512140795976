import React, { createContext, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import QueryProvider from "./api/QueryProvider";
import { ProvideAuth } from "./hooks/ProvideAuth";
import { useLocation } from "react-router-dom";
import { AlertProvider } from "./hooks/AlertProvider";

export function ScrollToTop(): null {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const authContext = createContext<UserProp | null>(null);

ReactDOM.render(
  <React.StrictMode>
    <Router basename={process.env.REACT_APP_SUBDIR}>
      <QueryProvider>
        <ProvideAuth>
          <AlertProvider>
            <ScrollToTop />
            <App />
          </AlertProvider>
        </ProvideAuth>
      </QueryProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

interface UserProp {
  name: string;
}
