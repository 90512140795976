import * as yup from "yup";
import { ReactElement, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import {
  TextareaInput,
  TextareaWrapper,
} from "../../components/formLayout/FormTextarea";
import FormLabel from "../../components/formLayout/FormLabel";
import FormError from "../../components/formLayout/FormError";
import { Button } from "../../components/Buttons";

export type NoticeData = {
  msg_type: string;
  msg_text: string;
  msg_mediaID: number | null;
  msg_moduleID: number | null;
  msg_userID: number;
};

export const schema = yup.object().shape({
  msg_text: yup.string().required("En text krävs"),
});

export default function NoticeForm(props: {
  methods: UseFormReturn<NoticeData>;
  serverErrors: string[];
  submitBtnText: string;
  onFormSubmit: (
    formData: FieldValues,
    e?: React.BaseSyntheticEvent
  ) => Promise<void>;
}): ReactElement {
  const [errorsPresent, setErrorsPresent] = useState<boolean>(false);

  return (
    <form>
      <TextareaWrapper className="form-textarea-wrapper my-10">
        <FormLabel htmlFor="msg_text" label="Notis" />
        <TextareaInput
          resize="both"
          height="h-60"
          {...props.methods.register("msg_text" as const)}
        />
      </TextareaWrapper>
      <div>
        {props.serverErrors.map((e, index) => (
          <FormError key={e + index.toString()} message={e} />
        ))}
      </div>
      <div className="flex items-center">
        <Button
          content={props.submitBtnText}
          size="medium"
          onClick={props.methods.handleSubmit(props.onFormSubmit, () =>
            setErrorsPresent(true)
          )}
        ></Button>
        {errorsPresent && (
          <FormError message="Något gick fel" className="pl-5" />
        )}
      </div>
    </form>
  );
}
