import { ReactElement } from "react";
import Menu from "./Menu";
import MenuItem from "./MenuItem";
import MenuSection from "./MenuSection";
import NoticesMenuItem from "./NoticesMenuItem";
import ResourceMenutItems from "./ResourceMenuItems";

export default function RegUserMenu(): ReactElement {
  return (
    <>
      <Menu>
        <MenuSection>
          <MenuItem path="/">Hem</MenuItem>
        </MenuSection>
        <MenuSection>
          <MenuItem path="/laslogg">Läsdagbok</MenuItem>
          <NoticesMenuItem />
          <ResourceMenutItems />
        </MenuSection>
        <MenuSection>
          <MenuItem path="/mitt-konto">Mitt konto</MenuItem>
        </MenuSection>
      </Menu>
    </>
  );
}
