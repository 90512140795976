import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useMemo } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { useGetClients, useGetUserRoles } from "../../api/endpoints";
import FormError from "../../components/formLayout/FormError";
import { FormSection } from "../../components/formLayout/FormSection";
import { useAuth } from "../../hooks/ProvideAuth";
import { useGetOrganizations } from "../../pages/Organizations/useGetOrganizations";
import { getFormArrayMinError } from "../../util";
import {
  MappedTitles,
  PrefilledUserRole,
  UserFormData,
  UserRoleFormData,
} from "./user-form-types";
import UserRolesSelector from "./UserRolesSelector";

export default function UserFormUserRoles(props: {
  methods: UseFormReturn<UserFormData>;
  prefilledUserRole?: PrefilledUserRole;
}): ReactElement {
  const rolesOptions = useRolesOptions();
  const orgQuery = useGetOrganizations();
  const clientQuery = useGetClients();

  const organizations = useMemo(
    () =>
      orgQuery.isSuccess
        ? orgQuery.data.items.reduce((acc: MappedTitles, curr) => {
            acc[curr.organizationID] = curr.org_name;
            return acc;
          }, {})
        : {},
    [orgQuery]
  );
  const clients = useMemo(
    () =>
      clientQuery.isSuccess
        ? clientQuery.data.items.reduce((acc: MappedTitles, curr) => {
            acc[curr.clientID] = curr.cl_name;
            return acc;
          }, {})
        : {},
    [clientQuery]
  );

  const roleNames = useMemo(
    () =>
      rolesOptions.reduce((acc: MappedTitles, curr) => {
        acc[curr.value] = curr.label;
        return acc;
      }, {}),
    [rolesOptions]
  );

  const userRolesFieldArray = useFieldArray({
    control: props.methods.control,
    name: "userRoles",
  });

  const deleteRolesFieldArray = useFieldArray({
    control: props.methods.control,
    name: "deleteUserRoles",
  });

  const addUserRole = (
    clientIds: { label: string; value: number }[],
    orgIds: { label: string; value: number }[],
    roleName: { label: string; value: string }
  ): void => {
    const userRoles = props.methods.getValues("userRoles");

    if (orgIds.length > 0) {
      for (const orgId of orgIds) {
        userRoles.push({
          role_name: roleName.value,
          role_organizationID: orgId.value,
        });
      }
    } else if (clientIds.length > 0) {
      for (const clientId of clientIds) {
        userRoles.push({
          role_name: roleName.value,
          role_clientID: clientId.value,
        });
      }
    } else {
      userRoles.push({
        role_name: roleName.value,
      });
    }

    props.methods.setValue("userRoles", userRoles);
  };

  const removeUserRole = (f: UserRoleFormData, index: number): void => {
    if (f.previouslySaved) {
      const minimalField = {
        role_name: f.role_name,
        role_organizationID: f.role_organizationID,
      };
      deleteRolesFieldArray.append(minimalField);
    }
    userRolesFieldArray.remove(index);
  };

  return (
    <FormSection>
      <UserRolesSelector
        clients={clientQuery.isSuccess ? clientQuery.data.items : []}
        organizations={orgQuery.isSuccess ? orgQuery.data.items : []}
        onClick={addUserRole}
        userRoleOptions={rolesOptions}
        prefilledUserRole={props.prefilledUserRole}
      />

      <div>
        <table className="table w-full fsu-table">
          <thead>
            <tr>
              <th>Uppdragsgivare</th>
              <th>Organisation</th>
              <th>Roll</th>
            </tr>
          </thead>
          <tbody>
            {userRolesFieldArray.fields.map((field, index) => {
              return (
                <tr key={field.id}>
                  <td>
                    {field.role_clientID != null
                      ? clients[field.role_clientID]
                      : "-"}
                  </td>
                  <td>
                    {field.role_organizationID != null
                      ? organizations[field.role_organizationID]
                      : "-"}
                  </td>
                  <td>
                    <div>{roleNames[field.role_name]}</div>
                  </td>
                  <td>
                    <button
                      className="opacity-90 hover:opacity-100"
                      onClick={() => {
                        removeUserRole(field, index);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {props.methods.formState.errors.userRoles != null && (
          <FormError
            message={getFormArrayMinError(
              props.methods.formState.errors.userRoles
            )}
          />
        )}
      </div>
    </FormSection>
  );
}

function useRolesOptions(): { value: string; label: string }[] {
  const auth = useAuth();
  const userRoles = useGetUserRoles();
  const options: { value: string; label: string }[] = [
    userRoles["regUser"],
    userRoles["orgAdmin"],
  ];

  if (auth?.highestRole === "clientOrgAdmin") {
    options.push(userRoles["clientOrgAdmin"]);
  } else if (auth?.highestRole === "admin") {
    options.push(userRoles["clientOrgAdmin"]);
    options.push(userRoles["admin"]);
  }

  return options;
}
