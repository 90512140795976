import { ReactElement, ReactNode } from "react";
import { IconButtonLink } from "../Buttons";

export default function FormSectionBodyFieldArray(props: {
  children: ReactNode;
}): ReactElement {
  return <div className="grid grid-cols-1">{props.children}</div>;
}

export function AppendFormFieldButton(props: {
  className?: string;
  onButtonClick(): void;
  children: ReactNode;
  disabled?: boolean;
}): ReactElement {
  return (
    <div className={`w-full ${props.className != null ? props.className : ""}`}>
      <IconButtonLink
        disabled={props.disabled}
        className=""
        content={props.children}
        type="button"
        onClick={() => props.onButtonClick()}
      />
    </div>
  );
}
