import { ReactElement, ReactNode } from "react";
import ModuleInputHeader from "./ModuleInputWrapper/ModuleInputHeader";
import ModuleInputWrapper from "./ModuleInputWrapper/ModuleInputWrapper";

export default function ModuleLogFormWrapper(props: {
  children: ReactNode;
}): ReactElement {
  return (
    <ModuleInputWrapper>
      <ModuleInputHeader title="Läslogg" className="pb-0" />
      <div className="bg-ocean-blue grid grid-cols-3 border border-b-2 divide-x ">
        <div className="p-4 pt-2">
          <div className="font-semibold text-white">
            Citat. En mening/några meningar
          </div>
          <div className="text-dark-gray">Skriv sida</div>
        </div>
        <div className="py-4 pt-2">
          <div className="font-semibold text-white">
            Din reflektion och tolkning
          </div>
          <div className=" text-dark-gray">
            Hur förstår jag det här? Vad betyder det?
          </div>
        </div>
        <div className="py-4 pr-4 pt-2 space-y-4">
          <div>
            <div className="font-semibold text-white">
              Vilken betydelse har det i praktiken?
            </div>
            <div className=" text-dark-gray">
              Konsekvenser för praktiken? Klokt att göra?:
            </div>
          </div>
          <div className="">
            <div className="font-semibold text-white">
              Vilken betydelse har det för mitt ledarskap?
            </div>
            <div className=" text-dark-gray">
              Tänk styrning, strategier, ledning för en hållbar organisation och
              måluppfyllelse.
            </div>
            <div className=" text-dark-gray">
              Klokt att göra på kort/lång sikt?
            </div>
          </div>
        </div>
      </div>
      <div>{props.children}</div>
    </ModuleInputWrapper>
  );
}
