import { ReactElement, useState, useContext, Fragment, useEffect } from "react";
import CardHeader from "../components/CardHeader";
import { useHistory, useLocation } from "react-router";
import { AuthContext } from "../hooks/ProvideAuth";
import { Button } from "../components/Buttons";
import { FieldValues, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { postData } from "../api/endpoints";
import inMemoryToken from "../hooks/TokenManager";
import { FormInput } from "../components/formLayout/FormInput";
import FormLabel from "../components/formLayout/FormLabel";
import FormInputWrapper from "../components/formLayout/FormInputWrapper";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isNotEmpty } from "../util";
import FormError from "../components/formLayout/FormError";
import Loader from "../components/layout/Loader";
import { LoginResponse } from "../api/types";

interface LoginFormData {
  password: string;
  username: string;
}

const schema = yup.object().shape({
  username: yup
    .string()
    .email("E-post kräver en gilltig adress")
    .required("E-post är ett obligatoriskt fält"),
  password: yup.string().required("Lösenord är ett obligatoriskt fält"),
});

export default function Login(): ReactElement {
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const location = useLocation<Location | undefined>();
  const auth = useContext(AuthContext);
  const mutation = useMutation((loginData: URLSearchParams) =>
    postData<LoginResponse>("auth/login", loginData)
  );

  const methods = useForm<LoginFormData>({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (methods.formState.isValidating && isNotEmpty(errorMessage)) {
      setErrorMessage("");
    }
  }, [methods.formState, errorMessage]);

  const handleButtonSubmit = async (formData: FieldValues): Promise<void> => {
    await mutation
      .mutateAsync(new URLSearchParams(formData))
      .then((res: LoginResponse) => {
        if (
          res.status === "ok" &&
          res.token != null &&
          res.user != null &&
          res.userRoles != null
        ) {
          inMemoryToken.setToken(res.token);

          const { user, userRoles, enabledLicenseParts } = res;
          auth?.signin({ user, userRoles, enabledLicenseParts });
          auth?.setSystemAlert(res.systemAlert);

          const from: Location =
            location.state !== undefined && "from" in location.state
              ? location.state["from"]
              : ({ pathname: "/" } as Location);

          history.replace(from);
        } else if (Object.keys(res.errors).length > 0) {
          let errorMessages: string[] = [];
          for (const field in res.errors) {
            errorMessages = [...errorMessages, res.errors[field]];
          }
          setErrorMessage(errorMessages.join());
        } else {
          setErrorMessage("Ogilltigt E-post eller Lösenord");
        }
      })
      .catch((error) => {
        // TODO: Handle error
        setErrorMessage(
          "Ett oväntat fel har uppstått. Vänligen prova igen lite senare."
        );
      });
  };

  return (
    <Fragment>
      <CardHeader title="Logga in" />
      <div className="mt-8">
        <form>
          <FormInputWrapper>
            <FormLabel htmlFor="username" label="E-post" />
            <FormInput {...methods.register("username")} type="text" />
            <FormError
              className="mb-4"
              message={methods.formState.errors.username?.message}
            />
          </FormInputWrapper>
          <FormInputWrapper>
            <FormLabel htmlFor="password" label="Lösenord" />
            <FormInput {...methods.register("password")} type="password" />
            <FormError
              className="mb-4"
              message={methods.formState.errors.password?.message}
            />
          </FormInputWrapper>
          <div className="flex items-center">
            <Button
              type="submit"
              className="flex gap-x-2"
              content={
                <>
                  Logga in {methods.formState.isSubmitting && <Loader inline />}
                </>
              }
              onClick={methods.handleSubmit(handleButtonSubmit)}
              disabled={
                methods.formState.isSubmitting || isNotEmpty(errorMessage)
              }
            />

            <FormError className="pl-4" message={errorMessage} />
          </div>
        </form>

        <div className="mt-8">
          <a href="/glomt-losenord">Klicka här om du glömt ditt lösenord</a>
        </div>
      </div>
    </Fragment>
  );
}
