import { ReactElement, ReactNode } from "react";
import Footer from "./Footer";

export default function ModuleLayout(props: {
  children: ReactNode;
}): ReactElement {
  return (
    <div className="flex-1 flex flex-col justify-between">
      <div className="min-h-screen">{props.children}</div>
      <Footer />
    </div>
  );
}
