import { ChangeEvent, ReactElement, useMemo } from "react";
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  useFieldArray,
  UseFormReturn,
  UseFormStateReturn,
} from "react-hook-form";
import FormLabel from "../components/formLayout/FormLabel";
import FormFieldArrayRow from "../components/formLayout/FormFieldArrayRow";
import FormSectionBodyFieldArray, {
  AppendFormFieldButton,
} from "../components/formLayout/FormFieldArraySection";
import { FormSection } from "../components/formLayout/FormSection";
import FormCheckboxWrapper from "../components/formLayout/FormCheckboxWrapper";
import { Module, ResourceCategory } from "../api/types";
import { useGetModules, useGetResourceCatergories } from "../api/endpoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormDateInput } from "../components/formLayout/FormDateInput";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { getFormArrayMinError } from "../util";
import Loader from "../components/layout/Loader";
import FormError from "../components/formLayout/FormError";
import { ClientData } from "../api/form-types";

const getNumberOfSelectedItems = (items: number[] | undefined): number => {
  return items != null ? items.filter((i) => i >= 0).length : 0;
};

export function LicensesFormSection(props: {
  methods: UseFormReturn<ClientData>;
}): ReactElement {
  const fieldsArray = useFieldArray<ClientData, "licenses">({
    name: "licenses",
    control: props.methods.control,
  });

  return (
    <FormSection title="Licenser">
      <FormSectionBodyFieldArray>
        {fieldsArray.fields.map(
          (field, index): ReactElement => {
            return (
              <FormFieldArrayRow
                key={field.id}
                title={`Licens ${index + 1}`}
                deleteItem={() => fieldsArray.remove(index)}
              >
                <input
                  type="hidden"
                  {...props.methods.register(
                    `licenses.${index}.licenseID` as const
                  )}
                  defaultValue={field.licenseID}
                />
                <div className="w-full xl:flex">
                  <div className="license-date-input-wrapper w-full xl:w-1/3 pb-4 lg:pr-12">
                    <FormLabel
                      htmlFor={`licenses.${index}.l_start_date`}
                      label="Tidsperiod"
                    />
                    <FormDateInput
                      key={`l_start_date-${index}`}
                      regRef={props.methods.register(
                        `licenses.${index}.l_start_date` as const
                      )}
                      defaultValue={field.l_start_date}
                      title="Från"
                    />
                    <FormError
                      className="mb-4"
                      message={
                        props.methods.formState.errors.licenses?.[index]
                          ?.l_start_date?.message
                      }
                    />

                    <FormDateInput
                      key={`l_end_date-${index}`}
                      regRef={props.methods.register(
                        `licenses.${index}.l_end_date` as const
                      )}
                      defaultValue={field.l_end_date}
                      title="Till"
                    />
                    <FormError
                      message={
                        props.methods.formState.errors.licenses?.[index]
                          ?.l_end_date?.message
                      }
                    />
                  </div>
                  <div className="w-full xl:w-2/3">
                    {props.methods.formState.errors.licenses?.[index]
                      ?.moduleIDs != null &&
                      props.methods.formState.errors.licenses[index]
                        ?.resourceIDs != null && (
                        <FormError message="Välj minst 1 modul eller resurs" />
                      )}
                    <FormLabel
                      htmlFor={`licenses.${index}.moduleIDs`}
                      label="Välj moduler"
                    />

                    <FormCheckboxWrapper
                      title="Mångfaldens förskola"
                      className="modules"
                      accordion
                      noSelected={getNumberOfSelectedItems(
                        props.methods.watch(
                          `licenses.${index}.moduleIDs` as const
                        )
                      )}
                    >
                      <ModulesFormInput
                        control={props.methods.control}
                        name={`licenses.${index}.moduleIDs` as const}
                      />
                    </FormCheckboxWrapper>
                    <LicenseResources index={index} methods={props.methods} />
                  </div>
                </div>
              </FormFieldArrayRow>
            );
          }
        )}
        <AppendFormFieldButton
          onButtonClick={() =>
            fieldsArray.append({
              l_start_date: "",
              l_end_date: "",
              licenseID: "",
              moduleIDs: [],
            })
          }
        >
          Lägg till licens
        </AppendFormFieldButton>
        {props.methods.formState.errors.licenses != null && (
          <FormError
            className="mt-2"
            message={getFormArrayMinError(
              props.methods.formState.errors.licenses
            )}
          />
        )}
      </FormSectionBodyFieldArray>
    </FormSection>
  );
}

function ModulesFormInput(props: {
  name: `licenses.${number}.moduleIDs`;
  control: Control<ClientData>;
}): ReactElement {
  const query = useGetModules();
  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <>Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.</>
      );
    case "success":
      return (
        <Controller
          control={props.control}
          name={props.name}
          render={({
            field,
            fieldState,
            formState,
          }: {
            field: ControllerRenderProps<
              ClientData,
              `licenses.${number}.moduleIDs`
            >;
            fieldState: ControllerFieldState;
            formState: UseFormStateReturn<ClientData>;
          }) => {
            const fieldValue: number[] = Array.isArray(field.value)
              ? field.value
              : [];
            const handleChecboxChanged = (
              e: ChangeEvent<HTMLInputElement>
            ): void => {
              const clickedCheckbox = Number.parseInt(e.target.value);

              let values = fieldValue;
              if (clickedCheckbox === -1 && e.target.checked) {
                values = [-1, ...query.data.items.map((m) => m.moduleID)];
              } else {
                if (fieldValue.includes(-1)) {
                  values = values.filter((fv) => fv !== -1);
                }
                values = e.target.checked
                  ? [...values, clickedCheckbox]
                  : values.filter((v) => v !== clickedCheckbox);
              }
              field.onChange(values);
            };

            return (
              <>
                <label key={-1}>
                  <input
                    onChange={handleChecboxChanged}
                    type="checkbox"
                    value={-1}
                    checked={fieldValue.includes(-1)}
                  />
                  Välj Alla
                  <span className="fancy-checkbox block">
                    <span className="fc-icon hidden block leading-none">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-blue-gray"
                      />
                    </span>
                  </span>
                </label>
                <div className="module-options flex flex-col flex-wrap mt-8 mb-12">
                  {query.data.items.map((m: Module, mIndex) => (
                    <label key={`${mIndex}`}>
                      <input
                        onChange={handleChecboxChanged}
                        type="checkbox"
                        value={m.moduleID}
                        checked={fieldValue.includes(m.moduleID)}
                      />
                      {m.m_number > 0 ? `${m.m_number}.` : ""} {m.m_title}
                      <span className="fancy-checkbox block">
                        <span className="fc-icon hidden block leading-none">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-blue-gray"
                          />
                        </span>
                      </span>
                    </label>
                  ))}
                </div>
              </>
            );
          }}
        />
      );
  }
}

interface AllResourceIdsByCategory {
  [id: number]: number[];
}
function LicenseResources({
  index,
  methods,
}: {
  index: number;
  methods: UseFormReturn<ClientData>;
}): ReactElement {
  const query = useGetResourceCatergories({ expand: "resources" });
  const allResourceIdsByCategory: { [id: number]: number[] } = useMemo(() => {
    if (query.data?.items == null) return {} as AllResourceIdsByCategory;
    return query.data.items.reduce((acc: AllResourceIdsByCategory, rc) => {
      acc[rc.resource_CategoryID] =
        rc.resources == null ? [] : rc.resources.map((r) => r.resourceID);
      return acc;
    }, {});
  }, [query.data]);

  const negatedResourceCategoryIds = useMemo(() => {
    if (query.data?.items == null) return {} as { [id: number]: number };
    return query.data.items.reduce((acc: { [id: number]: number }, rc) => {
      acc[rc.resource_CategoryID] = -Math.abs(rc.resource_CategoryID);
      return acc;
    }, {});
  }, [query.data]);
  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      if (query.data._meta.totalCount < 1) return <></>;

      return (
        <Controller
          control={methods.control}
          name={`licenses.${index}.resourceIDs` as const}
          render={({
            field,
            fieldState,
            formState,
          }: {
            field: ControllerRenderProps<
              ClientData,
              `licenses.${number}.resourceIDs`
            >;
            fieldState: ControllerFieldState;
            formState: UseFormStateReturn<ClientData>;
          }) => {
            const fieldValue: number[] = Array.isArray(field.value)
              ? field.value
              : [];
            const handleCheckboxChanged = (
              e: ChangeEvent<HTMLInputElement>,
              resourceCatId: number
            ): void => {
              const clickedCheckbox = Number.parseInt(e.target.value);
              const negatedResourceCatId: number =
                negatedResourceCategoryIds[resourceCatId];
              const allResourceIds =
                resourceCatId in allResourceIdsByCategory
                  ? allResourceIdsByCategory[resourceCatId]
                  : [];

              let values = fieldValue;
              if (
                clickedCheckbox === negatedResourceCatId &&
                e.target.checked
              ) {
                values = Array.from(
                  new Set([negatedResourceCatId, ...values, ...allResourceIds])
                );
              } else {
                if (fieldValue.includes(negatedResourceCatId)) {
                  values = values.filter((fv) => fv !== negatedResourceCatId);
                }
                values = e.target.checked
                  ? [...values, clickedCheckbox]
                  : values.filter((v) => v !== clickedCheckbox);
              }
              field.onChange(values);
            };

            const getNumberOfOptionsSelected = (
              resourceCatId: number,
              itemsSelected: number[] | undefined
            ): number => {
              if (itemsSelected == null) return 0;
              const allResources =
                resourceCatId in allResourceIdsByCategory
                  ? allResourceIdsByCategory[resourceCatId]
                  : [];
              return itemsSelected.filter(
                (n) => n >= 0 && allResources.find((r) => r === n)
              ).length;
            };

            return (
              <div className="w-full">
                <FormLabel
                  htmlFor={`licenses.${index}.resourceIDs`}
                  label="Välj resurser"
                />
                <div className="w-full flex gap-6">
                  {query.data.items.map((rc) => (
                    <FormCheckboxWrapper
                      key={rc.resource_CategoryID}
                      title={rc.rc_title}
                      className="modules w-1/2"
                      accordion
                      default="expanded"
                      noSelected={getNumberOfOptionsSelected(
                        rc.resource_CategoryID,
                        methods.watch(`licenses.${index}.resourceIDs` as const)
                      )}
                    >
                      <ResourceCategoryFormInput
                        handleCheckboxChanged={(e) =>
                          handleCheckboxChanged(e, rc.resource_CategoryID)
                        }
                        fieldValue={fieldValue}
                        resourceCategory={rc}
                        allResourceIds={
                          rc.resource_CategoryID in allResourceIdsByCategory
                            ? allResourceIdsByCategory[rc.resource_CategoryID]
                            : []
                        }
                      />
                    </FormCheckboxWrapper>
                  ))}
                </div>
              </div>
            );
          }}
        />
      );
  }
}

function ResourceCategoryFormInput(props: {
  handleCheckboxChanged: (e: ChangeEvent<HTMLInputElement>) => void;
  fieldValue: number[];
  resourceCategory: ResourceCategory;
  allResourceIds: number[];
}): ReactElement {
  const resources = useMemo(
    () =>
      props.resourceCategory.resources == null
        ? []
        : props.resourceCategory.resources,
    [props.resourceCategory]
  );
  const negatedResourceCatId = -Math.abs(
    props.resourceCategory.resource_CategoryID
  );
  return (
    <>
      <label key={negatedResourceCatId}>
        <input
          onChange={props.handleCheckboxChanged}
          type="checkbox"
          value={negatedResourceCatId}
          checked={props.fieldValue.includes(negatedResourceCatId)}
        />
        Välj Alla
        <span className="fancy-checkbox block">
          <span className="fc-icon hidden block leading-none">
            <FontAwesomeIcon icon={faCheckCircle} className="text-blue-gray" />
          </span>
        </span>
      </label>
      <div className="flex flex-col flex-wrap mt-8 mb-12">
        {resources.map((r, index) => (
          <label key={`${props.resourceCategory.resource_CategoryID}-${index}`}>
            <input
              onChange={props.handleCheckboxChanged}
              type="checkbox"
              value={r.resourceID}
              checked={props.fieldValue.includes(r.resourceID)}
            />
            {r.rsc_title}
            <span className="fancy-checkbox block">
              <span className="fc-icon hidden block leading-none">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-blue-gray"
                />
              </span>
            </span>
          </label>
        ))}
      </div>
    </>
  );
}
