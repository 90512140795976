import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useMemo } from "react";
import { Link } from "react-router-dom";
import { Module } from "../api/types";
import { Button } from "../components/Buttons";
import Footer from "../components/layout/Footer";
import { isNotEmpty } from "../util";
import Loader from "../components/layout/Loader";
import { useAuth } from "../hooks/ProvideAuth";
import {
  useGetModuleProgresses,
  useGetPlatformModules,
} from "../api/endpoints";
import ResourceHint from "../components/ResourcesHint";

export default function Platform(): ReactElement {
  const auth = useAuth();
  const modules = useGetPlatformModules();
  const firstname = auth?.user != null ? auth.user.usr_firstname : "";
  const lastname = auth?.user != null ? auth.user.usr_lastname : "";
  return (
    <div className="flex-1 flex flex-col justify-between">
      <div className="flex flex-row min-h-screen">
        <div className="lg:w-4/5 mx-16">
          <h1 className="text-4xl mt-10 mb-8">
            {`Välkommen ${firstname} ${lastname}!`}
          </h1>
          <div className="my-6 w-full grid grid-cols-1 gap-4 xl:w-2/3 ">
            <div>
              <span className="text-bold text-dull-mint">
                Introduktionsmodul
              </span>
              <p>
                Här finner du en <b>Introduktionsmodul</b> med korta informativa
                filmer som vägleder dig in i plattformen och i det pedagogiska
                arbetet med de 15 kapitelmodulerna. Du börjar förslagsvis med
                att titta på dessa introduktionsfilmer innan du går vidare till
                nästa steg. Det är viktigt att alla användare av plattformen och
                modulerna har tagit del av den här informationen och att
                rektor/ledningen säkerställer att alla förstår vad som väntar
                och vad som förväntas av varje användare.
              </p>
            </div>
            <div>
              <span className="text-bold text-dull-mint">Modulerna</span>
              <p>
                När du sett introduktionsfilmerna och eventuellt
                inspirationsfilmerna tar du dig an någon, några eller alla de 15{" "}
                <b>Modulerna</b>. Välj själv i vilken ordning du ska arbeta med
                modulerna. Jag rekommenderar att innehållet i modulerna
                bearbetas både individuellt och kollegialt/i grupp och att varje
                modul och kapitel får ta tid att genomföra och läsa.
              </p>
            </div>
          </div>
          <ResourceHint className="my-12" forAdmin={true} />
          <div className="mb-24">
            <div className="flex items-center mt-10 mb-4">
              <h2 className="font-bold">
                Tillgängliga moduler{" "}
                {modules.isSuccess
                  ? ` | ${modules.data._meta.totalCount}st`
                  : ""}
              </h2>
            </div>
            {auth?.user?.userID != null && (
              <Modules userID={auth.user.userID} />
            )}
          </div>
        </div>
        {/* <Sidebar /> */}
      </div>
      <Footer />
    </div>
  );
}

// function Sidebar(): ReactElement {
//   return (
//     <div className="md:w-3/5 xl:w-2/5 2xl:w-1/4 hidden lg:block relative">
//       <div className="h-auto bg-light-mint p-8">
//         <h4 className="pb-4">Fortsätt Här...</h4>

//         <img
//           className="w-full pb-8"
//           src={process.env.PUBLIC_URL + "/example-img-2.jpg"}
//           alt=""
//         />

//         <FilledButton width="w-full" content="Starta" size="medium" />
//       </div>

//       <div className="border-b border-ocean-blue m-8 pb-8">
//         <h4 className="pb-2">Kommunikation</h4>
//         <p>
//           <span className="cmn-large conversaion-message-number">1</span>
//           <span>Du har 1 nytt meddelande</span>
//         </p>

//         <p>Inga nya meddelanden</p>
//       </div>
//       <div className="m-8">
//         <h4 className="pb-2">Läslogg</h4>
//         <p className="pb-4">Se dina senaste läsloggar</p>
//         <Link to="/laslogg">
//           <Button content="Läsloggen" size="medium" />
//         </Link>
//       </div>
//       <div className=" absolute bottom-0">
//         Modulerna är tillgängliga till 2024-04-27
//       </div>
//     </div>
//   );
// }

interface Progress {
  [step: string]: boolean;
}
interface ModuleProgressesByModuleID {
  [id: number]: Progress;
}
function Modules({ userID }: { userID: number }): ReactElement {
  const modulesQuery = useGetPlatformModules();
  const moduleProgressesQuery = useGetModuleProgresses({ userID });
  const moduleProgressesByModule: ModuleProgressesByModuleID = useMemo((): ModuleProgressesByModuleID => {
    if (
      moduleProgressesQuery.status !== "success" ||
      moduleProgressesQuery.data.items.length < 1
    )
      return {} as ModuleProgressesByModuleID;
    return moduleProgressesQuery.data.items.reduce(
      (acc: ModuleProgressesByModuleID, mp) => {
        if (!(mp.mprog_moduleID in acc)) {
          acc[mp.mprog_moduleID] = {};
        }
        if (!(mp.mprog_step in acc[mp.mprog_moduleID])) {
          acc[mp.mprog_moduleID][mp.mprog_step] = mp.mprog_completed != null;
        }
        return acc;
      },
      {}
    );
  }, [moduleProgressesQuery]);

  const loadingStatuses = ["idle", "loading"];
  if (
    modulesQuery.status === "success" &&
    moduleProgressesQuery.status === "success"
  ) {
    return (
      <div className="flex flex-col divide-y divide-ocean-blue-400">
        {modulesQuery.data.items.map((module: Module) => {
          const moduleProgresses =
            module.moduleID in moduleProgressesByModule
              ? moduleProgressesByModule[module.moduleID]
              : {};
          const progress = Object.values(moduleProgresses).filter((p) => p)
            .length;

          return (
            <div
              key={module.moduleID}
              className="flex flex-col md:flex-row py-8"
            >
              <div className="h-full md:w-1/4 lg:w-64">
                <img
                  alt={`${module.moduleID}.${module.m_title}`}
                  src={
                    isNotEmpty(module.m_thumbnail) &&
                    module.m_thumbnail.includes("http")
                      ? module.m_thumbnail
                      : process.env.PUBLIC_URL + module.m_thumbnail
                  }
                />
              </div>
              <div className="flex-1 w-full md:w-3/4 md:mx-8 font-bold">
                <div className="space-x-1 divide-x font-medium uppercase text-md pb-2 mt-8 md:mt-0">
                  <span className="text-fresh-mint font-bold pr-2 whitespace-nowrap">
                    {module.m_number === 0
                      ? "Introduktionsmodul"
                      : `Modul ${module.m_number}`}
                  </span>
                  {module.m_number !== 0 ? (
                    <span className="text-fresh-mint px-2 font-bold">
                      {progress > 0 ? "Påbörjad" : "Ej påbörjad"}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex flex-row w-full">
                  <div className="sm:flex sm:flex-row sm:justify-between md:block w-full lg:w-2/3">
                    <h3 className="text-ocean-blue font-bold md:mb-8 w-full mb-0">
                      {module.m_number === 0
                        ? "Introduktion"
                        : `${module.m_title}`}
                    </h3>
                    <div className="hidden md:block">
                      <Link to={`/modul/${module.moduleID}`}>
                        <Button
                          className="w-full md:w-auto"
                          content={
                            module.m_number !== 0
                              ? progress > 0
                                ? "Fortsätt"
                                : "Starta modulen"
                              : "Till modulen"
                          }
                          size="medium"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {module.m_number !== 0 ? (
                <div className="flex-initial w-full mt-8 md:mt-0 md:w-1/4">
                  <div className="space-x-1 font-medium uppercase text-md pb-2 text-fresh-mint font-bold">
                    {`${progress}/3 Genomförda`}
                  </div>
                  <ProgessIndication moduleProgresses={moduleProgresses} />
                  <div className="md:hidden mt-8 ">
                    <Link to={`/modul/${module.moduleID}`}>
                      <Button
                        className="w-full md:w-auto"
                        content={progress > 0 ? "Fortsätt" : "Starta modulen"}
                        size="medium"
                      />
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="md:hidden mt-8 ">
                  <Link to={`/modul/${module.moduleID}`}>
                    <Button
                      className="w-full md:w-auto"
                      content="Till modulen"
                      size="medium"
                    />
                  </Link>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  } else if (
    loadingStatuses.includes(modulesQuery.status) ||
    loadingStatuses.includes(moduleProgressesQuery.status)
  ) {
    return <Loader />;
  } else {
    return (
      <div>
        Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
      </div>
    );
  }
}

function ProgessIndication({
  moduleProgresses: progress,
}: {
  moduleProgresses: Progress;
}): ReactElement {
  const progess = [
    {
      module_progressID: 2,
      step: "main_module_logs",
      title: "Läsning och läslogg",
      done:
        "main_module_logs" in progress ? progress["main_module_logs"] : false,
    },
    {
      module_progressID: 1,
      step: "m_lecture_video",
      title: "Film",
      done: "m_lecture_video" in progress ? progress["m_lecture_video"] : false,
    },
    {
      module_progressID: 3,
      step: "m_analysis_text",
      title: "Reflektion och analys",
      done: "m_analysis_text" in progress ? progress["m_analysis_text"] : false,
    },
  ];

  const processCheckboxes = progess.map((prog) => (
    <div key={prog.module_progressID}>
      <div
        className={`${
          prog.done
            ? "bg-dull-mint bg-opacity-10 border-dull-mint"
            : "bg-medium-gray border-blue-gray  border-opacity-30"
        } border-l-4 w-full my-1 shadow rounded-sm flex justify-between items-center pr-2`}
      >
        <span
          className={`px-2 font-medium ${!prog.done ? "text-opacity-25" : ""}`}
        >
          {prog.title}
        </span>
        <span
          className={`flex border rounded${
            !prog.done ? " border-blue-gray border-opacity-20" : ""
          }`}
        >
          <FontAwesomeIcon
            className={prog.done ? "" : `opacity-0`}
            icon={faCheck}
            transform="shrink-6"
          />
        </span>
      </div>
    </div>
  ));

  return <>{processCheckboxes}</>;
}
