import {
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  TextareaHTMLAttributes,
} from "react";
import { isEmpty, isNotEmpty } from "../../util";

export function TextareaWrapper(props: {
  children: ReactNode;
  className?: string;
}): ReactElement {
  return (
    <div
      className={`w-full relative ${
        isNotEmpty(props.className) ? props.className : ""
      }`}
    >
      {props.children}
    </div>
  );
}

interface TextareaInputProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  resize?: "none" | "vertical" | "horizontal" | "both";
  height?: string;
}
export const TextareaInput = forwardRef(
  (props: TextareaInputProps, ref: Ref<HTMLTextAreaElement>): ReactElement => {
    const resize = isEmpty(props.resize) ? "none" : props.resize;
    const height = isEmpty(props.height) ? "h-full" : props.height;
    const resizeOptions = {
      none: "resize-none",
      vertical: "resize-y",
      horizontal: "resize-x",
      both: "resize",
    };
    return (
      <textarea
        ref={ref}
        {...props}
        className={`${height} w-full min-h-48 ${resizeOptions[resize]} p-2 text-lg text-ocean-blue`}
      ></textarea>
    );
  }
);
