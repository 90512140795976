import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ReactElement, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { deleteData } from "../../api/endpoints";
import { Notice } from "../../api/types";
import { Button } from "../../components/Buttons";
import NoticeCard from "../../components/communication/NoticeCard";
import Header from "../../components/layout/Header";
import Loader from "../../components/layout/Loader";
import { SimpleLayout } from "../../components/layout/SimpleLayout";
import { useAuth } from "../../hooks/ProvideAuth";
import { useGetNewNotices, useGetNotices } from "./endpoints";

export default function Notices(): ReactElement {
  const history = useHistory();
  const auth = useAuth();
  const noticesQuery = useGetNotices();
  const newNoticesQuery = useGetNewNotices();
  const queryClient = useQueryClient();

  const newNotices = useMemo(() => {
    if (newNoticesQuery.status === "success") {
      return newNoticesQuery.data.items;
    } else {
      return [];
    }
  }, [newNoticesQuery.data, newNoticesQuery.status]);

  let notices: Notice[];
  let noticesMarkup: ReactElement;
  switch (noticesQuery.status) {
    // switch ("success") {
    case "error":
      noticesMarkup = (
        <>Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.</>
      );
      break;
    case "success":
      notices = noticesQuery.data.items.filter((n) => n.msg_type === "NTC");
      if (notices.length > 0) {
        noticesMarkup = (
          <>
            {notices.map((notice, index) => {
              notice.msg_read = newNotices.find(
                (n) =>
                  n.messageID === notice.messageID &&
                  auth?.user?.userID !== notice.msg_userID
              )
                ? new Date()
                : null;
              return (
                <NoticeCard
                  key={`notice-${notice.messageID}`}
                  notice={notice}
                  onRemoveNotice={() => handleRemoveNotice(notice)}
                />
              );
            })}
          </>
        );
      } else {
        noticesMarkup = <p>Inga meddelanden</p>;
      }

      break;
    default:
      noticesMarkup = <Loader />;
      break;
  }

  const handleRemoveNotice = async (notice: Notice): Promise<void> => {
    await deleteData(`messages/${notice.messageID}`)
      .then(() => {
        void queryClient.invalidateQueries(["messages", "notices"]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <SimpleLayout>
      <Header title={"Notiser"}>
        {auth?.highestRole === "admin" && (
          <Button
            icon={faPlus}
            content="Lägg till ny notis"
            onClick={() => history.push("/notiser/ny")}
            size="xsmall"
          />
        )}
      </Header>
      <div className="mt-10 flex flex-col gap-y-10 w-full">{noticesMarkup}</div>
    </SimpleLayout>
  );
}
