import { ReactElement, ReactNode } from "react";
// import { useHistory } from "react-router-dom";
import { signout } from "../../api/endpoints";
import { useAuth } from "../../hooks/ProvideAuth";

export default function Menu(props: { children: ReactNode }): ReactElement {
  const auth = useAuth();
  // const history = useHistory();

  const handleLogout = async (): Promise<void> => {
    await signout()
      .then(() => {
        auth?.signout();
        // history.push("/logga-in");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="text-left inline-block">
      {props.children}
      <div>
        <button onClick={handleLogout}>Logga ut</button>
      </div>
    </div>
  );
}
