import { ReactElement, useMemo } from "react";
import { useHistory } from "react-router";
import { Organization, User } from "../api/types";
import { Button, IconButtonLink } from "./Buttons";
import ViewHeader from "./layout/ViewHeader";
interface OrgAdminsListProps {
  users: User[] | null;
  organization: Organization;
}

export function OrgAdminsList({
  users,
  organization,
}: OrgAdminsListProps): ReactElement {
  const history = useHistory();

  const orgAdmins = useMemo(
    () =>
      users == null
        ? []
        : users.filter((usr) =>
            usr.userRoles?.some((role) => role.role_name === "orgAdmin")
          ),

    [users]
  );

  return (
    <div>
      <ViewHeader title="Organisationsadministratör"></ViewHeader>
      {orgAdmins.map((usr) => (
        <div className="m-6" key={usr.userID}>
          <h5 className="text-blue-gray text-lg font-semibold">
            {`${usr.usr_firstname} ${usr.usr_lastname}`.toUpperCase()}
          </h5>
          <div className="text-base text-blue-gray">{usr.usr_email}</div>

          <div className="my-2">
            <Button
              content="Redigera"
              size="xsmall"
              onClick={() => history.push(`/anvandare/redigera/${usr.userID}`)}
            />
          </div>
        </div>
      ))}
      <IconButtonLink
        className="mt-4"
        content="Lägg till organisationsadministratör"
        onClick={() =>
          history.push("/anvandare/ny", {
            role: "orgAdmin",
            orgId: organization.organizationID,
          })
        }
      />
    </div>
  );
}
