/**
 * Provide client settings to authorized pages
 */

import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { fetchApiGet } from "../api/api";
import { ClientDefaultSettingsValue, ClientSetting, User } from "../api/types";
import { useAuth } from "../hooks/ProvideAuth";
import { isNotEmpty } from "../util";

interface Settings {
  isEnabled: (settingKey: string, defaultValue?: boolean) => boolean;
  defaultSettings: ClientDefaultSettingsValue;
}

const SettingsContext = createContext<Settings | null>(null);
export function useSettings(): Settings {
  const settings = useContext(SettingsContext);
  if (settings == null) {
    throw new Error(
      "Settings är null. Använd inte 'useSettginsProvider' utanför '<SettingsProvider>'"
    );
  }
  return settings;
}

export function SettingsProvider(props: { children: ReactNode }): ReactElement {
  const settings = useSettingsProvider();
  return (
    <SettingsContext.Provider value={settings}>
      {props.children}
    </SettingsContext.Provider>
  );
}

function useSettingsProvider(): Settings {
  // Default values and labels for client settings
  const defaultSettings: ClientDefaultSettingsValue = useMemo(
    () => ({
      permissionRegUserUploadDocs: {
        defaultValue: true,
        label: "Tillåt användare att ladda upp dokument i moduler.",
      },
      permissionUserViewModuleChapterAudio: {
        defaultValue: false,
        label:
          "Visa fil(er) för inläst(a) kapitel i de moduler uppdragsgivare har aktiva licenser för.",
      },
    }),
    []
  );
  const [settings, setSettings] = useState<ClientSetting>({});

  const auth = useAuth();
  const isAdmin = auth?.highestRole === "admin";

  useEffect(() => {
    if (auth?.user !== null) {
      const getClientSettings = (): void => {
        void fetchApiGet<User>(
          `users/${
            auth?.user != null ? auth.user.userID.toString() : ""
          }?expand=userRoles.organization.client, userRoles.client`
        )
          .then((data) => {
            if (isNotEmpty(auth?.highestRole) && data.userRoles != null) {
              switch (auth?.highestRole) {
                case "clientOrgAdmin": {
                  // client
                  const ur = data.userRoles.find(
                    (ur) => ur.client?.cl_settings != null
                  );
                  setSettings(
                    ur?.client?.cl_settings != null ? ur.client.cl_settings : {}
                  );
                  break;
                }
                case "orgAdmin":
                case "regUser": {
                  // org
                  const ur = data.userRoles.find(
                    (ur) => ur.organization?.client?.cl_settings != null
                  );
                  setSettings(
                    ur?.organization?.client?.cl_settings != null
                      ? ur.organization.client.cl_settings
                      : {}
                  );
                  break;
                }
                case "admin": {
                  // org
                  setSettings({});
                  break;
                }
                default:
                  throw new Error(
                    `Unknown user type '${
                      auth?.highestRole != null ? auth.highestRole : ""
                    }'`
                  );
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };

      void getClientSettings();
    }
  }, [auth?.user, auth?.highestRole]);

  const isEnabled = useCallback(
    (settingsKey: string, defaultValue?: boolean): boolean => {
      const settingsDefaultValue =
        settingsKey in defaultSettings
          ? defaultSettings[settingsKey].defaultValue
          : false;
      defaultValue = defaultValue != null ? defaultValue : settingsDefaultValue;
      return (
        isAdmin ||
        (settingsKey in settings ? settings[settingsKey] : defaultValue)
      );
    },
    [settings, isAdmin, defaultSettings]
  );

  return useMemo(() => ({ isEnabled, defaultSettings }), [
    isEnabled,
    defaultSettings,
  ]);
}
