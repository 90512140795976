import { GetListResponse, Notice } from "./../../api/types";
import { useQuery, UseQueryResult } from "react-query";
import { UseQeryOnSettled } from "./../../api/endpoints";
import { fetchApiGet } from "../../api/api";

export function useGetNewNotices(
  onSettled?: UseQeryOnSettled<Notice>
): UseQueryResult<GetListResponse<Notice>, Error> {
  return useQuery<GetListResponse<Notice>, Error>(
    ["messages", "notices", "new"],
    () => fetchApiGet<GetListResponse<Notice>>("messages?filter[msg_type]=NTC"),
    {
      onSettled: (data, error) =>
        onSettled != null ? onSettled(data, error) : null,
    }
  );
}

export function useGetNotices(): UseQueryResult<
  GetListResponse<Notice>,
  Error
> {
  const path = "messages?filter[msg_type]=NTC&show-all-NTC=1&sort=-msg_created";
  const key = ["messages", "notices"];
  return useQuery<GetListResponse<Notice>, Error>(key, () =>
    fetchApiGet<GetListResponse<Notice>>(path)
  );
}

/**
 *
 * @param noticeId - string UserId to get view data from
 */
export function useGetNotice(noticeId: string): UseQueryResult<Notice> {
  return useQuery<Notice, Error>(["messages", noticeId], () =>
    fetchApiGet<Notice>(`messages/${noticeId}`)
  );
}
