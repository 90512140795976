import { ReactElement } from "react";

interface HeaderProps {
  className?: string;
  title: string;
  subtitle?: string;
}

export default function CardHeader(props: HeaderProps): ReactElement {
  return (
    <div className="text-center">
      <h1 className="pb-12 text-blue-gray">{props.title}</h1>
      {props.subtitle ?? <div className="text-blue-gray">{props.subtitle}</div>}
    </div>
  );
}
