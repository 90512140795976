import { yupResolver } from "@hookform/resolvers/yup";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { deleteData } from "../../api/endpoints";
import { ModuleLogData } from "../../api/form-types";
import { Module, ModuleLog } from "../../api/types";
import { useAuth } from "../../hooks/ProvideAuth";
import useAutoSave from "../../hooks/UseAutoSave";
import { Button, FilledButton } from "../Buttons";
import { AppendFormFieldButton } from "../formLayout/FormFieldArraySection";
import { TextareaInput, TextareaWrapper } from "../formLayout/FormTextarea";
import ModuleLogFormWrapper from "./ModuleLogFormWrapper";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsReadOnly } from "./LectureModule/ReadOnyProvider";
import { isEmpty, isNotEmpty } from "../../util";

// https://codesandbox.io/s/react-hook-form-field-array-advanced-with-delete-insert-append-edit-l19pz?file=/src/index.js:1526-1532

const schema = yup.object({
  mlog_quote: yup.string(),
  mlog_interpretation: yup.string(),
  mlog_reflection: yup.string(),
});

export function ModuleLogForm(props: {
  module: Module;
  onChange?: (moduleLogs: { key: string; log: ModuleLogData }[]) => void;
}): ReactElement {
  const onChangeRef = useRef(props.onChange);

  const isFormReadOnly = useIsReadOnly();
  const values = props.module.moduleLogs?.map((log, index) => ({
    key: `${props.module.moduleID}-${index}`,
    log: {
      mlog_userID: log.mlog_userID,
      module_logID: log.module_logID.toString(),
      mlog_moduleID: log.mlog_moduleID,
      mlog_quote: isNotEmpty(log.mlog_quote) ? log.mlog_quote : "",
      mlog_interpretation: isNotEmpty(log.mlog_interpretation)
        ? log.mlog_interpretation
        : "",
      mlog_reflection: isNotEmpty(log.mlog_reflection)
        ? log.mlog_reflection
        : "",
    },
  }));

  const auth = useAuth();
  const [moduleLogs, setModuleLogs] = useState<
    { key: string; log: ModuleLogData }[]
  >(values != null ? values : []);
  const [newModuleLogIndex, setNewModuleLogIndex] = useState<number>(
    props.module.moduleLogs != null ? props.module.moduleLogs.length + 1 : 1
  );
  const defaultLogValue = {
    mlog_moduleID: props.module.moduleID,
    mlog_userID: auth?.user?.userID,
    mlog_quote: "",
    mlog_interpretation: "",
    mlog_reflection: "",
  };

  const addModuleLog = (): void => {
    const keyValue = `${props.module.moduleID}-new-${newModuleLogIndex}`;
    setModuleLogs(() => [
      ...moduleLogs,
      {
        key: `${props.module.moduleID}-${newModuleLogIndex}`,
        log: {
          ...defaultLogValue,
          module_logID: keyValue,
        },
      },
    ]);
    setNewModuleLogIndex(newModuleLogIndex + 1);
  };
  const updateModuleLog = (key: string, ml: ModuleLog): void => {
    const index = moduleLogs.findIndex((log) => log.key === key);
    setModuleLogs((moduleLogs) => {
      moduleLogs[index].log.module_logID = ml.module_logID.toString();
      moduleLogs[index].log.mlog_quote = isNotEmpty(ml.mlog_quote)
        ? ml.mlog_quote
        : "";
      moduleLogs[index].log.mlog_interpretation = isNotEmpty(
        ml.mlog_interpretation
      )
        ? ml.mlog_interpretation
        : "";
      moduleLogs[index].log.mlog_reflection = isNotEmpty(ml.mlog_reflection)
        ? ml.mlog_reflection
        : "";
      return [...moduleLogs];
    });
  };

  const removeModuleLog = (index: number): void => {
    setModuleLogs([
      ...moduleLogs.slice(0, index),
      ...moduleLogs.slice(index + 1),
    ]);
  };

  const handleRemoveModuleLog = async (key: string): Promise<void> => {
    const index = moduleLogs.findIndex((log) => log.key === key);

    if (!moduleLogs[index].log.module_logID.includes("new")) {
      await deleteData(`module-logs/${moduleLogs[index].log.module_logID}`)
        .then(() => {
          removeModuleLog(index);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      removeModuleLog(index);
    }
  };

  if (moduleLogs.length < 2) {
    addModuleLog();
  }

  useEffect(() => {
    if (onChangeRef.current) onChangeRef.current(moduleLogs);
  }, [moduleLogs]);

  return (
    <ModuleLogFormWrapper>
      <div className="divide-y border">
        {moduleLogs.map((logData, index) => (
          <ModuleLogFormField
            key={logData.key}
            index={index}
            log={logData.log}
            onRemoveLog={() => handleRemoveModuleLog(logData.key)}
            onSaveLog={(ml: ModuleLog) => updateModuleLog(logData.key, ml)}
          />
        ))}
      </div>
      <AppendFormFieldButton
        className="mt-4"
        onButtonClick={addModuleLog}
        disabled={isFormReadOnly}
      >
        <span className="font-semibold">Ny rad</span>
      </AppendFormFieldButton>
    </ModuleLogFormWrapper>
  );
}

function hasUnsavedChanges(data: ModuleLogData, moduleLog: ModuleLog): boolean {
  return !(
    ((isEmpty(moduleLog.mlog_quote) && isEmpty(data.mlog_quote.trim())) ||
      data.mlog_quote.trim() === moduleLog.mlog_quote) &&
    ((isEmpty(moduleLog.mlog_interpretation) &&
      isEmpty(data.mlog_interpretation.trim())) ||
      data.mlog_interpretation.trim() === moduleLog.mlog_interpretation) &&
    ((isEmpty(moduleLog.mlog_reflection) &&
      isEmpty(data.mlog_reflection.trim())) ||
      data.mlog_reflection.trim() === moduleLog.mlog_reflection)
  );
}

/**
 *
 * @param props
 */
function ModuleLogFormField(props: {
  log: ModuleLogData;
  index: number;
  onRemoveLog: () => void;
  onSaveLog: (moduleLog: ModuleLog) => void;
}): ReactElement {
  const isFormReadOnly = useIsReadOnly();
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [confirmRemoveLog, setConfirmRemoveLog] = useState<boolean>(false);

  const autoSave = useAutoSave<ModuleLog, ModuleLogData>(
    "module-logs",
    props.log.module_logID,
    hasUnsavedChanges
  );

  const methods = useForm<ModuleLogData>({
    resolver: yupResolver(schema),
    defaultValues: props.log,
  });

  useEffect(() => {
    if (
      autoSave.lastSavedObject != null &&
      hasUnsavedChanges(props.log, autoSave.lastSavedObject)
    ) {
      props.onSaveLog(autoSave.lastSavedObject);
    }
  }, [autoSave.lastSavedObject, props]);

  const handleChange = (): void => {
    if (timer) {
      setTimer(undefined);
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        void methods.trigger().then((isValid) => {
          const values = methods.getValues();
          if (isValid) {
            autoSave.setSaveObject(values);
          }
        });
      }, 1000)
    );
  };

  return (
    <form onChange={handleChange}>
      <div className="grid grid-cols-3 divide-x divide-opacity-20 divide-ocean-blue h-48 relative">
        <div className="flex">
          <div className="flex items-center bg-ocean-blue bg-opacity-10 text-center text-xl">
            <span className="px-1.5 font-bold opacity-40">
              {props.index + 1}
            </span>
          </div>
          <input type="hidden" {...methods.register(`module_logID`)} />
          <input type="hidden" {...methods.register(`mlog_moduleID`)} />
          <input type="hidden" {...methods.register(`mlog_userID`)} />
          <TextareaWrapper>
            <TextareaInput
              {...methods.register(`mlog_quote`)}
              disabled={isFormReadOnly}
            />
            <div className="absolute bottom-0 left-0 pl-2 w-full text-sm">
              {methods.formState.errors.mlog_quote?.message}
            </div>
          </TextareaWrapper>
        </div>

        <TextareaWrapper>
          <TextareaInput
            {...methods.register(`mlog_interpretation`)}
            disabled={isFormReadOnly}
          />
          <div className="absolute bottom-0 left-0 pl-2 w-full text-sm">
            {methods.formState.errors.mlog_interpretation?.message}
          </div>
        </TextareaWrapper>
        <div className="flex">
          <TextareaWrapper>
            <TextareaInput
              {...methods.register(`mlog_reflection`)}
              disabled={isFormReadOnly}
            />
            <div className="absolute bottom-0 left-0 pl-2 w-full text-sm">
              {methods.formState.errors.mlog_reflection?.message}
            </div>
          </TextareaWrapper>
          <button
            disabled={isFormReadOnly}
            type="button"
            className="x-button flex items-center bg-ocean-blue-dark bg-opacity-10 hover:bg-opacity-20 transition"
            onClick={() => setConfirmRemoveLog(true)}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
          {confirmRemoveLog && (
            <div className="absolute inset-0 bg-ocean-blue-dark bg-opacity-50 flex items-center justify-center">
              <div className="bg-white w-60 p-5 z-50 border rounded-sm border-blue-gray">
                <div className="font-semibold text-center mb-3">
                  Är du säker på att du vill ta bort raden?
                </div>
                <div className="flex justify-center space-x-2">
                  <FilledButton
                    type="button"
                    width="w-20"
                    size="small"
                    content="Ja"
                    onClick={() => props.onRemoveLog()}
                  />
                  <Button
                    type="button"
                    size="small"
                    width="w-20"
                    content="Nej"
                    onClick={() => setConfirmRemoveLog(false)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
}
