import { ReactElement, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { hasUserCapabilities } from "../../api/Auth";
import { useAuth, Capabilities } from "../../hooks/ProvideAuth";

export default function MenuItem(props: {
  path: string;
  children: ReactNode;
  capabilities?: Capabilities[];
}): ReactElement {
  const auth = useAuth();
  return props.capabilities != null &&
    !hasUserCapabilities(
      props.capabilities,
      auth?.userCapabilities != null ? auth.userCapabilities : []
    ) ? (
    <></>
  ) : (
    <li className="block pb-2 ">
      <NavLink
        exact
        to={props.path}
        className="relative"
        activeStyle={{
          fontWeight: "bold",
          color: "#1c557f",
        }}
      >
        {props.children}
      </NavLink>
    </li>
  );
}
