import { ReactElement } from "react";

export default function MenuItemIndicator(props: {
  itemsCount: number;
}): ReactElement {
  return (
    <div className="absolute -top-0.5 -right-6 bg-fresh-mint text-white rounded-full h-5 w-5 text-sm flex items-center justify-center">
      {props.itemsCount}
    </div>
  );
}
