import { ReactElement } from "react";
import { UserRole } from "../../api/types";
import { isNotEmpty } from "../../util";

const userRoleNames: { [x: string]: string } = {
  admin: "Admin",
  orgAdmin: "Organisationsadministratör",
  clientOrgAdmin: "Uppdragsgivaradministratör",
  regUser: "Användare",
};
export default function UserRoles(props: {
  usrRoles?: UserRole[] | undefined;
}): ReactElement {
  return (
    <>
      {props.usrRoles != null
        ? props.usrRoles
            .map((u) =>
              u.role_name in userRoleNames ? userRoleNames[u.role_name] : null
            )
            .filter((role) => isNotEmpty(role))
            .join(", ")
        : "Saknas"}
    </>
  );
}
