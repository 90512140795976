import { ReactElement } from "react";
import Menu from "./Menu";
import MenuItem from "./MenuItem";
import MenuSection from "./MenuSection";
import NoticesMenuItem from "./NoticesMenuItem";
import ResourceMenutItems from "./ResourceMenuItems";

export default function OrgAdminMenu(): ReactElement {
  return (
    <Menu>
      <MenuSection>
        <MenuItem path="/">Hem</MenuItem>
        <MenuItem path="/organisationer">Organisationer</MenuItem>
        <MenuItem path="/anvandare">Användare</MenuItem>
        <MenuItem path="/genomforda-moduler">Genomförda moduler</MenuItem>
      </MenuSection>
      <MenuSection>
        <NoticesMenuItem />
        <MenuItem path="/laslogg">Läsloggar</MenuItem>
        <MenuItem path="/moduler">Moduler</MenuItem>
        <ResourceMenutItems />
      </MenuSection>
      <MenuSection>
        <MenuItem path="/mitt-konto">Mitt konto</MenuItem>
      </MenuSection>
    </Menu>
  );
}
