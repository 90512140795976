import { ReactElement, useMemo, useRef } from "react";
import { getMediaByRefAndType, isNotEmpty } from "../../util";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { Media, Module } from "../../api/types";
import LinkToFile from "../LinkToFile";
import TopImage from "../layout/TopImage";
import { Button } from "../Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChalkboardTeacher,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";

export default function IntroductionModule(props: {
  module: Module;
}): ReactElement {
  return (
    <div>
      <TopImage
        imagePath="/introduction-module.jpg"
        className="bg-center relative  flex items-center justify-center"
      />

      <div className="module-content-wrapper">
        <div className="mb-12">
          <h1>Introduktionsmodul</h1>
        </div>

        <Introduction />
        <Presentation module={props.module} />
        <PlatformInfo module={props.module} />
        <ProcessInfo module={props.module} />
        <ToolsInfo module={props.module} />
        <WorkFlowAndSupportMaterial module={props.module} />
        <Resources module={props.module} />
      </div>
    </div>
  );
}

function Introduction(): ReactElement {
  return (
    <div className="module-block">
      <div className="intro">
        <div>
          <div className="mb-4">
            I den här modulen finns sex filmer på sammanlagt ca. 1h och 10 min.
          </div>
          <div className="mb-4">
            Innehållet vägleder er i hur ni ska gå tillväga och ni får med er
            några olika verktyg som ni kan ha nytta av när ni sedan tar er an de
            olika modulerna och läsningen av boken Mångfaldens förskola.
          </div>
          <div className="mb-4">
            I de två sista filmerna i den här modulen går jag metodiskt igenom
            arbetsgången och stödmaterialet i modulerna, dess struktur och
            innehåll.
          </div>
          <div className="mb-4">
            Ett råd är att inte ha för bråttom till de 15 kapitelmodulerna. Se
            filmerna nedan i lugn och ro och reflektera över innehållet och vad
            som är klokt att göra för att just du och ni tillsammans ska lyckas
            med det ni önskar.
          </div>
        </div>
      </div>
    </div>
  );
}

function Presentation(props: { module: Module }): ReactElement {
  const presentationMedia = useMemo(
    () =>
      getMediaByRefAndType(
        props.module.all_media,
        "intro-presentation",
        "video"
      ),
    [props.module]
  );

  const documents = useMemo(
    () =>
      getMediaByRefAndType(
        props.module.all_media,
        "intro-presentation",
        "document"
      ),
    [props.module.all_media]
  );

  return (
    <div className="module-block mb-8">
      <div className="intro">
        <h3>Om mig och syftet med webbutbildningen</h3>
        <div>
          I den här filmen får du ta del av en kort presentation av vem jag är
          och vad syftet är med webbutbildningen. Jag försöker också skicka med
          lite kloka råd utifrån de erfarenheter jag skaffat mig genom de
          utbildningsinsatser jag lett genom åren.
        </div>
      </div>
      <div className="module-video">
        {presentationMedia.map((media: Media) => (
          <VideoPlayer
            mediaID={media.mediaID}
            moduleID={props.module.moduleID}
            messages={
              props.module.messages
                ? [
                    ...props.module.messages.filter(
                      (message) =>
                        message.msg_type === "LVC" &&
                        message.msg_mediaID === media.mediaID
                    ),
                  ]
                : []
            }
            key={media.mediaID}
            videoUrl={media.media_url}
            posterUrl={
              isNotEmpty(media.media_thumbnail_url)
                ? media.media_thumbnail_url
                : "/om-mig.png"
              // process.env.PUBLIC_URL + props.module.m_lecture_video_thumbnail
            }
          />
        ))}
      </div>
      {documents.length > 0 && (
        <div className="mt-8">
          {documents.map((media: Media) => (
            <LinkToFile
              key={media.mediaID}
              path={media.media_url}
              displayName={media.media_title}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function PlatformInfo(props: { module: Module }): ReactElement {
  const platformInfoMedia = useMemo(
    () =>
      getMediaByRefAndType(props.module.all_media, "intro-platform", "video"),
    [props.module]
  );
  const documents = useMemo(
    () =>
      getMediaByRefAndType(
        props.module.all_media,
        "intro-platform",
        "document"
      ),
    [props.module.all_media]
  );
  return (
    <div className="module-block  mb-8">
      <div className="intro">
        <h3>Plattformens uppbyggnad</h3>
        <div>
          I den här filmen presenteras plattformen och modulinnehållet. Du som
          uppdragsgivare/ uppdragsadministratör kommer även ha möjlighet att
          logga in och lära känna plattformen och dess innehåll innan användarna
          loggar in och startar kompetensutvecklingen.{" "}
        </div>
      </div>
      <div className="module-video">
        {platformInfoMedia.map((media: Media) => (
          <VideoPlayer
            key={media.mediaID}
            mediaID={media.mediaID}
            moduleID={props.module.moduleID}
            messages={
              props.module.messages
                ? [
                    ...props.module.messages.filter(
                      (message) =>
                        message.msg_type === "LVC" &&
                        message.msg_mediaID === media.mediaID
                    ),
                  ]
                : []
            }
            videoUrl={media.media_url}
            posterUrl={
              isNotEmpty(media.media_thumbnail_url)
                ? media.media_thumbnail_url
                : "/plattformens-uppbyggnad.png"
              // process.env.PUBLIC_URL + props.module.m_lecture_video_thumbnail
            }
          />
        ))}
      </div>
      {documents.length > 0 && (
        <div className="mt-8">
          {documents.map((media: Media) => (
            <LinkToFile
              key={media.mediaID}
              path={media.media_url}
              displayName={media.media_title}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function ProcessInfo(props: { module: Module }): ReactElement {
  const processInfoMedia = useMemo(
    () =>
      getMediaByRefAndType(props.module.all_media, "intro-process", "video"),
    [props.module]
  );
  const documents = useMemo(
    () =>
      getMediaByRefAndType(props.module.all_media, "intro-process", "document"),
    [props.module.all_media]
  );
  return (
    <div className="module-block  mb-8">
      <div className="intro">
        <h3>Professions- och förskoleutveckling</h3>
        <div>
          I den här filmen belyser jag några aspekter som rör
          professionsutveckling och förskoleutveckling framför individuell
          kompetensutveckling samt systematiskt kvalitetsarbete.
        </div>
      </div>
      <div className="module-video">
        {processInfoMedia.map((media: Media) => (
          <VideoPlayer
            mediaID={media.mediaID}
            moduleID={props.module.moduleID}
            messages={
              props.module.messages
                ? [
                    ...props.module.messages.filter(
                      (message) =>
                        message.msg_type === "LVC" &&
                        message.msg_mediaID === media.mediaID
                    ),
                  ]
                : []
            }
            key={media.mediaID}
            videoUrl={media.media_url}
            posterUrl={
              isNotEmpty(media.media_thumbnail_url)
                ? media.media_thumbnail_url
                : "/proffesions-och-forskoleutveckling.png"
              // process.env.PUBLIC_URL + props.module.m_lecture_video_thumbnail
            }
          />
        ))}
      </div>
      {documents.length > 0 && (
        <div className="mt-8">
          {documents.map((media: Media) => (
            <LinkToFile
              key={media.mediaID}
              path={media.media_url}
              displayName={media.media_title}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function ToolsInfo(props: { module: Module }): ReactElement {
  const toolsInforMedia = useMemo(
    () => getMediaByRefAndType(props.module.all_media, "intro-tools", "video"),
    [props.module]
  );
  const documents = useMemo(
    () =>
      getMediaByRefAndType(props.module.all_media, "intro-tools", "document"),
    [props.module.all_media]
  );
  return (
    <div className="module-block mb-8">
      <div className="intro">
        <h3>Stödverktyg</h3>
        <div>
          I den här filmen beskriver jag några stödverktyg och ger några råd vad
          gäller specifika stödfunktioner i organisationen.
        </div>
      </div>
      <div className="module-video">
        {toolsInforMedia.map((media: Media) => (
          <VideoPlayer
            mediaID={media.mediaID}
            key={media.mediaID}
            moduleID={props.module.moduleID}
            messages={
              props.module.messages
                ? [
                    ...props.module.messages.filter(
                      (message) =>
                        message.msg_type === "LVC" &&
                        message.msg_mediaID === media.mediaID
                    ),
                  ]
                : []
            }
            videoUrl={media.media_url}
            posterUrl={
              isNotEmpty(media.media_thumbnail_url)
                ? media.media_thumbnail_url
                : "/stodverktyg.png"
              // process.env.PUBLIC_URL + props.module.m_lecture_video_thumbnail
            }
          />
        ))}
      </div>
      {documents.length > 0 && (
        <div className="mt-8">
          {documents.map((media: Media) => (
            <LinkToFile
              key={media.mediaID}
              path={media.media_url}
              displayName={media.media_title}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function WorkFlowAndSupportMaterial(props: { module: Module }): ReactElement {
  const toolsInforMedia = useMemo(
    () =>
      getMediaByRefAndType(
        props.module.all_media,
        "intro-workflow-support-material",
        "video"
      ),
    [props.module]
  );
  const documents = useMemo(
    () =>
      getMediaByRefAndType(
        props.module.all_media,
        "intro-workflow-support-material",
        "document"
      ),
    [props.module.all_media]
  );
  return (
    <div className="module-block mb-8">
      <div className="intro">
        <h3>Arbetsgång och stödmaterial</h3>
        <div>
          I de här två filmerna beskriver jag, steg för steg, arbetsgången och
          stödmaterialet som finns i varje modul. Kanhända det är mycket att
          processa så här i inledningen av utbildningsinsatsen, men ni kommer
          snart in i det återkommande mönstret i varje modul.
        </div>
      </div>
      <div className="module-video">
        {toolsInforMedia.map((media: Media) => (
          <VideoPlayer
            mediaID={media.mediaID}
            key={media.mediaID}
            moduleID={props.module.moduleID}
            messages={
              props.module.messages
                ? [
                    ...props.module.messages.filter(
                      (message) =>
                        message.msg_type === "LVC" &&
                        message.msg_mediaID === media.mediaID
                    ),
                  ]
                : []
            }
            videoUrl={media.media_url}
            posterUrl={
              isNotEmpty(media.media_thumbnail_url)
                ? media.media_thumbnail_url
                : "/workflow-support-material.png"
              // process.env.PUBLIC_URL + props.module.m_lecture_video_thumbnail
            }
          />
        ))}
      </div>
      {documents.length > 0 && (
        <div className="mt-8">
          {documents.map((media: Media) => (
            <LinkToFile
              key={media.mediaID}
              path={media.media_url}
              displayName={media.media_title}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export function Resources({ module }: { module: Module }): ReactElement {
  const fieldRef = useRef<HTMLInputElement>(null);

  const resourceLectures = useMemo(
    () =>
      getMediaByRefAndType(
        module.all_media,
        "intro-fristaende-forelasningar",
        "document"
      ),
    [module]
  );
  if (
    module.all_media == null ||
    !("show-resource-hints" in module.all_media)
  ) {
    return <></>;
  }

  if (resourceLectures.length < 1) return <></>;
  return (
    <div className="module-block my-8" ref={fieldRef} id="testar">
      <div className="intro">
        <h3 className="flex justify-between">
          Fristående föreläsningar och workshops för fördjupad kunskap
          <Button
            size="medium"
            content={
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://forskoleutveckling.nu/tillgangliga-resurser/#forelasningar"
                className="flex items-center gap-2"
              >
                Läs mer på hemsidan
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </a>
            }
          />
        </h3>
        <div>
          Är ni intresserade av att fördjupa förståelsen för innehållet i boken
          Mångfaldens förskola? Har ni funnit en del i boken som intresserar er
          särskilt mycket eller som ni upplever att ni behöver mer kunskap om?
        </div>
      </div>
      <div>
        <h4 className="mb-8">TILLGÄNGLIGA FÖRELÄSNINGAR</h4>
        <div className="flex flex-col gap-8">
          {resourceLectures.map((media: Media) => (
            <div
              key={media.mediaID}
              className="flex flex-col sm:flex-row gap-4 sm:gap-8 items-center"
            >
              <div className="w-full sm:w-44 flex-none">
                <div className="relative overflow-hidden sm:rounded-full pb-1/2 sm:pb-full border border-dark-gray bg-soft-mint bg-opacity-60">
                  {isNotEmpty(media.media_thumbnail_url) ? (
                    <img
                      className="absolute inset-0 object-cover w-full h-full"
                      src={media.media_thumbnail_url}
                      alt={media.media_title}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faChalkboardTeacher}
                      className="absolute inset-0 object-cover w-full h-full m-auto opacity-40"
                      size="3x"
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <h5 className="font-bold">{media.media_title}</h5>
                <div>{media.media_description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Button
        className="mt-8"
        size="medium"
        content={
          <a
            href="https://forskoleutveckling.nu/tillgangliga-resurser/#forelasningar"
            className="flex items-center gap-2"
            rel="noopener noreferrer"
            target="_blank"
          >
            Läs mer om föreläsningarna
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
        }
      />
    </div>
  );
}
