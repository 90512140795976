import { ReactElement, useMemo } from "react";
import { Redirect, useParams } from "react-router";
import IntroductionModule from "../components/modules/IntroductionModule";
import LectureModule from "../components/modules/LectureModule";
import Loader from "../components/layout/Loader";
import { useAuth } from "../hooks/ProvideAuth";
import ModuleLayout from "../components/layout/ModuleLayout";
import { useGetCourseModule } from "../api/endpoints";

export default function CourseModule(): ReactElement {
  const auth = useAuth();
  const params = useParams<{ id: string }>();
  const query = useGetCourseModule("id" in params ? params["id"] : "");

  const module = useMemo(() => {
    if (query.isSuccess) {
      const currentUserId = auth?.user?.userID;
      query.data.moduleLogs = query.data.moduleLogs?.filter(
        (m) => m.mlog_userID === currentUserId
      );
      query.data.messages = query.data.messages?.filter((m) =>
        m.msg_type === "FBC"
          ? m.msg_for_userID === currentUserId
          : m.msg_userID === currentUserId
      );
      query.data.moduleProgresses = query.data.moduleProgresses?.filter(
        (m) => m.mprog_userID === currentUserId
      );
      return query.data;
    }
    return null;
  }, [query.isSuccess, query.data, auth?.user?.userID]);

  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      if (query.error.statusCode === 403) {
        return (
          <Redirect
            to={{
              pathname: `/`,
              state: {
                alert: {
                  message: "Du har inte tillgång till detta.",
                  color: "error",
                  link: undefined,
                  timeoutLength: 5000,
                },
              },
            }}
          />
        );
      }
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      if (module != null) {
        return (
          <ModuleLayout>
            {query.data.m_number === 0 ? (
              <IntroductionModule module={module} />
            ) : (
              <LectureModule module={module} />
            )}
          </ModuleLayout>
        );
      } else {
        return (
          <ModuleLayout>
            <Loader />
          </ModuleLayout>
        );
      }
  }
}
