import { ReactElement, useMemo } from "react";
import { useGetResourceCatergories } from "../../api/endpoints";
import { Capabilities, useAuth } from "../../hooks/ProvideAuth";
import { isEmpty } from "../../util";
import MenuItem from "./MenuItem";

export default function ResourceMenutItems(): ReactElement {
  const auth = useAuth();
  const displayResourceItems = useMemo(
    () =>
      auth?.userCapabilities != null &&
      auth.userCapabilities.some((uc) => uc === Capabilities.view_resources),
    [auth]
  );

  return displayResourceItems ? <ResourceCategoryMenuItems /> : <></>;
}

function ResourceCategoryMenuItems(): ReactElement {
  const query = useGetResourceCatergories();
  switch (query.status) {
    case "idle":
    case "loading":
    case "error":
      return <></>;
    case "success":
      return (
        <>
          {query.data.items.map((rc, index) => (
            <MenuItem
              key={index}
              path={`/resurser/${
                isEmpty(rc.rc_slug) ? rc.resource_CategoryID : rc.rc_slug
              }`}
            >
              {rc.rc_title}
            </MenuItem>
          ))}
        </>
      );
  }
}
