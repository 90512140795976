import { ReactElement } from "react";
import { UseFormReturn } from "react-hook-form";
import { ClientData } from "../api/form-types";
import FormError from "../components/formLayout/FormError";
import { FormInput } from "../components/formLayout/FormInput";
import FormInputWrapper from "../components/formLayout/FormInputWrapper";
import FormLabel from "../components/formLayout/FormLabel";
import {
  FormSection,
  FormSectionBody,
} from "../components/formLayout/FormSection";

export function ClientFormSection(props: {
  methods: UseFormReturn<ClientData>;
}): ReactElement {
  const methods = props.methods;
  return (
    <>
      <FormSection className="org-basic-info" title="Organisation">
        <FormSectionBody>
          <FormInputWrapper>
            <FormLabel htmlFor="cl_name" label="Namn" />
            <FormInput {...methods.register("cl_name" as const)} type="text" />

            <FormError message={methods.formState.errors.cl_name?.message} />
          </FormInputWrapper>
          <FormInputWrapper>
            <FormLabel htmlFor="cl_org_number" label="Organisationsnummer" />
            <FormInput
              {...methods.register("cl_org_number" as const)}
              type="text"
            />
            <FormError
              message={methods.formState.errors.cl_org_number?.message}
            />
          </FormInputWrapper>
        </FormSectionBody>
      </FormSection>
      <FormSection title="Kontaktuppgifter">
        <FormSectionBody>
          <FormInputWrapper>
            <FormLabel htmlFor="cl_contact_name" label="Namn" />
            <FormInput
              {...methods.register("cl_contact_name" as const)}
              type="text"
            />
            <FormError
              message={methods.formState.errors.cl_contact_name?.message}
            />
          </FormInputWrapper>
          <FormInputWrapper>
            <FormLabel htmlFor="cl_contact_email" label="E-post" />
            <FormInput
              {...methods.register("cl_contact_email" as const)}
              type="email"
            />
            <FormError
              message={methods.formState.errors.cl_contact_email?.message}
            />
          </FormInputWrapper>
          <FormInputWrapper>
            <FormLabel htmlFor="cl_contact_phone" label="Telefon" />
            <FormInput
              {...methods.register("cl_contact_phone" as const)}
              type="text"
            />
            <FormError
              message={methods.formState.errors.cl_contact_phone?.message}
            />
          </FormInputWrapper>
        </FormSectionBody>
      </FormSection>
    </>
  );
}
