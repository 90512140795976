import { useQuery, UseQueryResult } from "react-query";
import { fetchApiGet } from "../../api/api";
import { ApiError } from "../../api/ApiError";
import { getQueryString } from "../../api/endpoints";
import { GetListResponse, Resource, ResourceCategory } from "../../api/types";
import { isNotEmpty } from "../../util";

export function useGetResource(id: string): UseQueryResult<Resource, ApiError> {
  const key = ["resources", id];
  const path = `resources/${id}?expand=media`;

  return useQuery<Resource, ApiError>(key, () => fetchApiGet<Resource>(path));
}

export function useGetResources(props?: {
  categorySlug?: string;
  page?: string | null;
  filter?: { slug: string };
  perPage?: number;
}): UseQueryResult<GetListResponse<Resource>, Error> {
  let key = ["resources"];
  const queryStrings: string[] = [];

  if (props != null) {
    if (isNotEmpty(props.page)) {
      key = [...key, "page", props.page];
      queryStrings.push("page=" + props.page);
    }

    if (props.categorySlug != null) {
      key = [...key, "slug", props.categorySlug];
      queryStrings.push("category-slug=" + props.categorySlug);
    }
    if (props.filter != null) {
      key = [...key, "filter", props.filter.slug];

      if (isNotEmpty(props.filter.slug)) {
        key = [...key, "slug", props.filter.slug];
        queryStrings.push("filter[rc_slug][like]=" + props.filter.slug);
      }
    }
    if (props.perPage != null) {
      queryStrings.push(`per-page=${props.perPage}`);
      key = [...key, `perPage=${props.perPage}`];
    }
  }

  const path = "resources" + getQueryString(queryStrings);

  return useQuery<GetListResponse<Resource>, Error>(key, () =>
    fetchApiGet<GetListResponse<Resource>>(path)
  );
}

export function useGetResourceCategory(
  id: number
): UseQueryResult<ResourceCategory> {
  const key = ["resource-categories", id];
  const path = `resource-categories/${id}?expand=resources`;

  return useQuery<ResourceCategory, Error>(key, () =>
    fetchApiGet<ResourceCategory>(path)
  );
}
