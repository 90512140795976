import { ApiError } from "./ApiError";
import { PostResponse } from "./types";
import inMemoryToken from "./../hooks/TokenManager";
import { isEmpty, isNotEmpty } from "../util";

const API_STATUS_UNAUTHORIZED = 401;
let isRefreshing = false;
let refreshSubscribers: (() => unknown)[] = [];
const subscribeTokenRefresh = (cb: () => Promise<unknown>): number =>
  refreshSubscribers.push(cb);

const onRefreshed = (): void => {
  // console.log("refreshing ", refreshSubscribers.length, " subscribers");
  refreshSubscribers.map((cb) => cb());
  refreshSubscribers = [];
};

export enum RequestTypes {
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

/**
 *
 */
export function refreshToken<T>(): Promise<T> {
  return fetchApiRequest("auth/refresh-token", {
    method: "POST",
    credentials: "include",
  });
}

/**
 * Make request with fetchAPI to path with req
 * @param path - string
 * @param requestInit - RequestInit
 */
async function fetchApiRequest<T>(
  path: string,
  requestInit?: RequestInit
): Promise<T> {
  let config: RequestInit = { method: "GET", credentials: "include" };
  if (requestInit != null) {
    config = { ...requestInit, credentials: "include" };
  }

  if (isEmpty(process.env.REACT_APP_API_URL)) {
    throw new Error("Missing base url");
  }
  const baseUrl = process.env.REACT_APP_API_URL;

  const token = inMemoryToken.getToken();
  if (isNotEmpty(token) && path !== "auth/refresh-token") {
    config.headers =
      config.headers == null
        ? { Authorization: `Bearer ${token}` }
        : { ...config.headers, ...{ Authorization: `Bearer ${token}` } };
  }

  // trim path from first character slash
  path = path.replace(/^\//, "");
  const response = await fetch(baseUrl + "/" + path, config);

  if (!response.ok) {
    switch (response.status) {
      case API_STATUS_UNAUTHORIZED:
        if (!isRefreshing) {
          isRefreshing = true;
          await refreshToken<PostResponse>()
            .then((res) => {
              if (isNotEmpty(res.token)) {
                inMemoryToken.setToken(res.token);
              }
              isRefreshing = false;
              onRefreshed();
            })
            .catch(() => {
              inMemoryToken.ereaseToken();
              isRefreshing = false;
            });
          if (isNotEmpty(inMemoryToken.getToken())) {
            return fetchApiRequest<T>(path, config);
          }
        } else {
          return new Promise<T>(() => {
            subscribeTokenRefresh(() => fetchApiRequest<T>(path, config));
          });
        }
        break;
      case 422:
        return (await response.json()) as Promise<T>;
      default:
        throw new ApiError(response, (await response.json()) as PostResponse);
    }
  }

  if (response.status === 204) {
    return (null as unknown) as Promise<T>;
  } else {
    return response.json() as Promise<T>;
  }
}

/**
 * Wrapper function for fetchAPI GET requests
 * @param path
 */
export async function fetchApiGet<T>(path: string): Promise<T> {
  return fetchApiRequest(path);
}
/**
 * Wrapper function for fetchAPI DELETE requests
 * @param path
 */
export async function fetchApiDelete<T>(path: string): Promise<T> {
  return fetchApiRequest(path, {
    method: "DELETE",
  });
}

/**
 * Wrapper funciton for fetchAPI POST requests
 * @param path
 * @param data
 */
export async function fetchApi<T>(
  path: string,
  data: URLSearchParams | string | FormData,
  requestType?: RequestTypes
): Promise<T> {
  if (requestType == null) {
    requestType = RequestTypes.POST;
  }

  let requestContentType = "";
  if (data instanceof FormData) {
    requestContentType = "";
  } else if (data instanceof URLSearchParams) {
    requestContentType = "application/x-www-form-urlencoded";
  } else {
    requestContentType = "application/json";
  }

  const requestInit: RequestInit = {
    method: requestType,
    body: data,
  };

  if (isNotEmpty(requestContentType)) {
    requestInit.headers = {
      "Content-Type": requestContentType,
    };
  }

  return fetchApiRequest(path, requestInit);
}
