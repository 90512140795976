import { ReactElement } from "react";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { Button, FilledButton } from "../Buttons";
import Loader from "../../components/layout/Loader";

export default function FormActionButtons<T>(props: {
  methods: UseFormReturn<T>;
  onFormSave: SubmitHandler<T>;
  onFormCancel(): void;
}): ReactElement {
  return (
    <div className="space-x-2 mt-10">
      <div className="flex items-center space-x-2">
        <FilledButton
          content="Spara"
          size="medium"
          className="ml-2"
          onClick={props.methods.handleSubmit<T>(props.onFormSave)}
        />

        <Button
          type="button"
          content="Avbryt"
          size="medium"
          onClick={() => props.onFormCancel()}
        />
        {props.methods.formState.isSubmitting && (
          <Loader className={"ml-2"} noLabel inline />
        )}
      </div>
    </div>
  );
}
