import { ReactElement, useMemo, useState } from "react";
import { useController, useFieldArray, UseFormReturn } from "react-hook-form";
import { OrganizationData } from "../api/form-types";
import FormFieldArrayRow from "../components/formLayout/FormFieldArrayRow";
import FormSectionBodyFieldArray, {
  AppendFormFieldButton,
} from "../components/formLayout/FormFieldArraySection";
import { FormInput } from "../components/formLayout/FormInput";
import FormInputWrapper from "../components/formLayout/FormInputWrapper";
import FormLabel from "../components/formLayout/FormLabel";
import {
  FormSection,
  FormSectionBody,
} from "../components/formLayout/FormSection";
import * as yup from "yup";
import FormSelect from "../components/formLayout/FormSelect";
import { useGetClients, useGetUsers } from "../api/endpoints";
import { Client, SelectOption, User } from "../api/types";
import Select from "react-select";
import { hasRole } from "../api/Auth";
import { useAuth } from "../hooks/ProvideAuth";
import Loader from "../components/layout/Loader";
import FormError from "../components/formLayout/FormError";

export const organizationSchema = yup.object().shape({
  org_clientID: yup
    .number()
    .min(1, "Uppdragsgivare är obligatoriskt")
    .required(),
  org_name: yup.string().required("Namn är obligatoriskt"),
  users: yup.array(
    yup.object().shape({
      usr_firstname: yup.string().required("Förnamn är obligatoriskt"),
      usr_lastname: yup.string().required("Efternamn är obligatoriskt"),
      usr_email: yup
        .string()
        .email("E-post kräver en gilltig adress")
        .required("E-post kräver en gilltig adress"),
    })
  ),
});

export function OrganizationFormSection(props: {
  methods: UseFormReturn<OrganizationData>;
}): ReactElement {
  const auth = useAuth();
  return (
    <>
      {auth?.userRoles != null &&
      hasRole(auth.userRoles, ["admin", "clientOrgAdmin", "orgAdmin"]) ? (
        <ClientSelector methods={props.methods} />
      ) : null}

      <FormSection className="org-basic-info" title="Organisation">
        <FormSectionBody>
          <FormInputWrapper>
            <FormLabel htmlFor="org_name" label="Namn" />
            <FormInput
              {...props.methods.register("org_name" as const)}
              type="text"
            />
            <FormError
              message={props.methods.formState.errors.org_name?.message}
            />
          </FormInputWrapper>
        </FormSectionBody>
      </FormSection>
    </>
  );
}
function ClientSelector(props: {
  methods: UseFormReturn<OrganizationData>;
}): ReactElement {
  const query = useGetClients();
  const options: SelectOption[] = [{ label: "Välj ett alternativ", value: 0 }];
  let elements: ReactElement = <div>Ooops...</div>;

  switch (query.status) {
    case "idle":
    case "loading":
      elements = <Loader />;
      break;
    case "error":
      elements = (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
      break;
    case "success":
      if (query.data.items.length === 1) {
        props.methods.setValue("org_clientID", query.data.items[0].clientID);
        return <></>;
      }

      query.data.items.map((c: Client) =>
        options.push({ value: c.clientID, label: c.cl_name })
      );

      elements = (
        <FormSelect
          registerReturn={props.methods.register("org_clientID")}
          options={options}
        />
      );

      break;
  }
  return (
    <FormSection className="org-basic-info" title="Uppdragsgivare">
      <FormSectionBody>{elements}</FormSectionBody>
      <FormError
        message={props.methods.formState.errors.org_clientID?.message}
      />
    </FormSection>
  );
}

/**
 *
 * @param props
 */
export default function AdminUsersFormSection(props: {
  methods: UseFormReturn<OrganizationData>;
}): ReactElement {
  const fieldsArray = useFieldArray({
    name: "users",
    control: props.methods.control,
  });
  const [firstRun, setFirstRun] = useState(true);

  if (fieldsArray.fields.length > 0 && firstRun) {
    fieldsArray.remove(0);
  }

  return (
    <FormSection title="Organisationsadministratörer">
      <FormSection>
        <ExistingUserSelector methods={props.methods} />

        <FormSection>
          <FormLabel label="Nya Användare" htmlFor="users" />
          <FormSectionBodyFieldArray>
            {fieldsArray.fields.map((field, index) => (
              <FormFieldArrayRow
                key={field.id}
                title={`Ny organisationsadministratör ${index + 1}`}
                deleteItem={() => fieldsArray.remove(index)}
              >
                <FormSectionBody>
                  <FormInputWrapper>
                    <FormLabel
                      htmlFor={`users.${index}.usr_firstname`}
                      label="Förnamn"
                    />
                    <FormInput
                      {...props.methods.register(
                        `users.${index}.usr_firstname` as const
                      )}
                      type="text"
                    />
                    {props.methods.formState.errors.users != null && (
                      <FormError
                        message={
                          props.methods.formState.errors.users[index]
                            ?.usr_firstname?.message
                        }
                      />
                    )}
                  </FormInputWrapper>
                  <FormInputWrapper>
                    <FormLabel
                      htmlFor={`users.${index}.usr_lastname`}
                      label="Efternamn"
                    />
                    <FormInput
                      {...props.methods.register(
                        `users.${index}.usr_lastname` as const
                      )}
                      type="text"
                    />
                    {props.methods.formState.errors.users != null && (
                      <FormError
                        message={
                          props.methods.formState.errors.users[index]
                            ?.usr_lastname?.message
                        }
                      />
                    )}
                  </FormInputWrapper>
                  <FormInputWrapper>
                    <FormLabel
                      htmlFor={`users.${index}.usr_email`}
                      label="E-post"
                    />
                    <FormInput
                      {...props.methods.register(
                        `users.${index}.usr_email` as const
                      )}
                      type="email"
                    />
                    {props.methods.formState.errors.users != null && (
                      <FormError
                        message={
                          props.methods.formState.errors.users[index]?.usr_email
                            ?.message
                        }
                      />
                    )}
                  </FormInputWrapper>
                </FormSectionBody>
              </FormFieldArrayRow>
            ))}
            <AppendFormFieldButton
              onButtonClick={() => {
                setFirstRun(false);
                fieldsArray.append({
                  usr_firstname: "",
                  usr_lastname: "",
                  usr_email: "",
                  userRoles: [{ role_name: "orgAdmin" }],
                });
              }}
            >
              Lägg till användare
            </AppendFormFieldButton>
          </FormSectionBodyFieldArray>
        </FormSection>
      </FormSection>
    </FormSection>
  );
}

function ExistingUserSelector(props: {
  methods: UseFormReturn<OrganizationData>;
}): ReactElement {
  const [selectedUsers, setSelectedUsers] = useState<
    { value: number; label: string }[]
  >([]);
  const existingUsersController = useController({
    control: props.methods.control,
    name: "existingUsers",
  });
  const adminsOptionsQuery = useGetUsers();
  const adminsOptions = useMemo(
    () =>
      adminsOptionsQuery.status === "success"
        ? adminsOptionsQuery.data.items.map((u: User) => {
            return {
              value: u.userID,
              label: `${u.usr_firstname} ${u.usr_lastname}`,
            };
          })
        : [],
    [adminsOptionsQuery.data, adminsOptionsQuery.status]
  );

  return (
    <>
      <FormInputWrapper>
        <FormLabel label="Existerande Användare" htmlFor="existingUsers" />
        <Select
          closeMenuOnSelect={false}
          isClearable={true}
          isSearchable={true}
          isMulti
          options={adminsOptions}
          value={selectedUsers}
          onChange={(e) =>
            setSelectedUsers(e as { value: number; label: string }[])
          }
          onMenuClose={() =>
            existingUsersController.field.onChange(
              selectedUsers.reduce(
                (acc: { userID: number; role_name: string }[], curr) => {
                  acc.push({ userID: curr.value, role_name: "orgAdmin" });
                  return acc;
                },
                []
              )
            )
          }
        />
      </FormInputWrapper>
    </>
  );
}
