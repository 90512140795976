import { ReactElement, ReactNode } from "react";
import { Redirect, Route } from "react-router";
import inMemoryToken from "../hooks/TokenManager";
import { isNotEmpty } from "../util";

export default function PrivateRoute(props: {
  path: string;
  children?: ReactNode;
  exact?: boolean;
}): ReactElement {
  return (
    <Route
      exact={props.exact}
      path={props.path}
      render={({ location }) => {
        return isNotEmpty(inMemoryToken.getToken()) ? (
          props.children
        ) : (
          <Redirect
            to={{
              pathname: "/logga-in",
              ...(isNotEmpty(props.path) && {
                state: {
                  from: location,
                  alert: {
                    message:
                      "Du har inte tillgång till detta just nu. Försök logga in först.",
                    color: "primary",
                  },
                },
              }),
            }}
          />
        );
      }}
    />
  );
}
