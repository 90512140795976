import { ReactElement, useState } from "react";
import {
  FieldValues,
  useFieldArray,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import Header from "../components/layout/Header";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  FormSection,
  FormSectionBody,
} from "../components/formLayout/FormSection";
import FormLabel from "../components/formLayout/FormLabel";
import { Button } from "../components/Buttons";
import { LicensesFormSection } from "../forms/LicensesFormSection";
import FormInputWrapper from "../components/formLayout/FormInputWrapper";
import { FormInput } from "../components/formLayout/FormInput";
import FormFieldArrayRow from "../components/formLayout/FormFieldArrayRow";
import FormSectionBodyFieldArray, {
  AppendFormFieldButton,
} from "../components/formLayout/FormFieldArraySection";
import { postData } from "../api/endpoints";
import { Client, PostResponse } from "../api/types";
import { ClientFormSection } from "../forms/ClientFormSection";
import { getValidationGenericErrorMessages, setFormErrors } from "../util";
import { useHistory } from "react-router";
import Loader from "../components/layout/Loader";
import { SimpleLayout } from "../components/layout/SimpleLayout";
import FormError from "../components/formLayout/FormError";
import { ClientData } from "../api/form-types";
import { invalidateUsers } from "../api/InvalidateQueriesHelper";
import { SettingsFormSection } from "../forms/SettingsFormSection";

export const clientSchema = yup.object().shape({
  cl_name: yup.string().required("Namn är obligatoriskt"),
  cl_org_number: yup.string().required("Organisationsnummer är obligatoriskt"),
  cl_contact_name: yup.string().required("Namn är obligatoriskt"),
  cl_contact_email: yup
    .string()
    .email("E-post kräver en gilltig adress")
    .required("E-post är obligatoriskt"),
  cl_contact_phone: yup.string().required("Telefon är obligatoriskt"),
  users: yup.array(
    yup.object().shape({
      usr_firstname: yup.string().required("Förnamn är obligatoriskt"),
      usr_lastname: yup.string().required("Efternamn är obligatoriskt"),
      usr_email: yup
        .string()
        .email("E-post kräver en gilltig adress")
        .required("E-post kräver en gilltig adress"),
    })
  ),
  licenses: yup // TODO: Handle arrayField Errors:  https://github.com/react-hook-form/react-hook-form/issues/1617
    .array(
      yup.object().shape(
        {
          l_start_date: yup.string().required("Från är obligatoriskt"),
          l_end_date: yup.string().required("Till är obligatoriskt"),
          moduleIDs: yup.array(yup.number()).when("resourceIDs", {
            is: (resourceIDs: number[] | undefined) =>
              !resourceIDs || resourceIDs.length < 1,
            then: (s) => s.ensure().min(1, "Välj minst 1 resurs"),
            otherwise: (s) => s,
          }),
          resourceIDs: yup
            .array(yup.number())

            .when("moduleIDs", {
              is: (moduleIDs: number[] | undefined) =>
                !moduleIDs || moduleIDs.length < 1,
              then: (s) => s.ensure().min(1, "Välj minst 1 modul"),
              otherwise: (s) => s,
            }),
        },
        [
          ["l_start_date", "l_end_date"],
          ["l_start_date", "moduleIDs"],
          ["l_start_date", "resourceIDs"],
          ["l_end_date", "resourceIDs"],
          ["l_end_date", "moduleIDs"],
          ["moduleIDs", "resourceIDs"],
        ]
      )
    )
    .min(1, "Fyll i uppgifter för minst en licens"),
});

export default function AddClient(): ReactElement {
  const queryClient = useQueryClient();
  let formDefaults = {};
  formDefaults = {
    defaultValues: {
      users: [],
      licenses: [
        { l_start_date: "", l_end_date: "", moduleIDs: [], resourceIDs: [] },
      ],
    },
    resolver: yupResolver(clientSchema),
  };
  const history = useHistory();
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const methods = useForm<ClientData>(formDefaults);
  const mutation = useMutation((clientData: string) =>
    postData<Client | PostResponse>("clients", clientData)
  );
  const [errorsPresent, setErrorsPresent] = useState<boolean>(false);

  const handleButtonSubmit = async (
    formData: FieldValues,
    e?: React.BaseSyntheticEvent
  ): Promise<void> => {
    await mutation
      .mutateAsync(JSON.stringify(formData))
      .then((res) => {
        if ("errorsItemized" in res) {
          setFormErrors<ClientData>(res.errorsItemized, methods);
          setServerErrors(
            getValidationGenericErrorMessages(res.errorsItemized)
          );
        } else {
          void queryClient.invalidateQueries("clients", { exact: true });
          void queryClient.invalidateQueries({
            predicate: invalidateUsers,
          });
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);

        // TODO: HANDLE ERRORS
        // Set server errors like this
        // methods.setError(f, { message: m });
      });
  };

  return (
    <SimpleLayout>
      <Header title="Ny Uppdragsgivare" />
      <form className="gap-x-8">
        <ClientFormSection methods={methods} />
        <AdminUsersFormSection methods={methods} />
        <LicensesFormSection methods={methods} />
        <SettingsFormSection methods={methods} clientSettings={null} />

        <div>
          {serverErrors.map((e, index) => (
            <FormError key={e + index.toString()} message={e} />
          ))}
        </div>

        <div className="flex items-center">
          <Button
            content="Skapa uppdragsgivare"
            size="medium"
            onClick={methods.handleSubmit(handleButtonSubmit, () =>
              setErrorsPresent(true)
            )}
            // disabled={methods.formState.isDirty && !methods.formState.isValid}
          ></Button>
          {methods.formState.isSubmitting && (
            <Loader className={"ml-2"} noLabel inline />
          )}
          {errorsPresent && (
            <FormError message="Något gick fel" className="pl-5" />
          )}
        </div>
      </form>
    </SimpleLayout>
  );
}

/**
 *
 * @param props
 */
function AdminUsersFormSection(props: {
  methods: UseFormReturn<ClientData>;
}): ReactElement {
  const fieldsArray = useFieldArray({
    name: "users",
    control: props.methods.control,
  });
  return (
    <FormSection title="Uppdragsgivaradministratörer">
      <FormSectionBodyFieldArray>
        {fieldsArray.fields.map((field, index) => (
          <FormFieldArrayRow
            key={field.id}
            title={`Administratör ${index + 1}`}
            deleteItem={() => fieldsArray.remove(index)}
          >
            <FormSectionBody>
              <FormInputWrapper>
                <FormLabel
                  htmlFor={`users.${index}.usr_firstname`}
                  label="Förnamn"
                />
                <FormInput
                  {...props.methods.register(
                    `users.${index}.usr_firstname` as const
                  )}
                  type="text"
                />
                {props.methods.formState.errors.users != null && (
                  <FormError
                    message={
                      props.methods.formState.errors.users[index]?.usr_firstname
                        ?.message
                    }
                  />
                )}
              </FormInputWrapper>
              <FormInputWrapper>
                <FormLabel
                  htmlFor={`users.${index}.usr_lastname`}
                  label="Efternamn"
                />
                <FormInput
                  {...props.methods.register(
                    `users.${index}.usr_lastname` as const
                  )}
                  type="text"
                />
                {props.methods.formState.errors.users != null && (
                  <FormError
                    message={
                      props.methods.formState.errors.users[index]?.usr_lastname
                        ?.message
                    }
                  />
                )}
              </FormInputWrapper>
              <FormInputWrapper>
                <FormLabel
                  htmlFor={`users.${index}.usr_email`}
                  label="E-post"
                />
                <FormInput
                  {...props.methods.register(
                    `users.${index}.usr_email` as const
                  )}
                  type="email"
                />
                {props.methods.formState.errors.users != null && (
                  <FormError
                    message={
                      props.methods.formState.errors.users[index]?.usr_email
                        ?.message
                    }
                  />
                )}
              </FormInputWrapper>
            </FormSectionBody>
          </FormFieldArrayRow>
        ))}
        <AppendFormFieldButton
          onButtonClick={() =>
            fieldsArray.append({
              usr_firstname: "",
              usr_lastname: "",
              usr_email: "",
            })
          }
        >
          Lägg till administratör
        </AppendFormFieldButton>
      </FormSectionBodyFieldArray>
    </FormSection>
  );
}
