import { Capabilities } from "../hooks/ProvideAuth";
import { UserRole } from "./types";

export function hasRole(userRoles: UserRole[], roles: string[]): boolean {
  return roles.some(
    (role) => userRoles.find((r) => r.role_name === role) !== undefined
  );
}
export function hasRoleAccess(
  highestRole: string | undefined | null,
  role: string
): boolean {
  let roles: string[] = [];
  if (highestRole == null) {
    return false;
  }
  if (highestRole === "admin") {
    roles = ["admin", "clientOrgAdmin", "orgAdmin"];
  }
  if (highestRole === "clientOrgAdmin") {
    roles = ["clientOrgAdmin", "orgAdmin"];
  }
  if (highestRole === "orgAdmin") {
    roles = ["orgAdmin"];
  }

  roles.push("regUser");

  return roles.includes(role);
}

// export function getSystemUserRoles(): {
//   [key: string]: {
//     role_name: string;
//     role_name_readable: string;
//     level: number;
//   };
// } {
//   return {
//     admin: {
//       role_name: "admin",
//       role_name_readable: "Administratör",
//       level: 100,
//     },
//     clientOrgAdmin: {
//       role_name: "clientOrgAdmin",
//       role_name_readable: "Uppdragsgivaradministratör",
//       level: 80,
//     },
//     orgAdmin: {
//       role_name: "orgAdmin",
//       role_name_readable: "Organisationsadministratör",
//       level: 30,
//     },
//     regUser: {
//       role_name: "regUser",
//       role_name_readable: "Användare",
//       level: 10,
//     },
//   };
// }

export function hasUserCapabilities(
  requiredCaps: Capabilities[],
  usersCaps: Capabilities[]
): boolean {
  if (usersCaps.length > 0 && requiredCaps.length > 0) {
    return requiredCaps.every((elem) => usersCaps.includes(elem));
  }
  return requiredCaps.length < 1;
}

export function getUserRoleCapabilities(highestRole: string): Capabilities[] {
  const caps: Capabilities[] = [];
  const roles = getRoleHierarchy(highestRole);
  // console.log(roles);
  if (roles.admin) {
    caps.push(Capabilities.manage_notices);
  }
  if (roles.clientOrgAdmin) {
    caps.push(Capabilities.view_client_routes);
  }
  if (roles.orgAdmin) {
    caps.push(Capabilities.view_org_routes);
    caps.push(Capabilities.view_user_routes);
  }
  return caps;
}

interface HasRole {
  admin: boolean;
  clientOrgAdmin: boolean;
  orgAdmin: boolean;
  regUser: boolean;
}
function getRoleHierarchy(highestRole: string): HasRole {
  const roles: HasRole = {
    admin: false,
    clientOrgAdmin: false,
    orgAdmin: false,
    regUser: true,
  };
  if (highestRole === "admin") {
    roles.admin = true;
    roles.clientOrgAdmin = true;
    roles.orgAdmin = true;
  }
  if (highestRole === "clientOrgAdmin") {
    roles.clientOrgAdmin = true;
    roles.orgAdmin = true;
  }
  if (highestRole === "orgAdmin") {
    roles.orgAdmin = true;
  }

  return roles;
}
