import { ReactElement } from "react";
import { Link } from "react-router-dom";
import UserOrganization from "../../components/UserOrganizations";
import Loader from "../../components/layout/Loader";
import UserRoles from "./UserRoles";
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import TableBody from "../../components/Table/TableBody";
import { GetListResponse, User } from "../../api/types";
import { UseQueryResult } from "react-query";
import { isNotEmpty } from "../../util";
interface UsersTableProps {
  usersQuery: UseQueryResult<GetListResponse<User>, Error>;
}

export default function UsersTable({
  usersQuery,
}: UsersTableProps): ReactElement {
  switch (usersQuery.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      return (
        <Table>
          <TableHeader>
            <th>Namn</th>
            <th>E-post</th>
            <th>Konto</th>
            <th>Organisation</th>
            <th>Åtgärder</th>
          </TableHeader>
          <TableBody>
            {usersQuery.data.items.map((u) => (
              <tr key={u.usr_email}>
                <td>
                  <Link to={`/anvandare/${u.userID}`}>
                    {isNotEmpty(`${u.usr_firstname} ${u.usr_lastname}`.trim())
                      ? `${u.usr_firstname} ${u.usr_lastname}`
                      : u.usr_email}
                  </Link>
                </td>
                <td>{u.usr_email}</td>
                <td>
                  <UserRoles usrRoles={u.userRoles} />
                </td>
                <td>
                  <UserOrganization usrRoles={u.userRoles} />
                </td>
                <td>
                  <Link to={`/anvandare/redigera/${u.userID}`}>Redigera</Link>
                </td>
              </tr>
            ))}
          </TableBody>
        </Table>
      );
  }
}
