import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement } from "react";

interface LinkToFileProps {
  path: string;
  displayName: string;
  displayIcon?: boolean;
}

export default function LinkToFile({
  path,
  displayName,
  displayIcon,
}: LinkToFileProps): ReactElement {
  return (
    <div
      className={
        "pdf cursor-pointer" +
        (displayIcon == null || displayIcon ? " mt-6" : "")
      }
    >
      <a href={path}>
        {(displayIcon == null || displayIcon) && (
          <FontAwesomeIcon icon={faFilePdf} size="2x" className="mr-2" />
        )}
        <span className="font-md font-semibold">{displayName}</span>
      </a>
    </div>
  );
}
