import { faChevronRight, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import React, { ReactElement, ReactNode, useState } from "react";
import { Notice } from "../../api/types";
import { Button, FilledButton } from "../Buttons";
import CommunicationUserImg from "./CommunicationUserImg";
import "./communication.css";
import { useHistory } from "react-router";
import { useAuth } from "../../hooks/ProvideAuth";

interface NoticeProps {
  notice: Notice;
  onRemoveNotice: () => void;
}

export default function NoticeCard(props: NoticeProps): ReactElement {
  const auth = useAuth();
  const excerptLength = 400;
  const showExcerpt = props.notice.msg_text.length > excerptLength;
  const [showFullText, setFullText] = useState(!showExcerpt);
  const date = new Date(props.notice.msg_created);

  const userName = "Anniqa Sandell Ring";
  return (
    <div className="bg-medium-gray relative w-full xl:w-2/3">
      {auth?.highestRole === "admin" && (
        <AdminNoticeActionBar
          notice={props.notice}
          onRemoveNotice={props.onRemoveNotice}
        />
      )}
      <div className="p-8 flex w-full">
        <CommunicationUserImg message={props.notice} />
        <div className="ml-6 flex-1 relative">
          <span className="font-bold uppercase">{userName}</span>
          <span className="float-right text-black">
            {`${date.toLocaleDateString("sv-se", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })} | ${date.toLocaleTimeString("sv-se", {
              hour: "2-digit",
              minute: "2-digit",
            })}`}
          </span>

          <p className="mt-4 whitespace-pre-wrap">
            {showFullText
              ? props.notice.msg_text
              : props.notice.msg_text.substr(0, excerptLength) + "..."}
          </p>

          {showExcerpt && (
            <div className="absolute flex justify-end items-center uppercase h-8 w-full -bottom-11 left-0 right-0 ">
              <FilledButton
                className="show-more-btn"
                content={showFullText ? "Visa mindre" : "Visa mer"}
                icon={showFullText ? faChevronUp : faChevronRight}
                size="small"
                onClick={() => setFullText(!showFullText)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function AdminNoticeActionBar(props: NoticeProps): ReactElement {
  const history = useHistory();
  const [confirmRemoveNotice, setConfirmRemoveNotice] = useState<boolean>(
    false
  );
  return (
    <div className="action-bar w-full bg-ocean-blue text-white flex justify-end">
      <ActionBarButton
        content="Redigera"
        onClick={() =>
          history.push(`/notiser/redigera/${props.notice.messageID}`)
        }
      />
      <ActionBarButton
        content="Radera"
        delete={1}
        onClick={() => setConfirmRemoveNotice(true)}
      />

      {confirmRemoveNotice && (
        <div className="absolute inset-0 bg-ocean-blue-dark bg-opacity-50 flex items-center justify-center">
          <div className="bg-white w-60 p-5 z-50 border rounded-sm border-blue-gray">
            <div className="font-semibold text-center mb-3">
              Är du säker på att du vill radera notisen?
            </div>
            <div className="flex justify-center space-x-2">
              <FilledButton
                type="button"
                width="w-20"
                size="small"
                content="Ja"
                onClick={() => props.onRemoveNotice()}
              />
              <Button
                type="button"
                size="small"
                width="w-20"
                content="Nej"
                onClick={() => setConfirmRemoveNotice(false)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

interface ActionBarButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  content: ReactNode;
  delete?: number;
}
function ActionBarButton(props: ActionBarButtonProps): ReactElement {
  return (
    <button
      {...props}
      className={`
      h-full bg-ocean-blue bg-opacity-10 transition  cursor-pointer text-center p-2
      ${
        !Number.isNaN(props.delete)
          ? "hover:bg-red hover:bg-opacity-70"
          : "hover:bg-ocean-blue-dark"
      }
      `}
    >
      {props.content}
    </button>
  );
}
