import { ReactElement, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router";
import AuthorizedPage from "../components/layout/AuthorizedPage";
import AccountPage from "./AccountPage";
import AddClient from "./AddClient";
import AddOrganization from "./AddOrganization";
import AddUser from "./AddUser";
import Clients from "./Clients";
import CourseModule from "./CourseModule";
import EditClient from "./EditClient";
import EditOrganization from "./EditOrganization";
import EditUser from "./EditUser";
import Organizations from "./Organizations";
import Platform from "./Platform";
import ModuleLog from "./ModuleLogs";
import Users from "./Users/Users";
import ViewClient from "./ViewClient";
import ViewOrganization from "./ViewOrganization";
import ViewUser from "./Users/ViewUser";
import ImportUsers from "./ImportUsers/ImportUsers";
import UserModuleOverviewPage from "./UserModules/UserModuleOverviewPage";
import CompletedModulesPage from "./UserModules/CompletedModulesPage";
import AlertBar from "../components/layout/AlertBar";
import { SettingsProvider } from "../providers/SettingsProvider";
import Notices from "./Communication/Notices";
import AddNotice from "./Communication/AddNotice";
import EditNotice from "./Communication/EditNotice";
import ProtectedRoute from "../routes/ProtectedRoute";
import StartPageRoute from "../routes/StartPageRoute";
import ExtraResources from "./ExtraResources/ExtraResources";
import { useAuth, Capabilities } from "../hooks/ProvideAuth";
import ViewExtraResource from "./ExtraResources/ViewExtraResource";

export default function Home(): ReactElement {
  const auth = useAuth();
  const noticesCaps = [Capabilities.manage_notices, Capabilities.view_courses];
  const addResourceRoutes = useMemo(
    () =>
      auth?.userCapabilities != null &&
      auth.userCapabilities.some((uc) => uc === Capabilities.view_resources),
    [auth?.userCapabilities]
  );

  return (
    <AuthorizedPage>
      <SettingsProvider>
        <div className="flex flex-1 flex-col w-full">
          <AlertBar />
          <Switch>
            <Route path={`/moduler`} component={Platform} />
            <ProtectedRoute
              path="/uppdragsgivare"
              capabilities={[Capabilities.view_client_routes]}
              element={<ClientRoutes />}
            />
            <ProtectedRoute
              path="/organisationer"
              capabilities={[Capabilities.view_org_routes]}
              element={<OrganizationsRoute />}
            />
            <ProtectedRoute
              capabilities={[Capabilities.view_user_routes]}
              path="/anvandare"
              element={<UsersRoute />}
            />

            <Route path="/mitt-konto" component={AccountPage} />

            <Redirect
              from="/genomforda-moduler/:userId/:moduleId"
              to="/modul/:userId/:moduleId"
            />
            <ProtectedRoute
              capabilities={[
                Capabilities.view_org_routes,
                Capabilities.view_courses,
              ]}
              path="/genomforda-moduler"
              element={<CompletedModulesPage />}
            />

            <ProtectedRoute
              capabilities={[
                Capabilities.view_org_routes,
                Capabilities.view_courses,
              ]}
              path="/modul/:userId/:moduleId"
              element={<UserModuleOverviewPage />}
            />
            <Route path="/modul/:id" component={CourseModule} />
            <Route path="/laslogg" component={ModuleLog} />

            {/* NOTICES ROUTES */}
            <Route path="/notiser" exact component={Notices} />
            <ProtectedRoute
              path="/notiser/redigera/:id"
              capabilities={noticesCaps}
              element={<EditNotice />}
            />
            <ProtectedRoute
              path="/notiser/ny"
              capabilities={noticesCaps}
              element={<AddNotice />}
            />
            {/* RESOURCE ROUTES */}
            {addResourceRoutes && (
              <ProtectedRoute
                path="/resurser"
                capabilities={[Capabilities.view_resources]}
                element={
                  <Switch>
                    <Route
                      path={`/resurser/:slug/:id`}
                      exact
                      component={ViewExtraResource}
                    />
                    <Route
                      path={`/resurser/:slug`}
                      component={ExtraResources}
                    />
                  </Switch>
                }
              />
            )}
            <Redirect
              from={`/resurser`}
              to={{
                pathname: "/",
                state: {
                  alert: {
                    message: "Du har inte tillgång till detta.",
                    color: "error",
                  },
                },
              }}
            />

            <StartPageRoute />
          </Switch>
        </div>
      </SettingsProvider>
    </AuthorizedPage>
  );
}

/**
 *
 * @returns Protected client routes
 */
function ClientRoutes(): ReactElement {
  return (
    <>
      <Switch>
        <Route path="/uppdragsgivare/ny" component={AddClient} />
        <Route path="/uppdragsgivare/redigera/:id" component={EditClient} />
        <Route path="/uppdragsgivare/:id" component={ViewClient} />
        <Route path="/uppdragsgivare" component={Clients} />
      </Switch>
    </>
  );
}
/**
 *
 * @returns Protected user routes
 */
function UsersRoute(): ReactElement {
  return (
    <>
      <Switch>
        <Route path="/anvandare/redigera/:id" component={EditUser} />
        <Route path="/anvandare/ny" component={AddUser} />
        <Route path="/anvandare/importera" component={ImportUsers} />
        <Route path="/anvandare/:id" component={ViewUser} />
        <Route path="/anvandare" component={Users} />
      </Switch>
    </>
  );
}

function OrganizationsRoute(): ReactElement {
  return (
    <>
      <Switch>
        <Route path="/organisationer/ny" component={AddOrganization} />
        <Route
          path="/organisationer/redigera/:id"
          component={EditOrganization}
        />
        <Route path="/organisationer/:id" component={ViewOrganization} />
        <Route path="/organisationer" component={Organizations} />
      </Switch>
    </>
  );
}
