import { ReactElement, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useAlert } from "../../hooks/AlertProvider";

export default function AlertBar(): ReactElement | null {
  const alert = useAlert();
  useEffect(() => {
    const timer = setTimeout(
      () => alert?.clearAlert(),
      alert?.timeoutLength != null ? alert.timeoutLength : 8000
    );
    return () => clearTimeout(timer);
  }, [alert]);

  const bgColor = useMemo((): string => {
    switch (alert?.color) {
      case "error":
      case "warning":
        return "bg-red";

      case "success":
        return "bg-fresh-mint";

      default:
        return "bg-ocean-blue bg-opacity-80";
    }
  }, [alert?.color]);

  return alert?.message !== undefined ? (
    <div className={`AlertBar w-full px-16 py-3 text-white ${bgColor}`}>
      <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />

      <span className="text-white font-bold">{alert.message}</span>

      {alert.link !== undefined && <button>Understood</button>}

      <button onClick={alert.clearAlert}>
        <FontAwesomeIcon icon={faTimesCircle} className="x-button mr-2" />
      </button>
    </div>
  ) : null;
}
