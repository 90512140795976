import { Fragment, ReactElement, useEffect, useState } from "react";
import { Button } from "../components/Buttons";
import CardHeader from "../components/CardHeader";
import { FormInput } from "../components/formLayout/FormInput";
import FormInputWrapper from "../components/formLayout/FormInputWrapper";
import * as yup from "yup";
import FormLabel from "../components/formLayout/FormLabel";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { postData } from "../api/endpoints";
import { PostResponse } from "../api/types";
import { useHistory, useParams } from "react-router";
import { getValidationGenericErrorMessages, setFormErrors } from "../util";
import FormError from "../components/formLayout/FormError";
import Loader from "../components/layout/Loader";

export type CompleteAccountUserFormData = {
  usr_firstname: string;
  usr_lastname: string;
  usr_email: string;
  usr_password_plain?: string;
  usr_confirmed_password?: string;
  key: string;
};

const schema = yup.object().shape({
  // usr_firstname: yup.string().required(),
  // usr_lastname: yup.string().required(),
  // usr_email: yup
  //   .string()
  //   .email("E-post kräver en gilltig adress")
  //   .required("E-post är ett obligatoriskt fält"),
  usr_password_plain: yup
    .string()
    .min(8, "Lösenordet måste innehålla minst 8 tecken")
    .matches(/\d/, "Minst ett tecken måste vara en siffra")
    .required("Lösenordet är obligatoriskt"),
  usr_confirmed_password: yup
    .string()
    .required()
    .oneOf(
      [yup.ref("usr_password_plain"), null],
      "Lösenorden stämmer inte överens"
    ),
  key: yup.string().required(),
});

export default function CompleteAccount(): ReactElement {
  const params = useParams<{ key: string }>();

  const [validKey, setValidKey] = useState<number | boolean | null>();
  const history = useHistory();

  useEffect(() => {
    const validatePasswordResetKey = (): void => {
      postData<number | boolean>(
        `/auth/validate-password-reset-key `,
        JSON.stringify({ key: params.key })
      )
        .then((validKey) => {
          setValidKey(validKey);
        })
        .catch((error) => console.log(error));
    };

    void validatePasswordResetKey();
  }, [params.key]);

  return (
    <Fragment>
      <CardHeader title="Färdigställ ditt konto" />

      <div className="px-2 md:px-20">
        {validKey == null ? (
          <Loader />
        ) : validKey !== false ? (
          <CompleteAccountForm resetKey={params.key} />
        ) : (
          <div className="flex flex-col justify-between items-start">
            Den här länken är inte längre giltigt. Skapa ett eget lösenord via
            "Glömt lösenord"-sidan eller kontakta en administratör för att få
            hjälp med ett nytt lösenord.
            {
              <Button
                className="mt-10"
                content="Glömt lösenord"
                onClick={() => history.push("/glomt-losenord")}
              />
            }
          </div>
        )}
      </div>
    </Fragment>
  );
}

function CompleteAccountForm(props: { resetKey: string }): ReactElement {
  const [serverErrors, setServerErrors] = useState<string[]>([]);

  const history = useHistory();
  const mutation = useMutation((data: string) =>
    postData<PostResponse>(`/auth/reset-password-by-key`, data)
  );
  const methods = useForm<CompleteAccountUserFormData>({
    defaultValues: {
      key: props.resetKey,
    },
    resolver: yupResolver(schema),
  });

  const onButtonSubmit = (data: CompleteAccountUserFormData): void => {
    mutation.mutate(
      JSON.stringify({ key: data.key, password: data.usr_password_plain }),
      {
        onSuccess: (response) => {
          switch (response.status) {
            case "ok":
              history.replace("/logga-in");
              break;
            case "error":
            default:
              setServerErrors(
                getValidationGenericErrorMessages(response.errorsItemized)
              );
              setFormErrors(response.errorsItemized, methods);
              break;
          }
        },

        onError: (error) => {
          console.log(error);
          // setFormErrors(error.details.errorsItemized, methods);
        },
      }
    );
  };

  return (
    <form className="form">
      {/* <FormInputWrapper>
            <FormLabel htmlFor="usr_firstname" label="Förnamn" />
            <FormInput {...methods.register("usr_firstname")} type="text" />
            {methods.formState.errors.usr_firstname?.message}
          </FormInputWrapper>
          <FormInputWrapper>
            <FormLabel htmlFor="usr_lastname" label="Efternamn" />
            <FormInput {...methods.register("usr_lastname")} type="text" />
            {methods.formState.errors.usr_lastname?.message}
          </FormInputWrapper>

          <FormInputWrapper>
            <FormLabel htmlFor="usr_email" label="E-post" />
            <FormInput {...methods.register("usr_email")} type="text" />
            {methods.formState.errors.usr_email?.message}
          </FormInputWrapper> */}

      <FormInputWrapper>
        <FormLabel htmlFor="usr_password_plain" label="Nytt lösenord" />
        <FormInput
          {...methods.register("usr_password_plain")}
          type="password"
        />
        <FormError
          message={methods.formState.errors.usr_password_plain?.message}
        />
      </FormInputWrapper>
      <FormInputWrapper>
        <FormLabel
          htmlFor="usr_confirmed_password"
          label="Bekräfta nytt lösenord"
        />
        <FormInput
          {...methods.register("usr_confirmed_password")}
          type="password"
        />
        <FormError
          message={methods.formState.errors.usr_confirmed_password?.message}
        />
      </FormInputWrapper>
      <Button
        type="submit"
        content="Färdigställ konto"
        onClick={methods.handleSubmit(onButtonSubmit)}
      />
      <div>
        {serverErrors.map((e) => (
          <FormError key={e} message={e} />
        ))}
      </div>
    </form>
  );
}
