import { Query, QueryKey } from "react-query";

export function invalidateUsers(
  query: Query<unknown, unknown, unknown, QueryKey>
): boolean {
  return (
    (query.queryKey.length === 1 && query.queryKey[0] === "users") ||
    (query.queryKey[0] === "users" &&
      (query.queryKey.includes("filter") || query.queryKey.includes("page")))
  );
}
