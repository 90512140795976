import { ReactElement, useMemo } from "react";
import { Module, ModuleProgress } from "../../api/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import ViewHeader from "../../components/layout/ViewHeader";
import Loader from "../../components/layout/Loader";
import { Link } from "react-router-dom";
import { useGetModuleProgresses, useGetModules } from "../../api/endpoints";
import UseQueryError from "../../components/UseQueryError";

type UserModuleProps = {
  userID: number;
};

export default function UserModules({ userID }: UserModuleProps): ReactElement {
  const { status: mStatus, data: modules } = useGetModules();
  const { status: mpStatus, data: moduleProgresses } = useGetModuleProgresses({
    userID,
  });

  const loadingStatuses = ["idle", "loading"];
  if (
    mStatus === "success" &&
    mpStatus === "success" &&
    modules &&
    moduleProgresses
  ) {
    return (
      <>
        <ViewHeader
          title={`Moduler | ${modules._meta.totalCount}st`}
          className="mb-5"
        ></ViewHeader>
        <table className="table w-full fsu-table">
          <thead>
            <tr className="">
              <th>Modul</th>
              <th>Läsning och läslogg</th>
              <th>Film</th>
              <th>Reflektion och analys</th>
              <th>Åtgärder</th>
            </tr>
          </thead>
          <tbody>
            {modules.items.map((module) => (
              <tr key={module.moduleID}>
                <td>
                  {module.m_number !== 0 ? `${module.m_number}. ` : ""}
                  {module.m_title}
                </td>
                <UserModuleProgresses
                  module={module}
                  moduleProgresses={moduleProgresses.items}
                />

                <td>
                  <Link
                    to={{
                      pathname: `/modul/${userID}/${module.moduleID}`,
                      state: { previousPath: `/anvandare/${userID}` },
                    }}
                  >
                    Visa
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  } else if (
    loadingStatuses.includes(mStatus) ||
    loadingStatuses.includes(mpStatus)
  ) {
    return <Loader />;
  } else {
    return <UseQueryError />;
  }
}

function UserModuleProgresses({
  module,
  moduleProgresses,
}: {
  module: Module;
  moduleProgresses: ModuleProgress[];
}): ReactElement {
  const progresses = useMemo(() => {
    const userModuleProgresses = moduleProgresses.filter(
      (progress) => progress.mprog_moduleID === module.moduleID
    );
    return [
      userModuleProgresses.findIndex((progress) => {
        return (
          progress.mprog_step === "main_module_logs" &&
          progress.mprog_completed != null
        );
      }),
      userModuleProgresses.findIndex((progress) => {
        return (
          progress.mprog_step === "m_lecture_video" &&
          progress.mprog_completed != null
        );
      }),
      userModuleProgresses.findIndex((progress) => {
        return (
          progress.mprog_step === "m_analysis_text" &&
          progress.mprog_completed != null
        );
      }),
    ];
  }, [module, moduleProgresses]);

  return (
    <>
      {progresses.map((p, index) => (
        <td key={`progress-${index}`}>
          {p > -1 ? (
            <FontAwesomeIcon icon={faCheck} transform="shrink-2" />
          ) : (
            "-"
          )}
        </td>
      ))}
    </>
  );
}
