import { ReactElement } from "react";
import { Message, Notice } from "../../api/types";
import NewIndicator from "./NewIndicator";

export default function CommunicationUserImg(props: {
  message: Message | Notice;
}): ReactElement {
  const userImg =
    "https://media.forskoleutveckling.nu/persistence/media/mangfaldens-forskole/anniqa_sandell_ring_portratt_910x512.jpg";

  return (
    <div
      className="h-20 w-20 rounded-full bg-cover relative"
      style={{ backgroundImage: `url(${userImg})` }}
    >
      {props.message.msg_read != null && <NewIndicator />}
    </div>
  );
}
