interface TokenManager {
  ereaseToken(): boolean;
  getToken(): string | null;
  setToken(token: string | null): boolean;
}
const inMemoryToken = (): TokenManager => {
  let inMemoryToken: string | null = null;

  const getToken = (): string | null => inMemoryToken;

  const setToken = (token: string): boolean => {
    inMemoryToken = token;
    return true;
  };

  const ereaseToken = (): boolean => {
    inMemoryToken = null;
    return true;
  };

  return {
    ereaseToken,
    getToken,
    setToken,
  };
};

export default inMemoryToken();
