import { ReactElement, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Client, Organization, User } from "../api/types";
import Header from "../components/layout/Header";
import ViewHeader from "../components/layout/ViewHeader";
import { isNotEmpty } from "../util";
import { Button, IconButtonLink } from "../components/Buttons";
import Search from "../components/Search";
import { Link } from "react-router-dom";
import { LicensesList } from "../components/clients/Licenses";
import Loader from "../components/layout/Loader";
import { SimpleLayout } from "../components/layout/SimpleLayout";
import { useGetViewClient } from "../api/endpoints";
import useClientPagination from "../hooks/useClientPagination";
import PaginationUI from "../components/layout/PaginationUI";
import UserOrganization from "../components/UserOrganizations";

export default function ViewClient(): ReactElement {
  const params = useParams<{ id: string }>();
  const query = useGetViewClient(params.id);

  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      return (
        <SimpleLayout>
          <h4 className="mb-2">Uppdragsgivare:</h4>
          <Header title={query.data.cl_name} />
          <div className="mt-16">
            <LicensesList clientId={params.id} />
            {/* <div>STATISTIK</div> */}

            <div className="mt-16 mb-10 flex">
              <div className="pr-10">
                <ClientOrgAdminsList
                  users={query.data.users as User[]}
                  client={query.data}
                />

                <ClientDetails client={query.data} />
              </div>
              <div className="flex-1">
                <ClientUsersTable users={query.data.users as User[]} />

                <OrganizationTable
                  className="mt-16"
                  clientId={query.data.clientID}
                  orgs={query.data.organizations as Organization[]}
                />
              </div>
            </div>
          </div>
        </SimpleLayout>
      );
  }
}

export function ClientDetails({
  client,
  className,
}: {
  client: Client;
  className?: string;
}): ReactElement {
  return (
    <div className={`${isNotEmpty(className) ? className : "mt-24"}`}>
      <ViewHeader title="Uppdragsgivare"></ViewHeader>
      <div className="mt-8">
        <h5>{client.cl_name.toUpperCase()}</h5>
        <div>Organisationsnummer: {client.cl_org_number}</div>
        <h6>Kontaktperson</h6>
        <div>{client.cl_contact_name}</div>
        <div>{client.cl_contact_email}</div>
        <div>{client.cl_contact_phone}</div>
      </div>
    </div>
  );
}

export function ClientOrgAdminsList({
  users,
  client,
}: {
  users: User[] | undefined;
  client: Client;
}): ReactElement {
  const history = useHistory();

  const orgAdmins = useMemo(() => {
    if (users == null) return [];
    return users.filter((usr) =>
      usr.userRoles?.some((role) => role.role_name === "clientOrgAdmin")
    );
  }, [users]);

  return (
    <div>
      <ViewHeader title="Uppdragsgivaradministratörer"></ViewHeader>
      {orgAdmins.map((usr) => (
        <div key={usr.userID} className="mt-8">
          <h5>{`${usr.usr_firstname} ${usr.usr_lastname}`.toUpperCase()}</h5>
          <div>{usr.usr_email}</div>

          <div className="mt-4">
            <Button
              content="Redigera"
              size="xsmall"
              onClick={() => history.push(`/anvandare/redigera/${usr.userID}`)}
            />
          </div>
        </div>
      ))}
      <IconButtonLink
        className="mt-8"
        content="Lägg till uppdragsgivaradministratör"
        onClick={() =>
          history.push("/anvandare/ny", {
            role: "clientOrgAdmin",
            clientId: client.clientID,
          })
        }
      />
    </div>
  );
}

export function ClientUsersTable(props: {
  users: User[] | undefined;
}): ReactElement {
  const history = useHistory();
  const [searchString, setSearchString] = useState("");
  const items = useMemo((): User[] => {
    if (props.users == null) return [];
    const regUsers = props.users.filter((usr) =>
      usr.userRoles?.some((role) => role.role_name === "regUser")
    );

    const filter = searchString.toLowerCase();
    return isNotEmpty(filter)
      ? regUsers.filter(
          (usr) =>
            usr.usr_firstname.toLowerCase().includes(filter) ||
            usr.usr_lastname.toLowerCase().includes(filter) ||
            usr.usr_email.toLowerCase().includes(filter) ||
            (usr.userRoles != null &&
              usr.userRoles.filter((role) =>
                role.organization?.org_name.toLowerCase().includes(filter)
              ).length > 0)
        )
      : regUsers;
  }, [props.users, searchString]);

  const paginationProps = useClientPagination({
    totalCount: items.length,
  });
  const currentPageItems = useMemo(() => {
    return items.slice(paginationProps.fromItem - 1, paginationProps.toItem);
  }, [items, paginationProps]);

  return (
    <div>
      <ViewHeader title={`Användare | ${items.length}st`}>
        {/* <IconButtonLink
          content="Importera"
          className="mr-6"
          onClick={() => history.push("/användare/importera")} // prepopulate form data with organization and regUser?
        /> */}
        <IconButtonLink
          content="Lägg till användare"
          onClick={() =>
            history.push(`/anvandare/ny`, {
              role: "regUser",
            })
          }
        />
      </ViewHeader>
      <div className="mt-8">
        <Search onSearch={setSearchString}></Search>

        <div className="filter med:flex w-full md:w-1/3">
          <div className="dropdown-licens"></div>
          <div className="dropdown-status"></div>
        </div>
      </div>
      <PaginationUI {...paginationProps}>
        <table className="table w-full fsu-table mt-6">
          <thead>
            <tr className="">
              <th>Namn</th>
              <th>E-post</th>
              <th>Status</th>
              <th>Organisation</th>
              <th>Åtgärder</th>
            </tr>
          </thead>
          <tbody>
            {currentPageItems.map((u: User) => (
              <tr key={u.usr_email}>
                <td>
                  <Link to={`/anvandare/${u.userID}`}>
                    {isNotEmpty(`${u.usr_firstname} ${u.usr_lastname}`.trim())
                      ? `${u.usr_firstname} ${u.usr_lastname}`
                      : u.usr_email}
                  </Link>
                </td>
                <td>{u.usr_email}</td>
                <td>{u.usr_status === "active" ? "Aktiv" : "Väntande"}</td>
                <td>
                  <UserOrganization usrRoles={u.userRoles} />
                </td>
                <td>
                  <Link to={`/anvandare/redigera/${u.userID}`}>Redigera</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </PaginationUI>
    </div>
  );
}

export function OrganizationTable(props: {
  clientId: number;
  className?: string;
  orgs: Organization[] | undefined;
}): ReactElement {
  const history = useHistory();
  const [searchString, setSearchString] = useState("");

  const items = useMemo(() => {
    if (props.orgs == null) return [];
    const filter = searchString.toLowerCase();
    return isNotEmpty(filter)
      ? props.orgs.filter(
          (org) =>
            org.org_name.toLowerCase().includes(filter) ||
            org.organizationID.toString().includes(filter)
        )
      : props.orgs;
  }, [props.orgs, searchString]);

  const paginationProps = useClientPagination({
    totalCount: items.length,
  });
  const currentPageItems = useMemo(() => {
    return items.slice(paginationProps.fromItem - 1, paginationProps.toItem);
  }, [items, paginationProps]);

  return (
    <div className={isNotEmpty(props.className) ? props.className : ""}>
      <ViewHeader title={`Organisationer | ${items.length}st`}>
        <IconButtonLink
          content="Lägg till organisation"
          onClick={() =>
            history.push(`/organisationer/ny`, { clientId: props.clientId })
          }
        />
      </ViewHeader>
      <div className="mt-8">
        <Search onSearch={setSearchString}></Search>
      </div>
      <PaginationUI {...paginationProps}>
        <table className="table w-full fsu-table mt-6">
          <thead>
            <tr>
              <th>Organisation</th>
              <th className="w-32">Åtgärder</th>
            </tr>
          </thead>
          <tbody>
            {currentPageItems.map((o: Organization) => {
              return (
                <tr key={o.org_name}>
                  <td>
                    <Link to={`/organisationer/${o.organizationID}`}>
                      {o.org_name}{" "}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/organisationer/redigera/${o.organizationID}`}>
                      Redigera
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </PaginationUI>
    </div>
  );
}
