import { useQuery } from "react-query";
import { GetListResponse, Module, User } from "./../../api/types";
import { UseQueryResult } from "react-query";
import { fetchApiGet } from "../../api/api";
/**
 *
 * @param userId - string UserId to get view data from
 */
export function useGetViewUser(userId: string): UseQueryResult<User> {
  return useQuery<User, Error>(
    [
      "users",
      userId,
      "userRoles",
      "userRoles.organization",
      "userRoles.client",
    ],
    () =>
      fetchApiGet<User>(
        `users/${userId}?expand=userRoles,userRoles.organization,userRoles.client`
      )
  );
}

export function useGetViewUserModules(): UseQueryResult<
  GetListResponse<Module>,
  Error
> {
  return useQuery<GetListResponse<Module>, Error>(
    ["modules", "moduleProgresses"],
    () =>
      fetchApiGet<GetListResponse<Module>>(
        "modules?expand=moduleProgresses&sort=m_number"
      )
  );
}
