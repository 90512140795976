import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useState } from "react";
import { Button, FilledButton } from "./Buttons";
import Loader from "./layout/Loader";

export default function RemoveConfirmation(props: {
  text: string;
  small?: boolean;
  onRemove: () => void;
  className?: string;
}): ReactElement {
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(
    false
  );
  const [disabled, setDisabled] = useState<boolean>(false);
  return (
    <>
      <button
        className={`remove-confirmation-show-btn ${
          props.className != null ? props.className : ""
        }`}
        onClick={() => setShowRemoveConfirmation(true)}
      >
        <FontAwesomeIcon icon={faTimesCircle} />
      </button>
      {showRemoveConfirmation && (
        <div className="absolute inset-0 bg-ocean-blue-dark bg-opacity-50 flex items-center justify-center">
          <div
            className={`bg-white ${
              props.small ? "p-2 text-xs" : "p-4"
            } z-50 border rounded-sm border-blue-gray`}
          >
            <div
              className={`font-semibold text-center ${
                props.small ? "" : "mb-3"
              }`}
            >
              {props.text}
            </div>
            <div
              className={`flex justify-center ${
                props.small ? "space-x-1" : "space-x-2"
              }`}
            >
              <FilledButton
                disabled={disabled}
                type="button"
                width="w-20"
                size={props.small ? "xsmall" : "small"}
                content={disabled ? <Loader inline invert /> : "Ja"}
                onClick={() => {
                  props.onRemove();
                  setDisabled(true);
                }}
              />
              <Button
                type="button"
                size={props.small ? "xsmall" : "small"}
                width="w-20"
                content="Nej"
                onClick={() => setShowRemoveConfirmation(false)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
