import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { SelectOption } from "../../api/types";
import { isNotEmpty } from "../../util";

function FormSelect(props: {
  options: SelectOption[];
  registerReturn: UseFormRegisterReturn;
  className?: string;
}): ReactElement {
  return (
    <div
      className={`relative border border-blue-gray ${
        isNotEmpty(props.className) ? props.className : ""
      }`}
    >
      <select
        {...props.registerReturn}
        className={`text-blue-gray pl-4 w-full h-12 hover:border-ocean-blue hover:text-ocean-blue font-semibold `}
      >
        {props.options.map((o: SelectOption) => (
          <option key={`${o.value} ${o.label}`} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
      <FontAwesomeIcon
        className="absolute top-4 right-2.5 pointer-events-none"
        icon={faChevronDown}
      />
    </div>
  );
}

export default FormSelect;
