import { ReactElement, ReactNode } from "react";
import { isNotEmpty } from "../../../util";

export default function ModuleInputHeader({
  title,
  children,
  className,
}: {
  children?: ReactNode;
  title: string;
  className?: string;
}): ReactElement {
  return (
    <div className="bg-ocean-blue grid grid-col border border-b-2 divide-x ">
      <div className={`p-4 ${isNotEmpty(className) ? className : ""}`}>
        <div className="font-semibold text-lg text-white">{title}</div>
        {children}
      </div>
    </div>
  );
}
