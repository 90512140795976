export default function calculateVideoTime(timeInSeconds: number): string {
  const sec = timeInSeconds; // convert value to number if it's string
  const hours = Math.floor(sec / 3600); // get hours
  const minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  const seconds = sec - hours * 3600 - minutes * 60; //  get seconds

  let hourString = "00";
  let minuteString = "00";
  let secondString = "00";
  if (hours < 10) {
    hourString = `0${hours}`;
  } else {
    hourString = `${hours}`;
  }
  if (minutes < 10) {
    minuteString = `0${minutes}`;
  } else {
    minuteString = `${minutes}`;
  }
  if (seconds < 10) {
    secondString = `0${seconds}`;
  } else {
    secondString = `${seconds}`;
  }
  return `${hours > 0 ? hourString + ":" : ""}${minuteString}:${secondString}`; // Return is HH : MM : SS
}
