import { ReactElement } from "react";

import {
  useGetCourses,
  useGetMessages,
  useGetModuleLogs,
} from "../api/endpoints";
import { ModuleLogForm } from "../components/modules/ModuleLogForm";
import ModuleLogPropositionForm from "../components/modules/ModuleLogPropositionForm";
import Loader from "../components/layout/Loader";
import { useAuth } from "../hooks/ProvideAuth";
import LinkToFile from "../components/LinkToFile";
import ModuleLayout from "../components/layout/ModuleLayout";
import TopImage from "../components/layout/TopImage";
import { Module } from "../api/types";

export default function ModuleLogs(): ReactElement {
  const moduleTopImage = "/module-log.jpg";
  return (
    <ModuleLayout>
      <TopImage imagePath={moduleTopImage} />
      <div className="module-content-wrapper">
        <div className="mb-12">
          <h1 className="mb-10">Läslogg</h1>
          <div className="mb-10">
            <i>Ett stöd för fördjupning</i>
            <p>
              Läsningen av boken Mångfaldens förskola är huvudingrediensen i den
              här kompetensutvecklingsinsatsen. Ett stöd under läsningen och
              bearbetningen av texten kan vara att skriva läslogg. Det hjälper
              en att stanna upp och närläsa delar av kapitlets innehåll, att
              komma ner på djupet i texten och att sortera ut det mest
              väsentliga för just mig/oss. När du använder läsloggen reflekterar
              du över och analyserar textinnehållet på ett sätt som också bidrar
              till att det blir lättare att tänka kring konsekvenser för
              praktiken. Vad som är klokt att göra i den pedagogiska praktiken
              sett till det du läst och tänkt om. Låt frågeställningarna i era
              läsloggar leda er i vad som är viktigt för just er, för att ni ska
              nå de mål ni formulerat för kompetensutvecklingsinsatsen.
            </p>
            <div className="mt-4">
              <i>Läsloggen kan användas av alla</i>
              <p>
                Är du verksamhetschef, rektor, specialpedagog, förskollärare,
                barnskötare, pedagogista, ateljérista eller student? Läsloggen
                kan användas av er alla oavsett vilket uppdrag du har. Var bara
                lite kreativ i tanken sett till ditt ansvar och din roll när du
                läser ett kapitel och bearbetar det genom läsloggen.
              </p>
            </div>
            <div className="mt-4">
              <i>
                För ytterligare stöttning i att använda läsloggen, se dokumentet
                nedan
              </i>
              <LinkToFile
                path={`https://media.forskoleutveckling.nu/persistence/media/mangfaldens-forskole/St%C3%B6ttande-text-till-l%C3%A4sloggen.pdf`}
                displayName="Stöttande text till läsloggen"
              />
            </div>
          </div>
          <Courses />
        </div>
      </div>
    </ModuleLayout>
  );
}

function Courses(): ReactElement {
  const query = useGetCourses();
  const auth = useAuth();

  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      return (
        <div>
          {query.data.items.map((course) => (
            <div key={course.courseID}>
              {query.data.items.length > 1 && (
                <h3 className="text-dull-mint">{course.crs_title}</h3>
              )}
              <div>
                {(course.modules == null ? [] : course.modules)
                  .filter((m) => m.m_number !== 0 && auth?.user != null)
                  .map(
                    (m) =>
                      auth?.user != null && (
                        <CourseModuleLogs
                          key={`module-${m.moduleID}`}
                          userID={auth.user.userID}
                          module={m}
                        />
                      )
                  )}
              </div>
            </div>
          ))}
        </div>
      );
  }
}

function CourseModuleLogs({
  userID,
  module: m,
}: {
  userID: number;
  module: Module;
}): ReactElement {
  const messagesQuery = useGetMessages({
    userID,
    moduleID: m.moduleID,
  });
  const moduleLogsQuery = useGetModuleLogs({
    userID,
    moduleID: m.moduleID,
  });

  const loadingStatuses = ["idle", "loading"];
  if (
    messagesQuery.status === "success" &&
    moduleLogsQuery.status === "success"
  ) {
    m.messages = messagesQuery.data.items;
    m.moduleLogs = moduleLogsQuery.data.items;
    return (
      <div className="mb-12">
        <h3>
          Modul {m.m_number} - {m.m_title}{" "}
        </h3>
        <ModuleLogForm module={m} />
        <ModuleLogPropositionForm module={m} />
      </div>
    );
  } else if (
    loadingStatuses.includes(messagesQuery.status) ||
    loadingStatuses.includes(moduleLogsQuery.status)
  ) {
    return <Loader />;
  } else {
    return (
      <div>
        Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
      </div>
    );
  }
}
