import { ReactElement, useCallback, useMemo, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Header from "../../components/layout/Header";
import Loader from "../../components/layout/Loader";
import { SimpleLayout } from "../../components/layout/SimpleLayout";
import { isNotEmpty } from "../../util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import TableBody from "../../components/Table/TableBody";
import useServerPagination from "../../hooks/useServerPagination";
import PaginationUI from "../../components/layout/PaginationUI";
import { UseQueryResult } from "react-query";
import { CompletedUserModule, GetListResponse } from "../../api/types";
import { useGetCompletedModules } from "./endpoints";

export default function CompletedModulesPage(): ReactElement {
  const history = useHistory();
  const location = useLocation();
  const totalCompletedModules = useRef(0);
  const page = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get("sida");
  }, [location.search]);

  const query = useGetCompletedModules({ page, perPage: 25 });

  const handlePaginationChange = useCallback(
    (pageNumber: number | undefined, previousPage: number | undefined) => {
      if (pageNumber !== previousPage) {
        const pageString = pageNumber != null ? pageNumber.toString() : null;
        if (pageString != null) {
          history.push(`genomforda-moduler?sida=${pageString}`);
        } else {
          history.push(`genomforda-moduler`);
        }
      }
    },
    [history]
  );
  const paginationProps = useServerPagination(
    query.data?._meta,
    page,
    handlePaginationChange
  );
  if (query.status === "success") {
    totalCompletedModules.current = query.data._meta.totalCount;
  }

  return (
    <SimpleLayout>
      <Header
        title={`Genomförda moduler | ${totalCompletedModules.current}st`}
      ></Header>
      <PaginationUI {...paginationProps}>
        <div className="mt-8"></div>
        <CompletedModules query={query} />
      </PaginationUI>
    </SimpleLayout>
  );
}

function CompletedModules({
  query,
}: {
  query: UseQueryResult<GetListResponse<CompletedUserModule>, Error>;
}): ReactElement {
  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;

    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );

    case "success":
      return (
        <Table>
          <TableHeader>
            <th>Användare</th>
            <th>Modul</th>
            <th>Genomförd</th>
            <th>Åtgärd</th>
            <th></th>
          </TableHeader>
          <TableBody>
            {query.data.items.map((mp) => {
              let userName = mp.mprog_userID.toString();
              if (mp.user != null) {
                userName = isNotEmpty(
                  `${mp.user.usr_firstname} ${mp.user.usr_lastname}`.trim()
                )
                  ? `${mp.user.usr_firstname} ${mp.user.usr_lastname}`
                  : mp.user.usr_email;
              }

              return (
                <tr key={mp.module_progressID}>
                  <td>{userName}</td>
                  <td>
                    {mp.module != null
                      ? `${mp.module.m_number}. ${mp.module.m_title}`
                      : `${mp.mprog_moduleID}`}
                  </td>
                  <td>{mp.mprog_completed?.slice(0, 10)}</td>

                  <td>
                    <Link
                      to={`/genomforda-moduler/${mp.mprog_userID}/${mp.mprog_moduleID}`}
                    >
                      Återkoppla
                    </Link>
                  </td>
                  <td className="w-10">
                    {mp.feedbackMessage != null &&
                    isNotEmpty(mp.feedbackMessage.msg_text) ? (
                      <FontAwesomeIcon icon={faCheck} className="mr-0" />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="opacity-25 mr-0"
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </TableBody>
        </Table>
      );
  }
}
