import { ReactElement } from "react";
import { useGetClients, useGetUsers } from "../api/endpoints";
import Header from "../components/layout/Header";
import Loader from "../components/layout/Loader";
import { SimpleLayout } from "../components/layout/SimpleLayout";
import {
  ClientDetails,
  ClientUsersTable,
  OrganizationTable,
} from "./ViewClient";
import { Client, User } from "../api/types";
import { useGetOrganizations } from "./Organizations/useGetOrganizations";
import ResourceHint from "../components/ResourcesHint";

export default function OrgAdminDashboard(): ReactElement {
  const clientQuery = useGetClients();
  const orgQuery = useGetOrganizations();
  const usersQuery = useGetUsers();

  let client: Client;
  let users: User[];

  switch (clientQuery.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      client = clientQuery.data.items[0];
      users = usersQuery.isSuccess ? usersQuery.data.items : [];
      return (
        <SimpleLayout>
          <Header title={clientQuery.data.items[0].cl_name} />
          <div className="mt-2 mb-10">Status: Aktiv</div>
          <ResourceHint className="my-12" forAdmin={true} />
          <div>
            {/* <div>STATISTIK</div> */}

            <div className="flex">
              <ClientDetails className="mt-0 pr-10" client={client} />
              <div className="flex-1">
                <OrganizationTable
                  className="mb-10"
                  clientId={client.clientID}
                  orgs={orgQuery.isSuccess ? orgQuery.data.items : []}
                />
                <ClientUsersTable users={users} />
              </div>
            </div>
          </div>
        </SimpleLayout>
      );
  }
}
