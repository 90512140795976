import { ReactElement } from "react";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isNotEmpty } from "../../util";

interface LoaderProps {
  noLabel?: boolean;
  inline?: boolean;
  label?: string;
  className?: string;
  invert?: boolean;
}

export default function Loader({
  inline,
  label,
  className = "",
  invert,
}: LoaderProps): ReactElement {
  const inlineStyles = "";
  return (
    <div
      className={`${invert ? "invert" : ""} ${
        inline
          ? `${inlineStyles} ${className}`
          : `flex flex-col items-center justify-center pt-32 ${className}`
      }`}
    >
      <FontAwesomeIcon className="loader" size="lg" icon={faCog} spin />
      {isNotEmpty(label) && (
        <p className="text-xs mt-1">{isNotEmpty(label) || "Laddar..."}</p>
      )}
    </div>
  );
}
