import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from "react";
import ConfirmDialog from "../components/ConfirmDialog";
interface DialogConfig {
  title: string;
  message: string;
  actionCallback: (value: boolean | PromiseLike<boolean>) => void;
}

interface ConfirmDialogProps {
  openDialog(config: DialogConfig): void;
  resetDialog(): void;
  onConfirm(): void;
  onDismiss(): void;
}

export const ConfirmDialogContext = createContext<ConfirmDialogProps | null>(
  null
);

export function ProvideConfirmDialog(props: {
  children: ReactNode;
}): ReactElement {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogConfig, setDialogConfig] = useState<DialogConfig | null>(null);

  const openDialog = (config: DialogConfig): void => {
    setDialogOpen(true);
    setDialogConfig(config);
  };

  const resetDialog = (): void => {
    setDialogOpen(false);
    setDialogConfig(null);
  };

  const onConfirm = (): void => {
    resetDialog();
    dialogConfig?.actionCallback(true);
  };

  const onDismiss = (): void => {
    resetDialog();
    dialogConfig?.actionCallback(false);
  };

  return (
    <ConfirmDialogContext.Provider
      value={{ openDialog, resetDialog, onConfirm, onDismiss }}
    >
      <ConfirmDialog
        open={dialogOpen}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
      />
      {props.children}
    </ConfirmDialogContext.Provider>
  );
}

export function useConfirmDialog(): {
  getConfirmation(config: { title: string; message: string }): Promise<boolean>;
} {
  const con = useContext(ConfirmDialogContext);

  const getConfirmation = (options: {
    title: string;
    message: string;
  }): Promise<boolean> => {
    return new Promise<boolean>((res) => {
      con?.openDialog({ actionCallback: res, ...options });
    });
  };

  return { getConfirmation };
}
