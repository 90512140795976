import { ReactElement, useEffect, useState } from "react";
import { Path, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Redirect, useHistory, useParams } from "react-router";
import { postData } from "../api/endpoints";
import { PostResponse } from "../api/types";
import { Button } from "../components/Buttons";
import CardHeader from "../components/CardHeader";
import { FormInput } from "../components/formLayout/FormInput";
import FormInputWrapper from "../components/formLayout/FormInputWrapper";
import FormLabel from "../components/formLayout/FormLabel";
import { isEmpty } from "../util";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "../components/layout/Loader";
import { Link } from "react-router-dom";
import FormError from "../components/formLayout/FormError";

interface SetPasswordData {
  usr_password_plain: string;
  confirmed_password: string;
  key: string;
}

// Regexp validate password (usr_password_plain)
// How to make validation work smooth, where to put "password not matching"
const schema = yup.object({
  usr_password_plain: yup
    .string()
    .required("Nytt lösenord är obligatoriskt")
    .matches(
      /^(?=.{8,}$)(?=.*[A-Za-z])(?=.*\d).*$/,
      "Lösenordet måste innehålla minst 8 tecken därav en siffra"
    ),
  confirmed_password: yup
    .string()
    .required("Obligatoriskt")
    .oneOf(
      [yup.ref<string>("usr_password_plain"), null],
      "Lösenorden matchar inte"
    ),
});

export default function SetNewPassword(): ReactElement {
  const params = useParams<{ key: string }>();
  const [validKey, setValidKey] = useState<number | boolean | null>();

  useEffect(() => {
    const validatePasswordResetKey = (): void => {
      postData<number | boolean>(
        `/auth/validate-password-reset-key `,
        JSON.stringify({ key: params.key })
      )
        .then((validKey) => {
          setValidKey(validKey);
        })
        .catch((error) => console.log(error));
    };

    void validatePasswordResetKey();
  }, [params.key]);

  if (isEmpty(params.key)) {
    return <Redirect to="/"></Redirect>;
  }

  return (
    <>
      <CardHeader
        title="Nytt lösenord"
        subtitle={
          validKey == null || validKey === false
            ? undefined
            : "Skapa ett nytt lösenord för att kunna logga in."
        }
      />
      <div className="mt-8">
        {validKey == null ? (
          <Loader />
        ) : validKey !== false ? (
          <SetNewPasswordForm resetKey={params.key} />
        ) : (
          <div>
            Den här länken är inte längre giltigt.{" "}
            {
              <Link to="/glomt-losenord" className="font-bold">
                Klicka här för att återställa ditt lösenord igen.
              </Link>
            }
          </div>
        )}
      </div>
    </>
  );
}

function SetNewPasswordForm(props: { resetKey: string }): ReactElement {
  const [errorMessages, setErrorMessages] = useState<ReactElement[]>([]);
  const history = useHistory();

  const methods = useForm<SetPasswordData>({
    defaultValues: {
      key: props.resetKey,
    },
    resolver: yupResolver(schema),
    criteriaMode: "all",
  });
  const mutation = useMutation((data: URLSearchParams) =>
    postData<PostResponse>("auth/reset-password-by-key", data)
  );

  useEffect(() => {
    if (methods.formState.isValidating && errorMessages.length > 0) {
      setErrorMessages([]);
    }
  }, [methods.formState, errorMessages]);

  const handleButtonSubmit = async (
    formdata: SetPasswordData
  ): Promise<void> => {
    const data = new URLSearchParams({
      password: formdata.usr_password_plain,
      key: formdata.key,
    });
    await mutation
      .mutateAsync(data)
      .then((res) => {
        if (res.status === "ok") {
          history.push("/");
        } else {
          setErrorMessages(
            res.errors.map((message: string) => <div>{message}</div>)
          );
          // TODO: How to render multiple error messages for one field?
          for (const field in res.errorsItemized) {
            if (field === "usr_password_plain") {
              const errors: string[] = res.errorsItemized[field];
              errors.map((error) =>
                methods.setError(field as Path<SetPasswordData>, {
                  message: error,
                })
              );
            }
          }
        }
      })
      .catch();
  };

  return (
    <form>
      {errorMessages.length > 0 ? <div> {errorMessages} </div> : ""}
      <FormInputWrapper>
        <FormLabel htmlFor="password" label="Nytt lösenord" />
        <FormInput
          {...methods.register("usr_password_plain")}
          type="password"
        />
        <FormError
          message={methods.formState.errors.usr_password_plain?.message}
        />
      </FormInputWrapper>
      <FormInputWrapper>
        <FormLabel htmlFor="confirmed_password" label="Bekräfta lösenord" />
        <FormInput
          {...methods.register("confirmed_password")} // TODO: test this ref={methods.register("password", {required: true})}
          type="password"
          required
        />
        <FormError
          message={methods.formState.errors.confirmed_password?.message}
        />
      </FormInputWrapper>
      <Button
        content="Spara lösenord"
        onClick={methods.handleSubmit(handleButtonSubmit)}
      />
    </form>
  );
}
