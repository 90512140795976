import { ReactElement } from "react";
import { SimpleLayout } from "../components/layout/SimpleLayout";
import ProgressRing from "../components/ProgressRing/ProgressRing";
import { getZeroOrMaxTotalCount } from "../util";
import { useGetOrganizations } from "./Organizations/useGetOrganizations";
import { useGetActiveUsers } from "./AdminDashboard/useGetActiveUsers";
import { useGetCompletedModules } from "./UserModules/endpoints";
import { useGetDashboardModules, useGetUsers } from "../api/endpoints";

export default function AdminDashboard(): ReactElement {
  const { status: modulesStatus, data: modules } = useGetDashboardModules();
  const { status: usersStatus, data: users } = useGetUsers({
    page: "1",
    perPage: 1,
  });
  const {
    status: completedModulesStatus,
    data: completedModules,
  } = useGetCompletedModules({ page: "1", perPage: 1 });
  const { status: orgsStatus, data: organizations } = useGetOrganizations({
    page: "1",
    perPage: 1,
  });

  const amountAllUsersModules =
    users && modules ? users._meta.totalCount * modules._meta.totalCount : 0;
  const completedModulesCount = completedModules
    ? completedModules._meta.totalCount
    : 0;

  return (
    <SimpleLayout>
      <section className="bg-medium-gray bg-opacity-100 p-14 pt-10 pb-20 border-l-4 border-ocean-blue max-w-5xl">
        <header className="flex justify-between mb-4">
          <h4 className="m-0">Statistik</h4>
          <h4 className="m-0">2021–{new Date().getFullYear()}</h4>
        </header>

        <div className="flex justify-between flex-col lg:flex-row">
          {/* Active users out of total Users */}

          <ActiveUsersProgressRing />

          {/* Completed Modules */}
          <ProgressRing
            error={
              usersStatus === "error" ||
              modulesStatus === "error" ||
              completedModulesStatus === "error"
            }
            loading={
              usersStatus !== "success" ||
              modulesStatus !== "success" ||
              completedModulesStatus !== "success"
            }
            radius={110}
            stroke={8}
            progress={(completedModulesCount / amountAllUsersModules) * 100}
            number={completedModulesCount}
            label={
              completedModulesCount === 1
                ? "genomförd modul"
                : "genomförda moduler"
            }
            caption={`Av ${amountAllUsersModules} möjliga`}
            url="/genomforda-moduler"
          />

          {/* Organizations */}
          <ProgressRing
            error={modulesStatus === "error" || orgsStatus === "error"}
            loading={modulesStatus !== "success" || orgsStatus !== "success"}
            radius={110}
            stroke={8}
            progress={100}
            number={getZeroOrMaxTotalCount(organizations)}
            label={
              organizations?._meta.totalCount === 1
                ? "organisation"
                : "organisationer"
            }
            caption={"på plattformen"}
            url="/organisationer"
          />
        </div>
      </section>
    </SimpleLayout>
  );
}

function ActiveUsersProgressRing(): ReactElement {
  const { status: activeUsersStatus, data: activeUsers } = useGetActiveUsers({
    page: "1",
    perPage: 1,
  });
  const { status: usersStatus, data: users } = useGetUsers({
    page: "1",
    perPage: 1,
  });

  const activeUsersCount =
    activeUsersStatus === "success" && activeUsers != null
      ? activeUsers._meta.totalCount
      : 0;
  const usersCount =
    usersStatus === "success" && users != null ? users._meta.totalCount : 0;

  return (
    <ProgressRing
      loading={activeUsersStatus !== "success" || usersStatus !== "success"}
      error={activeUsersStatus === "error" || usersStatus === "error"}
      radius={110}
      stroke={8}
      progress={(activeUsersCount / usersCount) * 100}
      number={activeUsersCount}
      label={activeUsersCount === 1 ? "aktiv användare" : "aktiva användare"}
      caption={`Av ${usersCount} registrerade`}
      url="/anvandare"
    />
  );
}
