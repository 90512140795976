import { ReactElement, ReactNode } from "react";
import { Redirect, Route, useLocation } from "react-router";
import { isNotEmpty } from "../util";
import UnauthorizedPage from "../components/layout/UnathorizedPage";
import inMemoryToken from "../hooks/TokenManager";

export function PublicRoute(props: {
  path: string;
  component: ReactNode;
  exact?: boolean;
}): ReactElement {
  const location = useLocation<Location | undefined>();
  if (isNotEmpty(inMemoryToken.getToken())) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: location },
        }}
      />
    );
  } else {
    return (
      <Route exact={props.exact} path={props.path}>
        <UnauthorizedPage>{props.component}</UnauthorizedPage>
      </Route>
    );
  }
}
