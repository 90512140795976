import {
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  UseFormReturn,
} from "react-hook-form";
import { GetListResponse, Media } from "./api/types";

export function isEmpty(
  input: string | null | undefined
): input is null | undefined | "" {
  return input == null || input === "";
}

export function isNotEmpty(input: string | null | undefined): input is string {
  return !isEmpty(input);
}

export function getZeroOrMaxTotalCount<T>(
  input: undefined | GetListResponse<T>
): number {
  return input != null ? input._meta.totalCount : 0;
}

export function getFormData<T>(object: T, keys: string[] = []): unknown[] {
  return Object.entries(object).reduce<unknown[]>((pairs, [key, value]) => {
    if (typeof value === "object")
      pairs.push(...getFormData(value, [...keys, key]));
    else pairs.push([[...keys, key], value]);
    return pairs;
  }, []);
}

/**
 * Experimental removal of FieldValus that have "empty" values.
 * @param data
 */
export function removeEmptyFields(data: FieldValues): FieldValues {
  Object.keys(data).forEach((key) => {
    if (isEmpty(data[key])) {
      delete data[key];
    } else if (Array.isArray(data[key])) {
      data[key] = (data[key] as FieldValues[]).filter((value: FieldValues) => {
        if (Object.keys(value).length > 0) {
          const d = removeEmptyFields(value);
          return Object.keys(d).length > 0;
        } else {
          return value;
        }
      });
      if ((data[key] as unknown[]).length < 1) {
        delete data[key];
      }
    }
  });
  return data;
}

/**
 * Setting form errors based on server errors by mapping the
 * errors from a post request to the react hook form fields.
 * @param errorsItemized server errors
 * @param methods react hook form methods
 */
export function setFormErrors<T>(
  errorsItemized: { [field: string]: string[] },
  methods: UseFormReturn<T>
): void {
  for (const field in errorsItemized) {
    const errors: string[] = errorsItemized[field];
    errors.map((error) =>
      methods.setError(field as Path<T>, {
        message: error,
      })
    );
  }
}

/**
 *
 * @param errorsItemized
 * @returns
 */
export function getValidationGenericErrorMessages(errorsItemized: {
  [field: string]: string[];
}): string[] {
  return Object.keys(errorsItemized).includes("_generic")
    ? errorsItemized._generic.map((error) => {
        const start = error.indexOf(": ");
        const text = error.substr(start + 1);
        return text;
      })
    : [];
}

export function getReferencingDeleteError(
  ownerModel: string,
  errorsItemized: {
    [field: string]: string[];
  }
): string[] {
  const referencingDeleteError: string[] = [];
  for (const field in errorsItemized) {
    for (const error of errorsItemized[field]) {
      if (error.startsWith("Cannot delete record as it is referenced by ")) {
        const referencedModel = error.replace(
          "Cannot delete record as it is referenced by ",
          ""
        );

        // Do not forget the dot in the end...
        let referencedModelTitle = "";
        switch (referencedModel) {
          case "userRoles.":
            referencedModelTitle = "användare";
            break;
          case "organizations.":
            referencedModelTitle = "organisationer";
            break;
          case "licenses.":
            referencedModelTitle = "licenser";
            break;
        }

        referencingDeleteError.push(
          `${ownerModel} går inte att ta bort eftersom det finns ${referencedModelTitle} kopplade till den.`
        );
      }
    }
  }
  return referencingDeleteError;
}

export function getFormArrayMinError<T>(
  error: (DeepMap<T, FieldError> | undefined)[] | undefined
): string {
  return error != null && typeof error === "object" && "message" in error
    ? error["message"]
    : "";
}

export function getMediaByRefAndType(
  media: { [ref: string]: Media[] } | undefined,
  ref: string,
  type: "document" | "video" | "audio" | "info"
): Media[] {
  return media != null && ref in media
    ? media[ref].filter(
        (m: Media) => m.media_ref === ref && m.media_type === type
      )
    : ([] as Media[]);
}

export function generateRandomNumber(): number {
  const length = 8;
  const chars = "0123456789";
  let result = "";
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];

  return parseInt(result);
}
