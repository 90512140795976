import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, ReactNode } from "react";

export default function FormFieldArrayRow(props: {
  children: ReactNode;
  title?: string;
  deleteItem?(): void | undefined;
}): ReactElement {
  return (
    <div className="new-org-fields border-l-4 border-ocean-blue my-8 ">
      <div>
        <h4 className="m-0 p-4 pt-0">
          {props.title}
          {props.deleteItem != null ? (
            <button
              className="new-org-fields-remove-btn ml-2 text-red"
              type="button"
              onClick={() =>
                props.deleteItem != null ? props.deleteItem() : ""
              }
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
          ) : (
            ""
          )}
        </h4>
      </div>
      <div className=" p-8 bg-medium-gray">{props.children}</div>
    </div>
  );
}
