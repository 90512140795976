import { ReactElement } from "react";
import { useGetClients, useGetUsers } from "../api/endpoints";
import { Client, User } from "../api/types";
import { LicensesList } from "../components/clients/Licenses";
import Header from "../components/layout/Header";
import {
  ClientDetails,
  ClientOrgAdminsList,
  ClientUsersTable,
} from "../pages/ViewClient";
import Loader from "../components/layout/Loader";
import { SimpleLayout } from "../components/layout/SimpleLayout";
import ResourceHint from "../components/ResourcesHint";

export default function ClientDashboard(): ReactElement {
  const query = useGetClients();
  const usersQuery = useGetUsers();
  let client: Client;
  let users: User[];

  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      client = query.data.items[0];
      users = usersQuery.isSuccess ? usersQuery.data.items : [];
      return (
        <SimpleLayout>
          <Header title={client.cl_name} />
          <div className="mt-4 mb-12">Status: Aktiv</div>
          <ResourceHint className="my-12" forAdmin={true} />
          <LicensesList clientId={client.clientID.toString()} />

          {/* Statistics */}

          <div className="mt-16 flex">
            <div className="pr-10">
              <ClientOrgAdminsList users={users} client={client} />
              <ClientDetails client={client} />
            </div>
            <div className="flex-1">
              <ClientUsersTable users={users} />
              {/* <OrganizationTable
                clientId={client.clientID}
                orgs={client.organizations as Organization[]}
              /> */}
            </div>
          </div>
        </SimpleLayout>
      );
  }
}
