import { yupResolver } from "@hookform/resolvers/yup";
import { ReactElement, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { postData } from "../api/endpoints";
import { Button, FilledButton } from "../components/Buttons";
import CardHeader from "../components/CardHeader";
import { FormInput } from "../components/formLayout/FormInput";
import FormInputWrapper from "../components/formLayout/FormInputWrapper";
import FormLabel from "../components/formLayout/FormLabel";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("E-post kräver en gilltig adress")
    .required("E-post är ett obligatoriskt fält"),
});
export default function ForgotPassword(): ReactElement {
  const methods = useForm<{ email: string }>({
    defaultValues: { email: "" },
    resolver: yupResolver(schema),
  });
  const [passwordSent, setPasswordSent] = useState(false);

  const handleButtonSubmit = async (formData: {
    email: string;
  }): Promise<void> => {
    await postData<{ email: string }>(
      "auth/request-password-reset",
      new URLSearchParams(formData)
    )
      .then((response) => {
        setPasswordSent(true);
      })
      .catch((error) => {
        console.log(error);
        // TODO: Show message with error
      });
  };
  if (passwordSent) {
    return (
      <ForgottenPasswordSent
        onResendPassword={() => handleButtonSubmit(methods.getValues())}
      />
    );
  } else {
    return (
      <div className="mt-8">
        <CardHeader
          title="Glömt Lösenord"
          subtitle="Har du glömt ditt lösenord? Fyll i din e-postadress så skickar vi ett mejl med en återställnings-länk i så kan du skapa ett nytt lösenord snabbt och enkelt!"
        />
        <form className="form">
          <FormInputWrapper>
            <FormLabel htmlFor="email" label="E-post" />
            <FormInput {...methods.register("email")} type="email" required />
            {methods.formState.errors.email?.message}
          </FormInputWrapper>
          <Button
            type="submit"
            onClick={methods.handleSubmit((data) => handleButtonSubmit(data))}
            content="Skicka"
          />
        </form>
      </div>
    );
  }
}

export function ForgottenPasswordSent(props: {
  onResendPassword: () => void;
}): ReactElement {
  const history = useHistory();

  return (
    <>
      <CardHeader
        title="Tack!"
        subtitle="Ett mejl med återställnings-länk har nu skickats till e-postadressen du angett. Om du inte hittar något kan det finnas i skräpposten. Annars kan du klicka på knappen nedan och skicka ett nytt e-post."
      />
      <div className="mt-8">
        <FilledButton
          content="Logga in"
          onClick={() => history.push("/logga-in")}
        />
        <Button
          content="Skick mejlet igen"
          className="ml-2"
          onClick={(e) => {
            props.onResendPassword();
          }}
        />
      </div>
    </>
  );
}
