import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useAuth } from "../../hooks/ProvideAuth";
import { isNotEmpty } from "../../util";
import AdminMenu from "../menus/AdminMenu";
import ClientOrgAdminMenu from "../menus/ClilentOrgAdminMenu";
import OrgAdminMenu from "../menus/OrgAdminMenu";
import RegUserMenu from "../menus/RegUserMenu";

export default function AuthorizedPage(props: {
  menu?: boolean;
  children: ReactNode;
}): ReactElement {
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const appVersion = isNotEmpty(process.env.REACT_APP_VERSION)
    ? process.env.REACT_APP_VERSION
    : "";
  const clickhandler = (): void => {
    history.push("/");
  };

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  const [menuOpen, setMenuOpen] = useState(false);

  let menu: ReactNode;
  switch (auth?.highestRole) {
    case "admin":
      menu = <AdminMenu />;
      break;
    case "clientOrgAdmin":
      menu = <ClientOrgAdminMenu />;
      break;
    case "orgAdmin":
      menu = <OrgAdminMenu />;
      break;
    case "regUser":
    default:
      menu = <RegUserMenu />;
      break;
  }

  return (
    <div className="relative">
      <div
        className={`detect-click-outside-mobile-menu ${
          menuOpen ? "inline" : "hidden"
        } absolute inset-0 z-5`}
        onMouseDown={() => setMenuOpen(false)}
        aria-hidden="true"
      ></div>
      <div className="xl:hidden flex flex-row justify-between bg-light-mint py-2 px-16 ">
        <div>
          <button onClick={clickhandler}>
            <img
              className="w-16 m-auto"
              src={process.env.PUBLIC_URL + "/logo-icon.png"}
              alt=""
            />
          </button>
        </div>
        <button
          className="flex items-center cursor-pointer"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <FontAwesomeIcon icon={faBars} size="lg" />
        </button>
      </div>
      <div className="flex flex-row min-h-screen min-w-screen relative">
        {/* DESKTOP MENU */}
        <div
          className={`flex-none hidden xl:block w-56 2xl:w-72 bg-light-mint bg-opacity-70 p-4 text-center relative`}
        >
          <button onClick={clickhandler}>
            <img
              className="w-4/5 md:w-3/5 pb-6 m-auto"
              src={process.env.PUBLIC_URL + "/logo-icon.png"}
              alt=""
            />
          </button>
          {menu}
          <div className="absolute bottom-0 left-0 object-bottom w-full p-4">{`v ${appVersion}`}</div>
        </div>

        {/* MOBILE MENU */}
        <div
          className={`mobile-menu-box-shadow ${
            menuOpen ? "inline" : "hidden"
          } absolute w-full sm:w-72 right-0 sm:mr-6 p-12 bg-light-mint text-center min-w-52 min-h-auto z-10`}
        >
          {menu}
          <div className=" w-fit mt-20 text-left p-4">{`v ${appVersion}`}</div>
        </div>

        <>{props.children}</>
      </div>
    </div>
  );
}
