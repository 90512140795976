import { ReactElement, ReactNode } from "react";
import Loader from "../../components/layout/Loader";
import { useAuth } from "../../hooks/ProvideAuth";
import { useGetOrganizations } from "../../pages/Organizations/useGetOrganizations";
import { Client } from "../../api/types";
import { Link } from "react-router-dom";
import { isEmpty } from "../../util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../Buttons";

export default function Footer(props: { children?: ReactNode }): ReactElement {
  const orgQuery = useGetOrganizations();
  const auth = useAuth();

  switch (orgQuery.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      return (
        <div className="flex p-10 bg-soft-mint bg-opacity-30 md:p-12 lg:p-16 xl:p-20 xl:pb-16">
          <CardWithImage />
          <div className="flex-1">
            <div className="w-full grid grid-cols-1 gap-10 md:grid-cols-2 md:gap-12 lg:gap-16 xl:gap-20 2xl:grid-cols-4 2xl:gap-24">
              {
                // NAVIGERA
              }
              <FooterColumn title="Navigera">
                <ul className="">
                  <NavigationMenuItem path="/moduler">
                    Moduler
                  </NavigationMenuItem>
                  <NavigationMenuItem path="/notiser">
                    Notiser
                  </NavigationMenuItem>
                  <NavigationMenuItem path="/laslogg">
                    Läsloggar
                  </NavigationMenuItem>
                  <NavigationMenuItem path="/mitt-konto">
                    Mitt konto
                  </NavigationMenuItem>
                  <NavigationMenuItem>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={process.env.REACT_APP_INTEGRITY_POLICY_LINK}
                      className="cursor-pointer"
                    >
                      Integritetspolicy{" "}
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        size="xs"
                        className="ml-1"
                      />
                    </a>
                  </NavigationMenuItem>
                </ul>
              </FooterColumn>

              {
                // ORGANISATION
                orgQuery.data.items.length > 0 &&
                  auth?.highestRole === "regUser" && (
                    <FooterColumn title="Organisation">
                      <h5>
                        Du tillhör{" "}
                        <span className="font-bold">
                          {orgQuery.data.items[0].org_name}
                        </span>
                      </h5>
                    </FooterColumn>
                  )
              }

              {
                // UPPDRAGSGIVARE
                orgQuery.data.items.length > 0 &&
                  auth?.highestRole === "orgAdmin" &&
                  orgQuery.data.items[0].client && (
                    <ClientColumn client={orgQuery.data.items[0].client} />
                  )
              }

              {/* LATHUND */}
              <FooterColumn title="Lathund">
                <p>
                  Här hittar du instruktioner och steg-för-steg guider för olika
                  funktioner på plattformen.
                </p>
                <Button
                  size="medium"
                  className="mt-5 block"
                  content={
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`https://media.forskoleutveckling.nu/persistence/media/mangfaldens-forskole/Lathund%20fo%CC%88r%20inloggning%20och%20anva%CC%88ndarhantering%201.2.pdf`}
                      className="font-bold uppercase"
                    >
                      Inloggning{" "}
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        size="xs"
                        className="ml-1"
                      />
                    </a>
                  }
                />

                {(auth?.highestRole === "clientOrgAdmin" ||
                  auth?.highestRole === "admin" ||
                  auth?.highestRole === "orgAdmin") && (
                  <Button
                    size="medium"
                    className="mt-5 block"
                    content={
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={`https://media.forskoleutveckling.nu/persistence/media/mangfaldens-forskole/Lathund%20för%20plattformen%20Mångfaldens%20förskola.pdf`}
                        className="font-bold uppercase"
                      >
                        Allmänt{" "}
                        <FontAwesomeIcon
                          icon={faExternalLinkAlt}
                          size="xs"
                          className="ml-1"
                        />
                      </a>
                    }
                  />
                )}
              </FooterColumn>

              {(auth?.highestRole === "clientOrgAdmin" ||
                auth?.highestRole === "admin" ||
                auth?.highestRole === "orgAdmin") && (
                <>
                  {/* SUPPORT */}
                  <FooterColumn title="Support">
                    <p>
                      Har du frågor kan du mejla oss på:{" "}
                      <span className="font-bold">
                        support@forskoleutveckling.nu
                      </span>
                    </p>
                  </FooterColumn>
                </>
              )}

              <FooterColumn title="Nyhetsbrev">
                <p>
                  Vill du prenumerera på vårt nyhetsbrev? (ca. 1 utskick/månad)
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`https://forskoleutveckling.nu/nyhetsbrev/`}
                    className="font-bold uppercase "
                  >
                    <Button
                      size="medium"
                      className="mt-5 block flex justify-between items-center "
                      content={
                        <>
                          Klicka här
                          <FontAwesomeIcon
                            icon={faExternalLinkAlt}
                            size="xs"
                            className="ml-2"
                          />
                        </>
                      }
                    />
                  </a>
                </p>
              </FooterColumn>
            </div>

            {/* COPYRIGHT */}
            <div className="mt-12 flex flex-wrap lg:pt-6">
              <span className="hidden mx-2 2xl:block">|</span>
              <div className="">
                Design & utveckling:{" "}
                <span className="font-extrabold">WebbTrim</span>
              </div>
              <span className="hidden mx-2 md:block">|</span>
              <div className="w-full md:w-auto">
                Videoredigering:{" "}
                <span className="font-extrabold">TaRaZa Production</span>
              </div>
              <div className="w-full mt-6 md:mt-0 2xl:order-first 2xl:w-auto">
                © {new Date().getFullYear()} Copyright förskoleutveckling
                utbildningsplattform
              </div>
            </div>
          </div>
        </div>
      );
  }
}

function CardWithImage(): ReactElement {
  return (
    <div className="flex-initial lg:w-40 hidden lg:block lg:pr-16 xl:pr-20">
      <img
        className="w-full"
        alt="Logo"
        src={process.env.PUBLIC_URL + "/logo-icon-snug.png"}
      />
    </div>
  );
}

function FooterColumn(props: {
  title: string;
  children: ReactNode;
}): ReactElement {
  return (
    <div className="">
      <h4 className="uppercase font-extrabold">{props.title}</h4>
      <div>{props.children}</div>
    </div>
  );
}

function ClientColumn(props: { client: Client }): ReactElement {
  return (
    <FooterColumn title="Uppdragsgivare">
      <h5 className="uppercase font-semibold">{props.client.cl_name}</h5>
      <div>Organisationsnummer: {props.client.cl_org_number}</div>
      <h5 className="uppercase mt-5 font-semibold">Kontaktperson</h5>
      <div>{props.client.cl_contact_name}</div>
      <div>{props.client.cl_contact_email}</div>
      <div>{props.client.cl_contact_phone}</div>
    </FooterColumn>
  );
}

function NavigationMenuItem(props: {
  path?: string;
  children: ReactNode;
}): ReactElement {
  const navlink = isEmpty(props.path) ? (
    props.children
  ) : (
    <Link to={props.path} className="relative text-ocean-blue">
      {props.children}
    </Link>
  );

  return (
    <li className="block mb-1 pb-1 border-b border-ocean-blue border-opacity-20 last:border-b-0 ">
      {navlink}
    </li>
  );
}
