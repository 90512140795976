import { useMemo } from "react";
import { GetListResponseMeta } from "../api/types";
import {
  PaginationPage,
  PaginationProps,
} from "../components/layout/PaginationUI";
import { isNotEmpty } from "../util";

export default function useServerPagination(
  meta: GetListResponseMeta | undefined,
  page: string | null,
  paginationClicked: (
    pageNumber: number | undefined,
    previousPage: number | undefined
  ) => void
): PaginationProps {
  return useMemo(() => {
    const PAGES_TO_SHOW = 5;
    const currentPage = isNotEmpty(page) ? parseInt(page) : 1;
    const fromItem = meta != null ? (currentPage - 1) * meta.perPage + 1 : 0;

    let toItem = 0;
    const pages: PaginationPage[] = [];
    if (meta != null) {
      if (currentPage === meta.pageCount) {
        if (currentPage === 0) {
          toItem = meta.totalCount;
        } else {
          toItem = meta.perPage * (currentPage - 1);
          const remainder = meta.totalCount % meta.perPage;
          toItem += remainder === 0 ? meta.perPage : remainder;
        }
      } else {
        toItem = meta.perPage * currentPage;
      }

      let startPage = 1;
      let endPage = meta.pageCount;
      if (meta.pageCount > PAGES_TO_SHOW) {
        startPage = currentPage < 3 ? 1 : currentPage - 2;
        endPage = 4 + startPage;
        endPage = meta.pageCount < endPage ? meta.pageCount : endPage;
        const diff = startPage - endPage + 4;
        startPage -= startPage - diff > 0 ? diff : 0;
      }

      for (let x = startPage; x <= endPage; x++) {
        const activepage = currentPage === x;
        pages.push({
          key: `page-${x}`,
          activePage: activepage.toString(),
          pageNumber: x,
        });
      }
    }
    return {
      pages: pages,
      currentPage,
      pageCount: meta == null ? 0 : meta.pageCount,
      fromItem,
      toItem,
      onPaginationClicked: paginationClicked,
      pagesToShow: PAGES_TO_SHOW,
    };
  }, [page, paginationClicked, meta]);
}
