import { useCallback, useEffect, useMemo, useState } from "react";
import {
  PaginationPage,
  PaginationProps,
} from "../components/layout/PaginationUI";

export default function useClientPagination(props: {
  totalCount: number;
  perPage?: number;
}): PaginationProps {
  const [perPage] = useState(props.perPage != null ? props.perPage : 10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [props.totalCount]);

  const handlePaginationClicked = useCallback(
    (pageNumber: number | undefined): void => {
      setCurrentPage(pageNumber != null ? pageNumber : 1);
    },
    []
  );
  return useMemo(() => {
    const pageCount = Math.ceil(props.totalCount / perPage);
    const fromItem = (currentPage - 1) * perPage + 1;

    let toItem = 0;
    const remainder = props.totalCount % perPage;
    const pages: PaginationPage[] = [];
    toItem =
      currentPage === pageCount
        ? perPage * (currentPage - 1) + (remainder === 0 ? perPage : remainder)
        : perPage * currentPage;

    let startPage = 1;
    let endPage = pageCount;
    if (pageCount > perPage) {
      startPage = currentPage < 3 ? 1 : currentPage - 2;
      endPage = 4 + startPage;
      endPage = pageCount < endPage ? pageCount : endPage;
      const diff = startPage - endPage + 4;
      startPage -= startPage - diff > 0 ? diff : 0;
    }

    for (let x = startPage; x <= endPage; x++) {
      const activepage = currentPage === x;
      pages.push({
        key: `page-${x}`,
        activePage: activepage.toString(),
        pageNumber: x,
      });
    }

    return {
      pages: pages,
      pageCount,
      fromItem: fromItem,
      toItem: toItem,
      currentPage,
      pagesToShow: perPage,
      onPaginationClicked: handlePaginationClicked,
    };
  }, [currentPage, perPage, props.totalCount, handlePaginationClicked]);
}
