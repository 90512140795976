import { useQuery, UseQueryResult } from "react-query";
import { fetchApiGet } from "../../api/api";
import { GetListResponse, User } from "../../api/types";
import { isNotEmpty } from "../../util";

export function useGetActiveUsers(props?: {
  page?: string;
  perPage?: number;
}): UseQueryResult<GetListResponse<User>, Error> {
  let key = ["users", "active"];
  let queryString = "?filter[usr_status]=active";

  if (props != null) {
    if (isNotEmpty(props.page)) {
      key = [...key, "page", props.page];
      queryString += "&page=" + props.page;
    }

    if (props.perPage != null) {
      queryString += `&per-page=${props.perPage}`;
      key = [...key, `perPage=${props.perPage}`];
    }
  }

  const path = "users" + queryString;

  return useQuery<GetListResponse<User>, Error>(key, () =>
    fetchApiGet<GetListResponse<User>>(path)
  );
}
