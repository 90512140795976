import { ReactElement, useCallback } from "react";
import { useParams } from "react-router";
import { Module } from "../../api/types";
import Header from "../../components/layout/Header";
import Loader from "../../components/layout/Loader";
import ModuleLayout from "../../components/layout/ModuleLayout";
import { ReadOnlyProvider } from "../../components/modules/LectureModule/ReadOnyProvider";
import ModuleInputHeader from "../../components/modules/ModuleInputWrapper/ModuleInputHeader";
import ModuleInputWrapper from "../../components/modules/ModuleInputWrapper/ModuleInputWrapper";
import { ModuleLogForm } from "../../components/modules/ModuleLogForm";
import ModuleLogPropositionForm from "../../components/modules/ModuleLogPropositionForm";
import FeedbackForm from "../../components/modules/UserModules/FeedbackForm";
import UseQueryError from "../../components/UseQueryError";
import { isNotEmpty } from "../../util";
import {
  useGetUserModuleOverview,
  useGetUserModuleOverViewUser,
} from "./endpoints";
import { UserDocumentsList } from "./UserDocumentsList";

export default function UserModuleOverviewPage(): ReactElement {
  const { userId, moduleId } = useParams<{
    userId: string;
    moduleId: string;
  }>();
  const moduleQuery = useGetUserModuleOverview(userId, moduleId);
  const userQuery = useGetUserModuleOverViewUser(userId);
  const uID = parseInt(userId);

  const getAnalysisReflectionText = useCallback((module: Module) => {
    const mp = module.moduleProgresses?.filter(
      (mp) => mp.mprog_step === "m_analysis_text"
    );
    return mp != null && mp.length > 0 && isNotEmpty(mp[0].mprog_data)
      ? mp[0].mprog_data
      : "";
  }, []);

  const loadingStatuses = ["idle", "loading"];
  if (moduleQuery.status === "success" && userQuery.status === "success") {
    moduleQuery.data.moduleLogs = moduleQuery.data.moduleLogs?.filter(
      (m) => m.mlog_userID === uID
    );
    moduleQuery.data.messages = moduleQuery.data.messages?.filter((m) =>
      m.msg_type === "FBC" ? m.msg_for_userID === uID : m.msg_userID === uID
    );
    moduleQuery.data.moduleProgresses = moduleQuery.data.moduleProgresses?.filter(
      (m) => m.mprog_userID === uID
    );

    return (
      <ModuleLayout>
        <div className="module-content-wrapper">
          <Header title={`Översikt modul ${moduleQuery.data.m_number}`} />
          <div>
            <div>
              {`${userQuery.data.usr_firstname} ${userQuery.data.usr_lastname}`}
            </div>
          </div>
          {
            <ReadOnlyProvider value={true}>
              <div className="mt-12">
                <div>
                  <h3>Läsning och läslogg</h3>
                  <ModuleLogForm module={moduleQuery.data} />
                  <div className="mt-12">
                    <ModuleLogPropositionForm module={moduleQuery.data} />
                  </div>
                </div>
                <div className="mt-12">
                  <h3>Reflektion och analys</h3>
                  <ModuleInputWrapper className="mt-12">
                    <ModuleInputHeader title="Dokumentation" />
                    <div className="border h-80">
                      <textarea
                        defaultValue={getAnalysisReflectionText(
                          moduleQuery.data
                        )}
                        disabled={true}
                        maxLength={1500}
                        className="w-full h-full resize-none p-2 text-lg"
                      ></textarea>
                    </div>
                    <div>
                      {getAnalysisReflectionText(moduleQuery.data).length}
                      /1500 tecken
                    </div>
                  </ModuleInputWrapper>

                  <ModuleInputWrapper className="mt-12">
                    <ModuleInputHeader title="Bifogade dokument" />
                    <UserDocumentsList
                      userId={userId}
                      moduleId={moduleQuery.data.moduleID}
                    />
                  </ModuleInputWrapper>
                </div>
                <div className="mt-12">
                  <h3>Återkoppling på reflektion och analys</h3>
                  <FeedbackForm
                    module={moduleQuery.data}
                    userId={parseInt(userId)}
                  />
                </div>
              </div>
            </ReadOnlyProvider>
          }
        </div>
      </ModuleLayout>
    );
  } else if (
    loadingStatuses.includes(moduleQuery.status) ||
    loadingStatuses.includes(userQuery.status)
  ) {
    return <Loader />;
  } else {
    return <UseQueryError />;
  }
}
