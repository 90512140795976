import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ReactElement, useCallback, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Client, GetListResponse, License } from "../api/types";
import { Button } from "../components/Buttons";
import Header from "../components/layout/Header";
import Search from "../components/Search";
import Loader from "../components/layout/Loader";
import { SimpleLayout } from "../components/layout/SimpleLayout";
import { isNotEmpty } from "../util";
import PaginationUI from "../components/layout/PaginationUI";
import useServerPagination from "../hooks/useServerPagination";
import { UseQueryResult } from "react-query";
import { useGetClients } from "../api/endpoints";

export default function Clients(): ReactElement {
  const history = useHistory();
  const location = useLocation();
  const totalClientsCount = useRef(0);
  const [defaultFilterValue] = useState(
    new URLSearchParams(location.search).get("s")
  );

  const searchParams = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    return {
      page: urlParams.get("sida"),
      filter: urlParams.get("s"),
    };
  }, [location.search]);
  const query = useGetClients({
    page: searchParams.page,
    filter: searchParams.filter != null ? searchParams.filter : "",
    perPage: 25,
    expands: ["licenses"],
  });

  const urlChanged = useCallback(
    (filter: string | null, pageNumber: string | null): void => {
      const params: string[] = [];
      if (isNotEmpty(filter)) {
        params.push(`s=${filter}`);
      }
      if (isNotEmpty(pageNumber)) {
        params.push(`sida=${pageNumber}`);
      }
      let path = "uppdragsgivare";
      if (params.length > 0) {
        path += `?${params.join("&")}`;
      }
      history.push(path);
    },
    [history]
  );

  const handlePaginationChange = useCallback(
    (pageNumber: number | undefined, previousPage: number | undefined) => {
      if (pageNumber !== previousPage) {
        const pageString = pageNumber != null ? pageNumber.toString() : null;
        urlChanged(searchParams.filter, pageString);
      }
    },
    [urlChanged, searchParams]
  );

  const handleSearch = useCallback(
    (searchString: string) => {
      urlChanged(searchString, null);
    },
    [urlChanged]
  );
  const paginationProps = useServerPagination(
    query.data?._meta,
    searchParams.page,
    handlePaginationChange
  );
  if (query.status === "success") {
    totalClientsCount.current = query.data._meta.totalCount;
  }

  return (
    <SimpleLayout>
      <Header
        title={
          query.isSuccess
            ? `Uppdragsgivare | ${totalClientsCount.current}st`
            : "Uppdragsgivare"
        }
      >
        <div className="flex items-center self-center space-x-2">
          <Button
            size="medium"
            icon={faPlus}
            content="Lägg till Uppdragsgivare"
            onClick={() => history.push("uppdragsgivare/ny")}
          />
        </div>
      </Header>
      <div className="filter-search-wrapper">
        <Search
          onSearch={handleSearch}
          defaultValue={
            isNotEmpty(defaultFilterValue) ? defaultFilterValue : ""
          }
        />
      </div>
      <div>
        <PaginationUI {...paginationProps}>
          <ClientsTable query={query} />
        </PaginationUI>
      </div>
    </SimpleLayout>
  );
}

function ClientsTable({
  query,
}: {
  query: UseQueryResult<GetListResponse<Client>, Error>;
}): ReactElement {
  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      return (
        <>
          <table className="table w-full fsu-table ">
            <thead>
              <tr>
                <th>Uppdragsgivare</th>
                <th>Org.nummer</th>
                <th>Licenser</th>
                <th>Åtgärder</th>
              </tr>
            </thead>
            <tbody>
              {query.data.items.map((c: Client) => {
                const licenses = c.licenses != null ? c.licenses : [];
                return (
                  <tr key={c.cl_name}>
                    <td>
                      <Link to={`/uppdragsgivare/${c.clientID}`}>
                        {c.cl_name}
                      </Link>
                    </td>
                    <td>{c.cl_org_number}</td>
                    <td>
                      {licenses.length > 0
                        ? licenses
                            .map(
                              (l: License) =>
                                `${l.l_start_date}-${l.l_end_date}`
                            )
                            .join(", ")
                        : "Inga licenser"}
                    </td>
                    <td>
                      <Link to={`/uppdragsgivare/redigera/${c.clientID}`}>
                        Redigera
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );
  }
}
