import {
  ChangeEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { Message } from "../../api/types";
import { useForm } from "react-hook-form";
import { MessageData } from "../../api/form-types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FilledButton } from "../../components/Buttons";
import { useMutation } from "react-query";
import { postData, deleteData } from "../../api/endpoints";
import calculateVideoTime from "../../hooks/calculateVideoTime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faExpand,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import "./VideoPlayer.css";
import Loader from "../layout/Loader";
import MessageItem from "./MessageItem";
import { generateRandomNumber, isEmpty, isNotEmpty } from "../../util";
// import InfoText from "./InfoText";

interface PlayerProps {
  currentTime?: number; // seconds
  videoUrl: string;
  posterUrl?: string;
  onEnded?: () => void;
  messages?: Message[];
  moduleID?: number;
  mediaID?: number;
}

export type MessageWithKey = {
  key: string;
  message: MessageData;
  status: "error" | "pending" | "uploaded";
};

const schema = yup.object({
  msg_type: yup.string(),
  msg_text: yup.string().required("Skriva dina anteckning"),
  messageID: yup.number(),
  msg_moduleID: yup.number(),
  msg_mediaID: yup.number(),
  msg_position: yup.number(),
});

export default function VideoPlayer({
  posterUrl,
  onEnded,
  videoUrl,
  messages,
  moduleID,
  mediaID,
}: PlayerProps): ReactElement {
  const video = useRef<HTMLVideoElement>(null);
  const videoPlayer = useRef<HTMLDivElement>(null);
  const messagesPlus =
    messages && moduleID != null
      ? messages.map(
          (message): MessageWithKey => ({
            key: generateRandomNumber().toString(),
            message: {
              msg_type: message.msg_type,
              msg_text: message.msg_text,
              msg_mediaID: message.msg_mediaID,
              messageID: message.messageID.toString(),
              msg_moduleID:
                message.msg_moduleID != null ? message.msg_moduleID : moduleID,
              msg_position: message.msg_position,
            },
            status: "uploaded",
          })
        )
      : [];
  const messagesEnabled = mediaID != null && moduleID != null;

  const [localMessages, setLocalMessages] = useState<MessageWithKey[]>(
    messagesPlus
  );

  const [loading, setLoading] = useState<boolean>(
    isEmpty(videoUrl) && isNotEmpty(posterUrl) ? false : true
  );
  const [justSubmitted, setJustSubmitted] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<string>(
    calculateVideoTime(Math.floor(0))
  );
  const [seekBarTime, setSeekBarTime] = useState<number>(0);

  const [showMessages, setShowMessages] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  videoPlayer.current?.addEventListener("fullscreenchange", () => {
    setFullScreen(document.fullscreenElement?.id === "videoPlayer");
  });

  const methods = useForm<MessageData>({
    resolver: yupResolver(schema),
    defaultValues: {
      messageID: generateRandomNumber().toString(),
      msg_type: "LVC",
      msg_text: "",
      msg_mediaID: mediaID,
      msg_moduleID: moduleID,
      msg_position: 0,
    },
  });

  const mutation = useMutation((messageData: string) =>
    postData<Message>("messages", messageData)
  );

  const onSubmit = async (data: MessageData): Promise<void> => {
    data.msg_position = video.current
      ? Math.floor(video.current.currentTime)
      : 0;

    const newKey = generateRandomNumber().toString();
    setLocalMessages((prevState) => [
      ...prevState,
      { key: newKey, message: data, status: "pending" },
    ]);

    methods.reset();
    await mutation
      .mutateAsync(JSON.stringify(data))
      .then((result) => {
        setLocalMessages((prev) => {
          const updatedMessageIndex = prev.findIndex((m) => m.key === newKey);

          if (updatedMessageIndex > -1) {
            prev[
              updatedMessageIndex
            ].message.messageID = result.messageID.toString();
            prev[updatedMessageIndex].status = "uploaded";
          }
          return [...prev];
        });
        setJustSubmitted(true);
        setJustSubmitted(false);
      })
      .catch((error) => {
        console.log(error);
        setError("There was an error.");
        setLocalMessages((prev) => {
          const updatedMessageIndex = prev.findIndex((m) => m.key === newKey);

          if (updatedMessageIndex > -1) {
            prev[updatedMessageIndex].status = "error";
          }
          return [...prev];
        });
      });
  };

  const onDelete = async (messageKey: string): Promise<void> => {
    const localMessage = localMessages.find(
      (message) => message.key === messageKey
    );
    if (localMessage == null) return;
    if (localMessage.status === "error") {
      setLocalMessages((oldLocalMessages) => {
        const newLocalMessages = [...oldLocalMessages];
        const indexToBeRemoved = oldLocalMessages.findIndex(
          (object) => object.key === messageKey
        );
        if (indexToBeRemoved > -1) {
          newLocalMessages.splice(indexToBeRemoved, 1);
        }
        return newLocalMessages;
      });
    } else {
      await deleteData(`messages/${localMessage.message.messageID}`)
        .then(() => {
          setLocalMessages((oldLocalMessages) => {
            const newLocalMessages = [...oldLocalMessages];
            const indexToBeRemoved = oldLocalMessages.findIndex(
              (object) => object.key === messageKey
            );
            if (indexToBeRemoved > -1) {
              newLocalMessages.splice(indexToBeRemoved, 1);
            }

            return newLocalMessages;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSeekBarClick = (e: ChangeEvent<HTMLInputElement>): void => {
    const newTime = parseInt(e.target.value);
    setSeekBarTime(newTime);
    if (video.current) {
      video.current.currentTime = newTime;
    }
  };

  // Display only image if no videoUrl exists
  if (isEmpty(videoUrl) && isNotEmpty(posterUrl)) {
    return (
      <div>
        <img className="w-full" src={posterUrl} alt="" />
      </div>
    );
  }

  return (
    <div
      id="videoPlayer"
      ref={videoPlayer}
      className={`relative flex ${
        fullScreen ? "bg-ocean-blue" : "bg-white flex-col"
      }  text-medium-gray border border-ocean-blue`}
    >
      {loading && (
        <div className={"absolute z-50 inset-0 bg-white"}>
          <Loader />
        </div>
      )}
      <div
        className={`${
          fullScreen ? "bg-ocean-blue" : "bg-white flex-col"
        } relative flex justify-center content-between`}
      >
        <video
          onCanPlay={() => setLoading(false)}
          onClick={() => {
            setPlaying((playing) => !playing);
            if (video.current != null) {
              if (video.current.paused) {
                void video.current.play();
              } else {
                video.current.pause();
              }
            }
          }}
          className={`cursor-pointer ${
            fullScreen ? "bg-ocean-blue-dark" : "bg-ocean-blue bg-opacity-20"
          } hover:opacity-90`}
          ref={video}
          onTimeUpdate={(e) => {
            setCurrentTime(
              calculateVideoTime(Math.floor(e.currentTarget.currentTime))
            );
            setSeekBarTime(e.currentTarget.currentTime);
          }}
          onContextMenu={(e) => e.preventDefault()}
          controlsList="nodownload nofullscreen"
          poster={posterUrl}
          style={{ width: "100%" }}
          onEnded={onEnded}
        >
          {isNotEmpty(videoUrl) && <source src={videoUrl} type="video/mp4" />}
          <track kind="captions" />
          Your browser does not support the video tag.
        </video>

        {/* CONTROLS ------------ */}
        <div
          id="controls"
          className={`flex flex-col justify-between gap-4 ${
            fullScreen
              ? "bg-white bg-opacity-70 hover:bg-opacity-80 absolute bottom-0 left-0 w-full"
              : "bg-ocean-blue bg-opacity-10"
          } py-3 px-4`}
        >
          <div className="flex w-full gap-4">
            <div className="w-full flex items-center flex-wrap gap-y-2 sm:gap-0 sm:flex-nowrap">
              <button
                className={`flex-none mr-4 rounded-full bg-ocean-blue ${
                  playing ? "bg-opacity-25" : "bg-opacity-10"
                } w-8 h-8`}
                onClick={() => {
                  setPlaying((playing) => !playing);
                  if (video.current != null) {
                    if (video.current.paused) {
                      void video.current.play();
                    } else {
                      video.current.pause();
                    }
                  }
                }}
              >
                {video.current ? (
                  video.current.paused ? (
                    <FontAwesomeIcon icon={faPlay} />
                  ) : (
                    <FontAwesomeIcon icon={faPause} />
                  )
                ) : null}
              </button>

              {/* TIME ------------ */}
              <span className={"text-sm font-bold whitespace-nowrap"}>
                {video.current
                  ? `${currentTime} / ${calculateVideoTime(
                      Math.floor(video.current.duration)
                    )}`
                  : ""}
              </span>

              {/* SEEKBAR ---------------- */}
              <span
                className={
                  "w-full seekBar flex-initial flex items-center sm:ml-4 text-sm font-bold"
                }
              >
                {video.current && (
                  <input
                    value={seekBarTime}
                    type="range"
                    id="seek-bar"
                    name="seek-bar"
                    className="w-full"
                    max={video.current.duration}
                    onChange={handleSeekBarClick}
                  />
                )}
              </span>
            </div>
            <div className="flex-none flex items-start sm:items-center">
              {messagesEnabled && (
                <button
                  className={`mr-2 rounded-full bg-ocean-blue ${
                    showMessages ? "bg-opacity-25" : "bg-opacity-10"
                  } w-8 h-8`}
                  onClick={() => setShowMessages((show) => !show)}
                >
                  <FontAwesomeIcon icon={faStickyNote} />
                </button>
              )}
              <button
                className={`rounded-full bg-ocean-blue ${
                  fullScreen ? "bg-opacity-25" : "bg-opacity-10"
                } w-8 h-8`}
                onClick={() => {
                  if (videoPlayer.current != null) {
                    if (!fullScreen) {
                      void videoPlayer.current.requestFullscreen();
                    } else {
                      void document.exitFullscreen();
                    }
                  }
                }}
              >
                <FontAwesomeIcon icon={faExpand} />
              </button>
            </div>
          </div>

          {/* <InfoText /> */}
        </div>
      </div>

      {/* MESSAGES ------------ */}
      {showMessages && (
        <div
          id="messages"
          className={`flex flex-col text-sm justify-end ${
            fullScreen
              ? "relative pl-4 bottom-2 right-2 w-96"
              : "absolute right-2 top-px bottom-16 w-80"
          }  overflow-hidden`}
        >
          <ScrollToBottomDiv
            className={"max-h-full overflow-y-auto"}
            scroll={justSubmitted}
          >
            {localMessages.map((message) => (
              <MessageItem
                onClick={() => {
                  if (message.message.msg_position != null) {
                    setSeekBarTime(message.message.msg_position);
                    if (video.current) {
                      video.current.currentTime = message.message.msg_position;
                      void video.current.play();
                    }
                  }
                }}
                key={message.key}
                message={message}
                onDelete={() => onDelete(message.key)}
              />
            ))}
          </ScrollToBottomDiv>
          <form
            className="flex items-center mt-2"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <input type="hidden" {...methods.register(`messageID`)} />
            <textarea
              onKeyPress={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  void methods.handleSubmit(onSubmit)();
                  methods.reset();
                }
              }}
              placeholder="Skriv dina anteckningar här"
              className="border p-2 rounded-lg mr-2 h-12 w-full bg-opacity-90"
              {...methods.register("msg_text")}
            />
            <FilledButton type="submit" content="Spara" size="xsmall" />
          </form>
          {error !== undefined && <div className="bg-medium-gray">{error}</div>}
        </div>
      )}
    </div>
  );
}

const ScrollToBottomDiv = ({
  children,
  className,
  scroll,
}: {
  children: ReactNode;
  className: string;
  scroll: boolean;
}): ReactElement => {
  const elementRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (elementRef.current != null && scroll) {
      elementRef.current.scrollTop = elementRef.current.scrollHeight;
    }
  }, [scroll]);
  return (
    <div ref={elementRef} className={className}>
      {children}
    </div>
  );
};
