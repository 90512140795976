import { useQuery } from "react-query";
import {
  CompletedUserModule,
  GetListResponse,
  Module,
  User,
} from "./../../api/types";
import { UseQueryResult } from "react-query";
import { fetchApiGet } from "../../api/api";
import { isNotEmpty } from "../../util";

export function useGetUserModuleOverViewUser(
  id: string
): UseQueryResult<User, Error> {
  return useQuery<User, Error>(
    ["users", id],
    () => fetchApiGet<User>(`users/${id}`),
    {
      staleTime: 0,
    }
  );
}

export function useGetUserModuleOverview(
  userId: string,
  moduleId: string
): UseQueryResult<Module> {
  return useQuery<Module, Error>(
    [userId, moduleId, "moduleLogs", "moduleProgresses", "messages"],
    () =>
      fetchApiGet<Module>(
        `modules/${moduleId}?forUserId=${userId}&expand=moduleLogs,moduleProgresses,messages`
      ),
    {
      staleTime: 0,
    }
  );
}

export function useGetCompletedModules(props?: {
  page?: string | null;
  perPage?: number;
}): UseQueryResult<GetListResponse<CompletedUserModule>, Error> {
  let key = ["module-progresses", "completed-modules"];
  let queryString = "?expand=user,module,feedbackMessage";

  if (props != null) {
    if (isNotEmpty(props.page)) {
      key = [...key, "page", props.page];
      queryString += "&page=" + props.page;
    }
    if (props.perPage != null) {
      queryString += `&per-page=${props.perPage}`;
      key = [...key, `perPage=${props.perPage}`];
    }
  }

  const path = "module-progresses/completed-modules" + queryString;
  return useQuery<GetListResponse<CompletedUserModule>, Error>(key, () =>
    fetchApiGet<GetListResponse<CompletedUserModule>>(path)
  );
}
