import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { useController, useForm, UseFormReturn } from "react-hook-form";
import { AnyObjectSchema } from "yup";
import { ModuleProgressData } from "../../api/form-types";
import { Module, ModuleProgress } from "../../api/types";
import useAutoSave from "../../hooks/UseAutoSave";
import { isEmpty } from "../../util";
import { FormCheckbox } from "../formLayout/FormChecbox";
import FormError from "../formLayout/FormError";
import { useIsReadOnly } from "./LectureModule/ReadOnyProvider";

export default function ModuleProgressChecked(props: {
  step: string;
  form: UseModuleProgressForm;
}): ReactElement {
  const controller = useController({
    control: props.form.methods.control,
    name: "mprog_completed",
  });
  const isFormReadOnly = useIsReadOnly();
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [showErrorText, setShowErrorText] = useState(false);

  if (!props.form.meetRequirement && controller.field.value) {
    controller.field.onChange(null);
  }

  const handleCheckedClick = (e: ChangeEvent<HTMLInputElement>): void => {
    controller.field.onChange(e.target.checked ? true : null);
    // console.log({ target: e.target.checked });
    props.form.methods.setValue(
      "mprog_completed",
      e.target.checked ? true : null
    );

    if (timer) {
      setTimer(undefined);
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        void props.form.saveModuleProgress();
      }, 300)
    );
  };

  useEffect(() => {
    if (showErrorText && props.form.meetRequirement) {
      setShowErrorText(false);
    }
  }, [props.form.meetRequirement, showErrorText]);

  return (
    <button
      disabled={isFormReadOnly}
      type="button"
      onClick={() =>
        !props.form.meetRequirement
          ? setShowErrorText(true)
          : setShowErrorText(false)
      }
      className={`
      w-full cursor-default
      ${
        !isFormReadOnly && props.form.meetRequirement
          ? "bg-mint-green bg-opacity-10  border-mint-green"
          : "bg-medium-gray border-blue-gray border-opacity-30"
      }
        ${showErrorText ? "border-red" : ""}
        flex justify-start align-center border-l-4 my-1 shadow rounded-sm p-4 mt-8`}
    >
      <FormCheckbox
        title="Genomförd"
        checked={controller.field.value ? true : false}
        onChange={handleCheckedClick}
        onBlur={controller.field.onBlur}
        ref={controller.field.ref}
        disabled={isFormReadOnly || !props.form.meetRequirement}
      />
      {showErrorText && props.step === "main_module_logs" && (
        <FormError
          className="pl-2 self-center"
          message="Fyll i minst en rad i läsloggen för att fortsätta."
        />
      )}
      {showErrorText && props.step === "m_lecture_video" && (
        <FormError
          className="pl-2 self-center"
          message="Spela filmen ända till slutet för att fortsätta."
        />
      )}
      {showErrorText && props.step === "m_analysis_text" && (
        <FormError
          className="pl-2 self-center"
          message="Skriv minst 500 tecken i dokumentationen för att fortsätta."
        />
      )}
    </button>
  );
}

interface UseModuleProgressForm {
  meetRequirement: boolean;
  setMeetRequirement: (isMet: boolean) => void;
  saveModuleProgress: () => Promise<void>;
  methods: UseFormReturn<ModuleProgressData>;
}

export function useModuleProgressForm(
  step: string,
  schema: AnyObjectSchema,
  module: Module,
  meetRequirements: boolean,
  progress?: ModuleProgress
): UseModuleProgressForm {
  const [meetRequirement, setMeetRequirement] = useState(meetRequirements);
  const methods = useForm<ModuleProgressData>({
    resolver: yupResolver(schema),
    defaultValues:
      progress != null
        ? {
            ...progress,
            module_progressID: progress.module_progressID.toString(),
            mprog_completed: progress.mprog_completed != null ? true : null,
          }
        : {
            module_progressID: `new-progress-${module.moduleID}`,
            mprog_moduleID: module.moduleID,
            mprog_step: step,
            mprog_completed: null,
          },
  });

  const autoSave = useAutoSave<ModuleProgress, ModuleProgressData>(
    "module-progresses",
    methods.getValues("module_progressID"),
    hasValueChanged
  );

  useEffect(() => {
    if (
      methods.getValues("module_progressID").includes("new") &&
      autoSave.lastSavedObject != null
    ) {
      methods.setValue(
        "module_progressID",
        autoSave.lastSavedObject.module_progressID.toString()
      );
    }
  }, [autoSave.lastSavedObject, methods]);

  const saveModuleProgress = async (): Promise<void> => {
    const moduleProgress = methods.getValues();
    const isValid = await methods.trigger();

    // console.log({ moduleProgress, isValid, errors: methods.formState.errors });
    if (isValid) {
      autoSave.setSaveObject(moduleProgress);
    }
  };

  return { setMeetRequirement, meetRequirement, methods, saveModuleProgress };
}

function hasValueChanged(
  data: ModuleProgressData,
  moduleProgress: ModuleProgress
): boolean {
  return !(
    (data.mprog_completed != null ? true : false) ===
      (moduleProgress.mprog_completed != null) &&
    ((data.mprog_data == null && moduleProgress.mprog_data == null) ||
      (data.mprog_data != null &&
        data.mprog_data.trim() === moduleProgress.mprog_data) ||
      (data.mprog_data != null &&
        isEmpty(data.mprog_data.trim()) &&
        moduleProgress.mprog_data == null))
  );
}
