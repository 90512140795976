import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useMemo } from "react";
import { isNotEmpty } from "../util";

interface AlertProps {
  message?: string;
  color?: "warning" | "error" | "success" | "primary";
  link?: string;
  linkText?: string;
}

export default function StaticAlertBar(props: AlertProps): ReactElement {
  const bgColor = useMemo((): string => {
    switch (props.color) {
      case "error":
      case "warning":
        return "bg-red";

      case "success":
        return "bg-fresh-mint";

      default:
        return "bg-ocean-blue bg-opacity-80";
    }
  }, [props.color]);

  return (
    <div
      className={`AlertBar w-full px-16 py-3 text-white text-center ${bgColor}`}
    >
      <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />

      <span className="text-white font-bold">{props.message}</span>

      {isNotEmpty(props.link) && (
        <button>{isNotEmpty(props.linkText) ? props.linkText : "OK"}</button>
      )}
    </div>
  );
}
