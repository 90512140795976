import { ReactElement } from "react";
import { UserRole } from "../../api/types";
import { useGetUserRoles } from "../../api/endpoints";

type UserRolesTableProps = {
  userRoles?: UserRole[];
};
export default function UserRolesTable({
  userRoles,
}: UserRolesTableProps): ReactElement {
  const roles = useGetUserRoles();

  return (
    <div>
      <table className="table w-full fsu-table">
        <thead>
          <tr className="">
            <th>Uppdragsgivare</th>
            <th>Organisation</th>
            <th>Roll</th>
          </tr>
        </thead>
        <tbody>
          {userRoles?.map((role, index) => (
            <tr key={`${role.role_name}-${index} `}>
              <td>{role.client?.cl_name}</td>
              <td>{role.organization?.org_name}</td>
              <td>{roles[role.role_name].label}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
