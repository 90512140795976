import { ReactElement, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Organization, User } from "../../api/types";
import useClientPagination from "../../hooks/useClientPagination";
import { isNotEmpty } from "../../util";
import { IconButtonLink } from "../Buttons";
import PaginationUI from "../layout/PaginationUI";
import ViewHeader from "../layout/ViewHeader";
import Search from "../Search";

interface OrgUsersTableProps {
  className?: string;
  users: User[] | undefined;
  organization: Organization;
}

export default function OrgUsersTable({
  className,
  users,
  organization,
}: OrgUsersTableProps): ReactElement {
  const history = useHistory();
  const [searchString, setSearchString] = useState("");

  const filteredUsers = useMemo(() => {
    if (users == null) return [];
    const filter = searchString.toLowerCase();
    const filteredUsers = users.filter((usr) =>
      usr.userRoles?.some((role) => role.role_name === "regUser")
    );

    return isNotEmpty(filter)
      ? filteredUsers.filter(
          (usr) =>
            usr.usr_firstname.toLowerCase().includes(filter) ||
            usr.usr_lastname.toLowerCase().toString().includes(filter) ||
            usr.usr_email.toLowerCase().toString().includes(filter)
        )
      : filteredUsers;
  }, [users, searchString]);

  const paginationProps = useClientPagination({
    totalCount: filteredUsers.length,
  });
  const currentPageItems = useMemo(() => {
    return filteredUsers.slice(
      paginationProps.fromItem - 1,
      paginationProps.toItem
    );
  }, [filteredUsers, paginationProps]);

  return (
    <div className={className}>
      <ViewHeader title={`Användare | ${filteredUsers.length}st`}>
        <div className="float-right inline-flex pr-4">
          {/* <IconButtonLink
            className="inline-block align-middle mr-4"
            content="Importera"
            onClick={() => history.push("/användare/importera")} // prepopulate form data with organization and regUser?
          /> */}
          <IconButtonLink
            className="inline-block align-middle "
            content="Lägg till användare"
            onClick={() =>
              history.push("/anvandare/ny", {
                role: "regUser",
                orgId: organization.organizationID,
              })
            }
          />
        </div>
      </ViewHeader>
      <div className="mt-4">
        <Search onSearch={setSearchString}></Search>

        <div className="filter med:flex w-full md:w-1/3">
          <div className="dropdown-licens"></div>
          <div className="dropdown-status"></div>
        </div>
      </div>
      <PaginationUI {...paginationProps}>
        <table className="table w-full fsu-table">
          <thead>
            <tr className="">
              <th>Namn</th>
              <th>E-post</th>
              <th>Status</th>
              <th>Åtgärder</th>
            </tr>
          </thead>
          <tbody>
            {currentPageItems.map((u: User) => (
              <tr key={u.usr_email}>
                <td>
                  <Link to={`/anvandare/${u.userID}`}>
                    {isNotEmpty(`${u.usr_firstname} ${u.usr_lastname}`.trim())
                      ? `${u.usr_firstname} ${u.usr_lastname}`
                      : u.usr_email}
                  </Link>
                </td>
                <td>{u.usr_email}</td>
                <td>{u.usr_status === "active" ? "Aktiv" : "Väntande"}</td>
                <td>
                  <Link to={`/anvandare/redigera/${u.userID}`}>Redigera</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </PaginationUI>
    </div>
  );
}
