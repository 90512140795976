import {
  faExternalLinkAlt,
  faFilePdf,
  faFilm,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useMemo } from "react";
import reactStringReplace from "react-string-replace";
import { Module } from "../../api/types";
import ReactDOMServer from "react-dom/server";

import { getMediaByRefAndType, isEmpty } from "../../util";
interface ExtraMaterialProps {
  module: Module;
}
export default function ExtraModuleMaterial({
  module,
}: ExtraMaterialProps): ReactElement {
  const extraMaterial = useMemo(
    () => getMediaByRefAndType(module.all_media, "extra_material", "document"),
    [module.all_media]
  );

  return (
    <div>
      {extraMaterial.length > 0 && (
        <div className="mt-8">
          <h4>Extramaterial</h4>
          <div>
            Här finns ytterligare stödmaterial för fördjupning av det aktuella
            kapitlet i form av länkar till filmer, poddar och artiklar med mera.
            Av upphovsrättsliga skäl kan jag tyvärr inte infoga utdrag från
            andra filmer i mina filmer. Därav finns länkarna här.
          </div>
          <div>{module.m_extra_material_text}</div>
          <div className="mt-8">
            <table className="table w-full fsu-table fsu-extra-material">
              <thead>
                <tr className="">
                  <th>Källa</th>
                  <th>Kommentar</th>
                </tr>
              </thead>
              <tbody>
                {extraMaterial.map((media) => (
                  <tr key={media.mediaID}>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: getParsedText(media.media_description),
                      }}
                    ></td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: getParsedText(media.media_comment),
                      }}
                    ></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

function getParsedText(text: string | null): string {
  if (isEmpty(text)) return "";
  const matches = text.match(/\[\[(.*?)\]\]/g);

  if (matches) {
    const reactNode = reactStringReplace(text, /\[\[(.*?)\]\]/g, (match, i) => {
      switch (match) {
        case "faLink":
          return ReactDOMServer.renderToString(
            <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" />
          );
        case "faPdf":
          return ReactDOMServer.renderToString(
            <FontAwesomeIcon icon={faFilePdf} />
          );
        case "faFilm":
          return ReactDOMServer.renderToString(
            <FontAwesomeIcon icon={faFilm} />
          );
        default:
          break;
      }
      return <></>;
    });
    text = reactNode.join("");
  }
  return text;
}
