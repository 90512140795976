import { ReactElement } from "react";
import { useParams } from "react-router";
import { Organization, User } from "../api/types";
import Header from "../components/layout/Header";
import ViewHeader from "../components/layout/ViewHeader";
import { OrgAdminsList } from "../components/OrgAdminList";
import OrgUsersTable from "../components/organizations/OrgUsersTable";
import Loader from "../components/layout/Loader";
import { SimpleLayout } from "../components/layout/SimpleLayout";
import { useGetViewOrganization } from "../api/endpoints";
import { ClientDetails } from "./ViewClient";

export default function ViewOrganization(): ReactElement {
  const params = useParams<{ id: string }>();
  const query = useGetViewOrganization(params.id);

  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      query.data.users?.map((usr) => {
        usr.userRoles = usr.userRoles?.filter(
          (role) => role.role_organizationID?.toString() === params.id
        );
        return usr;
      });

      return (
        <SimpleLayout>
          <h4 className="mb-2">Organisation:</h4>
          <Header title={query.data.org_name} />
          <div className="mt-4 mb-12">Status: Aktiv</div>

          <div>
            {/* <div>STATISTIK</div> */}

            <div className="mt-16 mb-8 flex">
              <div className="pr-10">
                <OrgAdminsList
                  users={query.data.users as User[]}
                  organization={query.data}
                />
                {query.data.client != null && (
                  <ClientDetails client={query.data.client} />
                )}
              </div>
              <OrgUsersTable
                className="flex-1"
                organization={query.data}
                users={query.data.users as User[]}
              />
            </div>
          </div>
        </SimpleLayout>
      );
  }
}

export function OrganizationDetails({
  organization,
  className,
}: {
  organization: Organization;
  className?: string;
}): ReactElement {
  return (
    <div className={`${className != null ? className : "mt-24"}`}>
      <ViewHeader title="Organisation"></ViewHeader>
      <div className="mt-8">
        <h5>{organization.org_name.toUpperCase()}</h5>
      </div>
    </div>
  );
}
