import { ReactElement, ReactNode } from "react";

export default function Header(props: {
  title: string;
  children?: ReactNode;
}): ReactElement {
  return (
    <div
      className={props.children != null ? `flex flex-col-2` : "flex flex-col"}
    >
      <h1 className="object-left inline pr-8 font-serif">{props.title}</h1>
      {props.children != null ? props.children : ""}
    </div>
  );
}
