import Login from "./pages/Login";
import PrivateRoute from "./routes/PrivateRoute";
import { PublicRoute } from "./routes/PublicRoute";
import Home from "./pages/Home";
import { Switch } from "react-router";
import { ReactElement, useEffect, useState } from "react";
import ForgotPassword from "./pages/ForgotPassword";
import CompleteAccount from "./pages/CompleteAccount";
import { useAuth } from "./hooks/ProvideAuth";
import SetNewPassword from "./pages/SetNewPassword";
import { ProvideConfirmDialog } from "./hooks/ProvideConfirmDialog";
import Loader from "./components/layout/Loader";
import CookieConsent from "react-cookie-consent";
import StaticAlertBar from "./components/StaticAlertBar";
import { isNotEmpty } from "./util";

export default function App(): ReactElement {
  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  useEffect(() => {
    if (auth?.isTokenRefreshing) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [auth?.isTokenRefreshing]);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <>
        {isNotEmpty(auth?.systemAlert) && (
          <StaticAlertBar message={auth?.systemAlert} />
        )}

        <Switch>
          <PublicRoute path="/logga-in" component={<Login />} />

          <PublicRoute
            path="/glomt-losenord"
            exact
            component={<ForgotPassword />}
          />
          <PublicRoute
            path="/nytt-losenord/:key"
            exact
            component={<SetNewPassword />}
          />
          <PublicRoute
            path="/fardigstall-konto/:key"
            exact
            component={<CompleteAccount />}
          />

          <PrivateRoute path="">
            <ProvideConfirmDialog>
              <Home />
            </ProvideConfirmDialog>
          </PrivateRoute>
        </Switch>
        <CookieConsent
          buttonStyle={{ background: "#ffffff" }}
          contentStyle={{ color: "#ffffff" }}
          style={{
            background: "#1c557f",
          }}
          overlay
          overlayStyle={{ background: "rgba(28,85,127, 0.3)" }}
          buttonText="Jag accepterar"
        >
          Förskolutveckling använder sig av cookies för att förbättra din
          användarupplevelse{" "}
          <a
            className="text-mint-green hover:text-dull-mint cursor-pointer"
            href={process.env.REACT_APP_INTEGRITY_POLICY_LINK}
            rel="noopener noreferrer"
            target="_blank"
          >
            Läs mer här
          </a>
        </CookieConsent>
      </>
    );
  }
}
