import { ReactElement } from "react";
import { useParams } from "react-router";
import ViewHeader from "../../components/layout/ViewHeader";
import Header from "../../components/layout/Header";
import Loader from "../../components/layout/Loader";
import { SimpleLayout } from "../../components/layout/SimpleLayout";
import UserModules from "./UserModules";
import UserRolesTable from "./UserRolesTable";
import UseQueryError from "../../components/UseQueryError";
import { useGetViewUser } from "./endpoints";

export default function ViewUser(): ReactElement {
  const params = useParams<{ id: string }>();
  const query = useGetViewUser(params.id);

  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return <UseQueryError />;
    case "success":
      return (
        <SimpleLayout>
          <h4 className="mb-2">Användare:</h4>
          <Header
            title={`${query.data.usr_firstname} ${query.data.usr_lastname}`}
          />
          <div className="mt-4">
            <div>
              {`Status: ${
                query.data.usr_status === "active" ? "Aktiv" : "Väntande"
              }`}
            </div>
            <div>{`E-post: ${query.data.usr_email}`}</div>
          </div>

          <div>
            <div className="mt-8 mb-12 flex">
              <div className="flex-1">
                <ViewHeader
                  title={`Roller | ${
                    query.data.userRoles != null
                      ? query.data.userRoles.length
                      : 0
                  }st`}
                  className="mb-5"
                ></ViewHeader>
                <UserRolesTable userRoles={query.data.userRoles} />
              </div>
            </div>
            <div>
              <UserModules userID={query.data.userID} />
            </div>
          </div>
        </SimpleLayout>
      );
  }
}
