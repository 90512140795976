import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, ReactNode } from "react";
import { PaginationButton } from "../Buttons";

export interface PaginationPage {
  key: string;
  activePage: string;
  pageNumber: number;
}
export interface PaginationProps {
  pages: PaginationPage[];
  currentPage: number;
  pageCount: number;
  fromItem: number;
  toItem: number;
  pagesToShow?: number;
  onPaginationClicked: (
    x: number | undefined,
    previousPage: number | undefined
  ) => void;
  children?: ReactNode;
}
/**
 * Pagination UI - Limited logic. Logic implented in useClientPagination or ServerSiderPagination
 * @param props PaginationProps
 * @returns
 */
export default function PaginationUI(props: PaginationProps): ReactElement {
  const pagesToShow = props.pagesToShow != null ? props.pagesToShow : 5;
  if (props.pages.length <= 1) {
    return <>{props.children}</>;
  }

  const element = (
    <div className="flex justify-between my-8">
      <div className="flex gap-x-0.5">
        <PaginationButton
          onClick={() =>
            props.onPaginationClicked(undefined, props.currentPage)
          }
        >
          <>
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
            <span className="ml-2">Första</span>
          </>
        </PaginationButton>
        {props.currentPage > 3 && props.pageCount > pagesToShow && "..."}

        {props.pages.map((page) => (
          <PaginationButton
            key={page.key}
            activepage={page.activePage}
            onClick={() =>
              props.onPaginationClicked(page.pageNumber, props.currentPage)
            }
          >
            {page.pageNumber}
          </PaginationButton>
        ))}

        {props.currentPage + 2 < props.pageCount &&
          props.pageCount > pagesToShow &&
          "..."}

        <PaginationButton
          onClick={() =>
            props.onPaginationClicked(props.pageCount, props.currentPage)
          }
        >
          <>
            <span className="mr-2">Sista</span>
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </>
        </PaginationButton>
      </div>
      <div className="font-bold">
        Visar resultat: {props.fromItem} - {props.toItem}
      </div>
    </div>
  );

  return (
    <>
      {element}
      {props.children}
      {element}
    </>
  );
}
