import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, ReactNode, useState } from "react";
import { isNotEmpty } from "../../util";

export default function FormCheckboxWrapper(props: {
  children: ReactNode;
  title?: string;
  className?: string;
  accordion?: boolean;
  noSelected?: number | null;
  default?: "expanded" | "closed";
}): ReactElement {
  const [expanded, setExpanded] = useState(
    props.default != null && props.default === "closed" ? false : true
  );

  let titleContent = <></>;
  if (props.title != null) {
    let noSelected = <></>;
    if (props.noSelected != null) {
      noSelected = <span className="ml-1">{`(${props.noSelected})`}</span>;
    }

    if (props.accordion) {
      titleContent = (
        <button
          className="flex flex-row w-fit pr-2"
          onClick={(e) => {
            e.preventDefault();
            setExpanded(!expanded);
          }}
        >
          <span className="w-6">
            {expanded ? (
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} size="xs" />
            )}
          </span>

          {props.title}
          {noSelected}
        </button>
      );
    } else {
      titleContent = (
        <>
          {props.title}
          {noSelected}
        </>
      );
    }
  }
  return (
    <div
      className={`checkboxlist ${
        isNotEmpty(props.className) ? props.className : ""
      }`}
    >
      {props.title != null && (
        <h5 className="border-b border-blue-gray text-blue-gray font-semibold mt-4 mb-4 pb-2">
          {titleContent}
        </h5>
      )}

      <div className={`${!expanded ? "hidden" : ""}`}>{props.children}</div>
    </div>
  );
}
