import { ReactElement, useMemo } from "react";
import { useHistory } from "react-router";
import { hasRole } from "../../api/Auth";
import { useGetLicenses } from "../../api/endpoints";
import { useAuth } from "../../hooks/ProvideAuth";
import { Button } from "../Buttons";
import { Course, License, ResourceCategory } from "../../api/types";
import Loader from "../../components/layout/Loader";

export function LicensesList(props: { clientId: string }): ReactElement {
  const history = useHistory();
  const query = useGetLicenses({ clientId: props.clientId });
  const auth = useAuth();

  const filteredLicenses = useMemo(
    () =>
      query.data?.items.filter(
        (licenses) =>
          (typeof props.clientId == "string" &&
            licenses.l_clientID.toString() === props.clientId) ||
          (typeof props.clientId == "number" &&
            licenses.l_clientID === props.clientId)
      ),
    [query, props.clientId]
  );

  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      return (
        <div>
          {filteredLicenses && filteredLicenses.length > 0 ? (
            <div className="flex flex-row mb-8">
              <h2 className="mr-4">Tillgängliga Licenser</h2>
              {hasRole(auth?.userRoles != null ? auth.userRoles : [], [
                "admin",
              ]) && (
                <Button
                  content="Redigera"
                  size="small"
                  onClick={() =>
                    history.push(`/uppdragsgivare/redigera/${props.clientId}`)
                  }
                />
              )}
            </div>
          ) : (
            <>
              <h2 className="mr-4 mb-4">Tillgängliga Licenser</h2>
              <span className="mr-4">Inga Tillgängliga Licenser</span>
              {hasRole(auth?.userRoles != null ? auth.userRoles : [], [
                "admin",
              ]) && (
                <Button
                  content="Lägg till licenser"
                  size="xsmall"
                  onClick={() =>
                    history.push(`/uppdragsgivare/redigera/${props.clientId}`)
                  }
                />
              )}
            </>
          )}
          <div className="min-w-screen grid grid-cols-1 gap-x-28 gap-y-16">
            {filteredLicenses?.map(
              (l: License) =>
                filteredLicenses.length > 0 && (
                  <LicenseListItem license={l} key={l.licenseID} />
                )
            )}
          </div>
        </div>
      );
  }
}

function LicenseListItem(props: { license: License }): ReactElement {
  const l = props.license;
  const courses = l.courses == null ? [] : l.courses;
  const resourceCategories =
    l.resourceCategories != null ? l.resourceCategories : [];
  return (
    <div className="border-l-4 bg-medium-gray">
      <div className="p-4 pl-6 bg-white">
        <h4 className="mb-0 text-xl">
          Licens {l.l_start_date.substr(2, 2)}-{l.l_end_date.substr(2, 2)}
        </h4>
        <div className="">
          {l.l_start_date} till {l.l_end_date}
        </div>
      </div>
      <div className="pt-7 w-full">
        {courses.map((c: Course) => (
          <CourseListItem course={c} key={c.courseID} />
        ))}
        {resourceCategories.map((rc: ResourceCategory) => (
          <ResourceCatListItem resourceCat={rc} key={rc.resource_CategoryID} />
        ))}
      </div>
    </div>
  );
}

function CourseListItem(props: { course: Course }): ReactElement {
  const c = props.course;
  const secondColumnIndex = useMemo(
    () => Math.ceil((c.modules != null ? c.modules.length : 0) / 2),
    [c.modules]
  );
  return (
    <div className="pl-6 p-7 pt-0">
      <span className="font-bold text-xl text-blue-gray">{c.crs_title}</span>
      <div className={`grid grid-cols-2 grid-flow-row-dense gap-x-7`}>
        {c.modules?.map((m, mIndex) => (
          <div
            className={`py-1 text-md font-normal ${
              secondColumnIndex > 0 && mIndex >= secondColumnIndex
                ? "col-start-2"
                : "col-start-1"
            }`}
            key={m.moduleID}
          >
            {m.m_number > 0 ? `${m.m_number}. ` : ""}
            {`${m.m_title}`}
          </div>
        ))}
      </div>
    </div>
  );
}

function ResourceCatListItem({
  resourceCat,
}: {
  resourceCat: ResourceCategory;
}): ReactElement {
  const secondColumnIndex = useMemo(
    () =>
      Math.ceil(
        (resourceCat.resources != null ? resourceCat.resources.length : 0) / 2
      ),
    [resourceCat.resources]
  );
  return (
    <div className="pl-6 p-7 pt-0">
      <span className="font-bold text-xl text-blue-gray">
        {resourceCat.rc_title}
      </span>
      <div className="grid grid-cols-2 grid-flow-row-dense gap-x-7">
        {resourceCat.resources?.map((r, mIndex) => (
          <div
            className={`py-1 text-md font-normal ${
              secondColumnIndex > 0 && mIndex >= secondColumnIndex
                ? "col-start-2"
                : "col-start-1"
            }`}
            key={r.resourceID}
          >
            {`${r.rsc_title}`}
          </div>
        ))}
      </div>
    </div>
  );
}
