import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryCache = new QueryCache();
const mutationCache = new MutationCache();

const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 2, // 2 min
      cacheTime: 1000 * 60 * 60 * 4, // 4 hours
    },
  },
});

// Options here: https://react-query.tanstack.com/reference/useQuery
// Important ones here: https://react-query.tanstack.com/guides/important-defaults
// https://react-query.tanstack.com/plugins/createWebStoragePersistor

interface Props {
  children: React.ReactNode;
}

export default function QueryProvider({ children }: Props): React.ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
