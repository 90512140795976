import { ReactElement } from "react";
import { Capabilities } from "../../hooks/ProvideAuth";
import Menu from "./Menu";
import MenuItem from "./MenuItem";
import MenuSection from "./MenuSection";
import NoticesMenuItem from "./NoticesMenuItem";
import ResourceMenutItems from "./ResourceMenuItems";

export default function AdminMenu(): ReactElement {
  return (
    <Menu>
      <MenuSection>
        <MenuItem path="/">Hem</MenuItem>
        <MenuItem path="/uppdragsgivare">Uppdragsgivare</MenuItem>
        <MenuItem path="/organisationer">Organisationer</MenuItem>
        <MenuItem path="/anvandare">Användare</MenuItem>

        <MenuItem
          path="/genomforda-moduler"
          capabilities={[
            Capabilities.view_org_routes,
            Capabilities.view_courses,
          ]}
        >
          Genomförda moduler
        </MenuItem>
      </MenuSection>
      <MenuSection>
        <NoticesMenuItem />

        <MenuItem path="/moduler" capabilities={[Capabilities.view_courses]}>
          Moduler
        </MenuItem>
        <MenuItem path="/laslogg" capabilities={[Capabilities.view_courses]}>
          Läsloggar
        </MenuItem>

        <ResourceMenutItems />
      </MenuSection>
      <MenuSection>
        <MenuItem path="/mitt-konto">Mitt konto</MenuItem>
      </MenuSection>
    </Menu>
  );
}
