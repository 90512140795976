import { ReactElement } from "react";
import { useGetUserDocuments } from "../../api/endpoints";
import Loader from "../../components/layout/Loader";
import LinkToFile from "../../components/LinkToFile";
import { isNotEmpty } from "../../util";

export function UserDocumentsList({
  userId,
  moduleId,
}: {
  userId: string;
  moduleId: number;
}): ReactElement {
  const query = useGetUserDocuments({
    filter: {
      udoc_userID: userId,
      udoc_type: "RAD",
      udoc_moduleID: moduleId.toString(),
    },
    options: {
      staleTime: 0,
    },
  });

  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      return (
        <div className="flex flex-col border gap-8 bg-white relative p-8">
          <div className="w-auto flex-1 flex flex-col divide-dark-gray divide-y">
            {query.data.items.length > 0 ? (
              query.data.items.map((ud) => (
                <div
                  key={`${ud.udoc_userID}-${ud.udoc_type}-${ud.udoc_moduleID}-${ud.udoc_filename}`}
                  className="flex flex-row relative w-full p-4 pl-0"
                >
                  <div className="mr-8">
                    <div
                      className={`bg-soft-mint h-20 w-20 rounded flex items-center justify-center`}
                    >
                      <span className="text-white font-bold self-center text-center lowercase">
                        {ud.udoc_extension}
                      </span>
                    </div>
                  </div>
                  <div className="flex-auto flex-row">
                    <div className="font-bold text-blue-gray">
                      <LinkToFile
                        path={`${
                          isNotEmpty(process.env.REACT_APP_PERSISTENTDATA_URL)
                            ? process.env.REACT_APP_PERSISTENTDATA_URL
                            : ""
                        }${ud.udoc_url}`}
                        displayName={ud.udoc_filename}
                        displayIcon={false}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="">Inga dokument uppladdade</div>
            )}
          </div>
        </div>
      );
  }
}
