import { ReactElement } from "react";
import { Button } from "../Buttons";

export default function EditSubtitle(props: {
  deletedButtonText?: string;
  onDeletedClicked: () => void;
}): ReactElement {
  return (
    <div className="flex flex-row min-w-screen">
      <h4 className="mb-2">Redigera</h4>
      <Button
        className="self-start ml-2 py-1 px-2"
        content={
          props.deletedButtonText != null ? props.deletedButtonText : "Ta bort"
        }
        size="xsmall"
        onClick={props.onDeletedClicked}
      />
    </div>
  );
}
