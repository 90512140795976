import React, {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import { refreshToken } from "../api/api";
import { getUserRoleCapabilities } from "../api/Auth";
import {
  EnabledLicenseParts,
  LoginResponse,
  User,
  UserRole,
} from "../api/types";
import inMemoryToken from "../hooks/TokenManager";
import { isEmpty, isNotEmpty } from "../util";

export interface AuthProps {
  systemAlert: string | undefined;
  user: User | null;
  userCapabilities: Capabilities[];
  userRoles: UserRole[];
  isTokenRefreshing: boolean;
  highestRole: string;
  updateUser(user: User): void;
  signin(props: SigninProps): void;
  signout(): void;
  setSystemAlert(alert: string | undefined): void;
}

export interface SigninProps {
  user: User;
  userRoles: UserRole[];
  enabledLicenseParts?: EnabledLicenseParts;
}
export enum Capabilities {
  view_client_routes,
  view_org_routes,
  view_user_routes,

  manage_notices,

  view_resources,
  view_courses,
}

/**
 * Export AuthContext
 */
export const AuthContext = React.createContext<AuthProps | null>(null);
export function useAuth(): AuthProps | null {
  return useContext(AuthContext);
}

/**
 *
 * @param props { children : ReactNode}
 * return ReactElement <AuthContext.Provider>
 */
export function ProvideAuth(props: { children: ReactNode }): ReactElement {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  );
}

/**
 * Handle authorized user
 */
function useProvideAuth(): AuthProps {
  const queryQlient = useQueryClient();
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const [userCapabilities, setUserCapabilities] = useState<Capabilities[]>([]);
  const [systemAlert, setSystemAlert] = useState<string | undefined>();

  const [user, setUser] = useState<User | null>(null);
  const [isTokenRefreshing, setIsTokenRefreshing] = useState(true);

  const [highestRole, setHighestRole] = useState<string>("");

  useEffect(() => {
    if (user === null && isEmpty(inMemoryToken.getToken())) {
      setIsTokenRefreshing(true);
      const autoAuth = async (): Promise<void> => {
        await refreshToken<LoginResponse>()
          .then((res) => {
            if (
              res.status === "ok" &&
              isNotEmpty(res.token) &&
              res.user != null &&
              res.userRoles != null
            ) {
              inMemoryToken.setToken(res.token);
              signin({
                user: res.user,
                userRoles: res.userRoles,
                enabledLicenseParts: res.enabledLicenseParts,
              });
              setSystemAlert(res.systemAlert);
            }
            setIsTokenRefreshing(false);
          })
          .catch((error) => {
            console.log(error);
            setIsTokenRefreshing(false);
          });
      };

      void autoAuth();
    }
  }, [user]);

  const signin = (props: SigninProps): void => {
    setUserRoles(props.userRoles);
    setUser(props.user);
    const highestRole = getSortedRolesByPower(props.userRoles)[0];
    setHighestRole(highestRole);

    const capabilities = getUserRoleCapabilities(highestRole);

    // License capabilities
    if (props.enabledLicenseParts != null) {
      if (props.enabledLicenseParts.courses) {
        capabilities.push(Capabilities.view_courses);
      }
      if (props.enabledLicenseParts.resourceCategories) {
        capabilities.push(Capabilities.view_resources);
      }
    }

    setUserCapabilities(capabilities);
  };

  const signout = (): void => {
    inMemoryToken.ereaseToken();
    void queryQlient.removeQueries();
    setUser(null);
    setUserRoles([]);
    setUserCapabilities([]);
    setHighestRole("");
  };
  const updateUser = (user: User): void => {
    setUser(user);
  };

  return {
    systemAlert,
    user,
    userRoles,
    userCapabilities,
    isTokenRefreshing,
    highestRole,
    updateUser,
    signin,
    signout,
    setSystemAlert,
  };
}

/**
 *
 * @param userRoles
 */
function getSortedRolesByPower(userRoles: UserRole[]): string[] {
  const map: { [key: string]: number } = {};
  const roles =
    process.env.REACT_APP_ROLES != null
      ? process.env.REACT_APP_ROLES.split(",")
      : [];
  roles.map((r, index) => (map[r] = index));

  return userRoles
    .map((ur) => ur.role_name)
    .sort((a, b) => {
      if (map[a] < map[b]) {
        return -1;
      }

      if (map[a] > map[b]) {
        return 1;
      }

      return 0;
    });
}
