import { ReactElement, useMemo } from "react";
import { useAuth } from "../../hooks/ProvideAuth";
import { useGetNewNotices } from "../../pages/Communication/endpoints";
import MenuItem from "./MenuItem";
import MenuItemIndicator from "./MenuItemIndicator";

export default function NoticesMenuItem(): ReactElement {
  const query = useGetNewNotices();
  const auth = useAuth();
  const indicator = useMemo(() => {
    switch (query.status) {
      case "success":
        return (
          <MenuItemIndicator
            itemsCount={
              query.data.items.filter(
                (notice) =>
                  notice.msg_type === "NTC" &&
                  notice.msg_userID !== auth?.user?.userID
              ).length
            }
          />
        );

      default:
        return <></>;
    }
  }, [query.data, query.status, auth?.user]);

  return (
    <MenuItem path="/notiser">
      Notiser
      {indicator}
    </MenuItem>
  );
}
