import { useQuery, UseQueryResult } from "react-query";
import { fetchApiGet } from "../../api/api";
import { GetListResponse, Organization } from "../../api/types";
import { isNotEmpty } from "../../util";

interface EndpointProps {
  page?: string | null;
  filter?: string;
  perPage?: number;
}
export function useGetOrganizations(
  props?: EndpointProps
): UseQueryResult<GetListResponse<Organization>, Error> {
  let key = ["organizations"];

  let queryString = "?expand=client";
  if (props != null) {
    if (isNotEmpty(props.page)) {
      key = [...key, "page", props.page];
      queryString += "&page=" + props.page;
    }

    let filterString = "";
    if (props.filter != null) {
      if (isNotEmpty(props.filter)) {
        key = [...key, "filter", props.filter];
        filterString += "&filter[or][0][org_name][like]=" + props.filter;
        filterString += "&filter[or][1][org_number][like]=" + props.filter;
        const isId = parseInt(props.filter);
        if (!isNaN(isId)) {
          filterString += "&filter[or][2][organizationID]=" + props.filter;
        }
      }
      queryString += filterString;
    }

    if (props.perPage != null) {
      queryString += `&per-page=${props.perPage}`;
      key = [...key, `perPage=${props.perPage}`];
    }
  }

  const path = "organizations" + queryString;
  return useQuery<GetListResponse<Organization>, Error>(key, () =>
    fetchApiGet<GetListResponse<Organization>>(path)
  );
}
