import { yupResolver } from "@hookform/resolvers/yup";
import { ReactElement, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { postData } from "../../api/endpoints";
import { Notice, PostResponse } from "../../api/types";
import Header from "../../components/layout/Header";
import { SimpleLayout } from "../../components/layout/SimpleLayout";
import { useAuth } from "../../hooks/ProvideAuth";
import { getValidationGenericErrorMessages, setFormErrors } from "../../util";
import NoticeForm, { NoticeData, schema } from "./NoticeForm";

export default function AddNotice(): ReactElement {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const history = useHistory();

  const [serverErrors, setServerErrors] = useState<string[]>([]);

  const mutation = useMutation((noticeData: string) =>
    postData<Notice | PostResponse>("messages", noticeData)
  );
  const methods = useForm<NoticeData>({
    defaultValues: {
      msg_type: "NTC",
      msg_text: "",
      msg_mediaID: null,
      msg_moduleID: null,
      msg_userID: auth?.user != null ? auth.user.userID : 0,
    },
    resolver: yupResolver(schema),
  });

  const handleButtonSubmit = async (
    formData: FieldValues,
    e?: React.BaseSyntheticEvent
  ): Promise<void> => {
    await mutation
      .mutateAsync(JSON.stringify(formData))
      .then((res) => {
        if ("errorsItemized" in res) {
          setFormErrors<NoticeData>(res.errorsItemized, methods);
          setServerErrors(
            getValidationGenericErrorMessages(res.errorsItemized)
          );
        } else {
          void queryClient.invalidateQueries("messages");
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);

        // TODO: HANDLE ERRORS
        // Set server errors like this
        // methods.setError(f, { message: m });
      });
  };

  return (
    <SimpleLayout>
      <Header title="Skicka ny notis" />
      <NoticeForm
        methods={methods}
        serverErrors={serverErrors}
        onFormSubmit={handleButtonSubmit}
        submitBtnText="Skicka notis"
      />
    </SimpleLayout>
  );
}
