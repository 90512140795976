import { ReactElement, ReactNode } from "react";
import Footer from "./Footer";

export function SimpleLayout({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  return (
    <div className="flex-1 min-h-screen flex flex-col justify-between">
      <div className="mx-16 mt-10 mb-24">{children}</div>
      <Footer />
    </div>
  );
}
