import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, ReactNode } from "react";
import { isNotEmpty } from "../util";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  content: ReactNode;
  icon?: IconDefinition;
  width?: string;
  size?: "xsmall" | "small" | "medium" | "large";
  className?: string;
}

export function Button(props: ButtonProps): ReactElement {
  const buttonWidth = isNotEmpty(props.width) ? props.width : "";
  const buttonSize = getButtonSize(props.size);
  return (
    <button
      {...props}
      className={`
      btn-empty border border-ocean-blue rounded-full uppercase ${buttonWidth} ${buttonSize} font-medium
      ${props.className != null ? props.className : ""}
      `}
    >
      {props.icon ? <FontAwesomeIcon icon={props.icon} className="mr-2" /> : ""}
      {props.content}
    </button>
  );
}

export function FilledButton(props: ButtonProps): ReactElement {
  const buttonWidth = isNotEmpty(props.width) ? props.width : "";
  const buttonSize = getButtonSize(props.size);
  const buttonColor = "bg-ocean-blue";
  const textColor = "text-white";

  return (
    <button
      {...props}
      className={`

      btn-full border border-ocean-blue ${buttonColor} ${textColor} rounded-full uppercase ${buttonWidth} ${buttonSize} font-medium
      ${props.className != null ? props.className : ""} `}
    >
      {props.icon ? <FontAwesomeIcon icon={props.icon} className="mr-2" /> : ""}
      {props.content}
    </button>
  );
}

export function IconButtonLink(props: ButtonProps): ReactElement {
  return (
    <button
      {...props}
      className={`icon-button-link ${
        isNotEmpty(props.className) ? props.className : ""
      }`}
    >
      <FontAwesomeIcon
        icon={props.icon != null ? props.icon : faPlusCircle}
        className="mr-2"
      />
      {props.content}
    </button>
  );
}

interface PaginationButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  activepage?: string;
}
export function PaginationButton(props: PaginationButtonProps): ReactElement {
  const size = getButtonSize("small");

  if (props.activepage != null && props.activepage === "true") {
    return (
      <button
        {...props}
        className={`pagination-btn btn-full border border-ocean-blue uppercase font-bold bg-ocean-blue text-white ${size}`}
      >
        {props.children}
      </button>
    );
  } else {
    return (
      <button
        {...props}
        className={`pagination-btn btn-full border border-ocean-blue uppercase ${size}`}
      >
        {props.children}
      </button>
    );
  }
}

function getButtonSize(size?: "xsmall" | "small" | "medium" | "large"): string {
  switch (size) {
    case "xsmall":
      return "py-0.5 px-3 text-xs";
    case "small":
      return "py-1 px-3 text-sm self-end";
    case "medium":
      return "py-2 px-6 text-sm self-end";
    default:
      return "py-3 px-8";
  }
}
