import { ReactElement, useEffect, useState } from "react";
import { Button, FilledButton } from "../Buttons";
import { AppendFormFieldButton } from "../formLayout/FormFieldArraySection";
import { TextareaInput, TextareaWrapper } from "../formLayout/FormTextarea";
import { MessageData } from "../../api/form-types";
import { deleteData } from "../../api/endpoints";
import useAutoSave from "../../hooks/UseAutoSave";
import { useForm } from "react-hook-form";
import { Message, Module } from "../../api/types";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// https://codesandbox.io/s/react-hook-form-field-array-advanced-with-delete-insert-append-edit-l19pz?file=/src/index.js:1526-1532

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIsReadOnly } from "./LectureModule/ReadOnyProvider";
import ModuleInputWrapper from "./ModuleInputWrapper/ModuleInputWrapper";
import ModuleInputHeader from "./ModuleInputWrapper/ModuleInputHeader";
const schema = yup.object({
  msg_text: yup.string(),
});

export default function ModuleLogPropositionForm(props: {
  module: Module;
}): ReactElement {
  const isFormReadOnly = useIsReadOnly();
  const values = props.module.messages
    ?.filter((m) => m.msg_type === "LPR")
    .map((msg, index) => ({
      key: `${props.module.moduleID}-${index}`,
      message: {
        msg_type: msg.msg_type,
        msg_text: msg.msg_text,
        msg_mediaID: msg.msg_mediaID,
        msg_moduleID:
          msg.msg_moduleID == null ? props.module.moduleID : msg.msg_moduleID,
        messageID: msg.messageID.toString(),
      },
    }));

  // const auth = useAuth();
  const [propositions, setPropositions] = useState<
    { key: string; message: MessageData }[]
  >(values != null ? values : []);
  const [newPropositionIndex, setNewPropositionIndex] = useState<number>(
    values != null ? values.length + 1 : 1
  );
  const defaultPropositionValue = {
    msg_type: "LPR",
    msg_text: "",
    msg_mediaID: null,
    msg_moduleID: props.module.moduleID,
  };

  const addProposition = (): void => {
    const keyValue = `${props.module.moduleID}-new-${newPropositionIndex}`;
    setPropositions([
      ...propositions,
      {
        key: `${props.module.moduleID}-${newPropositionIndex}`,
        message: {
          ...defaultPropositionValue,
          messageID: keyValue,
        },
      },
    ]);
    setNewPropositionIndex(newPropositionIndex + 1);
  };

  const updateProposition = (key: string, newPropositionId: string): void => {
    const index = propositions.findIndex((msg) => msg.key === key);
    propositions[index].message.messageID = newPropositionId;
    setPropositions([...propositions]);
  };

  const removeModuleLog = (index: number): void => {
    setPropositions([
      ...propositions.slice(0, index),
      ...propositions.slice(index + 1),
    ]);
  };

  const handleRemoveProposition = async (key: string): Promise<void> => {
    const index = propositions.findIndex((msg) => msg.key === key);

    if (!propositions[index].message.messageID.includes("new")) {
      await deleteData(`messages/${propositions[index].message.messageID}`)
        .then(() => {
          removeModuleLog(index);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      removeModuleLog(index);
    }
  };

  if (propositions.length < 3) {
    addProposition();
  }

  return (
    <ModuleInputWrapper>
      <ModuleInputHeader title="Frågeställning" />

      <div className="divide-y border">
        {propositions.map((proposition, index) => (
          <PropositionForm
            key={proposition.key}
            index={index}
            proposition={proposition.message}
            onRemoveLog={() => handleRemoveProposition(proposition.key)}
            onSaveLog={(newId: string) =>
              updateProposition(proposition.key, newId)
            }
          />
        ))}
      </div>
      <AppendFormFieldButton
        className="mt-4"
        onButtonClick={addProposition}
        disabled={isFormReadOnly}
      >
        <span className="font-semibold">Ny rad</span>
      </AppendFormFieldButton>
    </ModuleInputWrapper>
  );
}

function hasUnsavedChanges(data: MessageData, proposition: Message): boolean {
  return !(data.msg_text.trim() === proposition.msg_text);
}

function PropositionForm(props: {
  index: number;
  proposition: MessageData;
  onRemoveLog: () => void;
  onSaveLog: (newId: string) => void;
}): ReactElement {
  const formIsReadOnly = useIsReadOnly();
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [confirmRemoveLog, setConfirmRemoveLog] = useState<boolean>(false);

  const autoSave = useAutoSave<Message, MessageData>(
    "messages",
    props.proposition.messageID,
    hasUnsavedChanges
  );

  const methods = useForm<MessageData>({
    mode: "onChange",
    defaultValues: props.proposition,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (
      props.proposition.messageID.includes("new") &&
      autoSave.lastSavedObject != null
    ) {
      props.onSaveLog(autoSave.lastSavedObject.messageID.toString());
    }
  }, [autoSave.lastSavedObject, props]);

  const handleChange = (): void => {
    if (timer) {
      setTimer(undefined);
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        void methods.trigger().then((isValid) => {
          const values = methods.getValues();
          if (isValid) {
            autoSave.setSaveObject(values);
          }
        });
      }, 1000)
    );
  };

  return (
    <form onChange={handleChange}>
      <div className="grid h-28 relative">
        <div className="flex">
          <div className="flex items-center bg-ocean-blue bg-opacity-10 text-center text-xl">
            <span className="px-1.5 font-bold opacity-40">
              {props.index + 1}
            </span>
          </div>

          <TextareaWrapper>
            <TextareaInput
              {...methods.register(`msg_text`)}
              disabled={formIsReadOnly}
            />
            <div className="absolute bottom-0 left-0 pl-2 w-full">
              {methods.formState.errors.msg_text?.message}
            </div>
          </TextareaWrapper>
          <button
            disabled={formIsReadOnly}
            type="button"
            className="x-button flex items-center bg-ocean-blue-dark relative bg-opacity-10 hover:bg-opacity-20 transition"
            onClick={() => setConfirmRemoveLog(true)}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
          {confirmRemoveLog && (
            <div className="absolute inset-0 bg-ocean-blue-dark bg-opacity-50 flex items-center justify-center">
              <div className="bg-white w-80 p-4 z-50 border rounded-sm border-blue-gray">
                <div className="font-semibold text-center mb-2">
                  Är du säker på att du vill ta bort raden?
                </div>
                <div className="flex justify-center space-x-2">
                  <FilledButton
                    type="button"
                    width="w-20"
                    size="small"
                    content="Ja"
                    onClick={() => props.onRemoveLog()}
                  />
                  <Button
                    type="button"
                    size="small"
                    width="w-20"
                    content="Nej"
                    onClick={() => setConfirmRemoveLog(false)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
}
