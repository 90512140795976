import { ReactElement } from "react";
import { Redirect, useParams } from "react-router";
import { Media, Resource } from "../../api/types";
import Loader from "../../components/layout/Loader";
import ModuleLayout from "../../components/layout/ModuleLayout";
import TopImage from "../../components/layout/TopImage";
import LinkToFile from "../../components/LinkToFile";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import { isNotEmpty } from "../../util";
import { useGetResource } from "./resource-endpoints";

export default function ViewExtraResource(): ReactElement {
  const params = useParams<{ slug: string; id: string }>();
  const query = useGetResource(params.id);
  const moduleTopImage = "/module-log.jpg";
  let resource: Resource;

  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      if (query.error.statusCode === 403) {
        return (
          <Redirect
            to={{
              pathname: `/resurser/${params.slug}`,
              state: {
                alert: {
                  message: "Du har inte tillgång till detta.",
                  color: "error",
                  link: undefined,
                  timeoutLength: 5000,
                },
              },
            }}
          />
        );
      }
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      resource = query.data;
      return (
        <ModuleLayout>
          <TopImage imagePath={moduleTopImage} />
          <div className="module-content-wrapper">
            <div className="mb-12">
              <h1>{resource.rsc_title}</h1>
              {isNotEmpty(resource.rsc_description) && (
                <p className="mt-6">{resource.rsc_description}</p>
              )}
              <div className="mt-8">
                {resource.media == null ? (
                  <p>Ingen media hittad...</p>
                ) : (
                  resource.media.map((m) => (
                    <ResourceMedia key={m.mediaID} media={m} />
                  ))
                )}
              </div>
            </div>
          </div>
        </ModuleLayout>
      );
  }
}

interface ResourceMediaProps {
  media: Media;
}
function ResourceMedia({ media }: ResourceMediaProps): ReactElement {
  let mediaContent: ReactElement;

  switch (media.media_type) {
    case "audio":
      mediaContent = (
        <div className="">
          <audio controls src={media.media_url}>
            <track kind="captions" />
            Your browser does not support the audio tag.
          </audio>
        </div>
      );
      break;
    case "document":
      mediaContent = (
        <LinkToFile
          key={media.mediaID}
          path={media.media_url}
          displayName={media.media_title}
        />
      );
      break;
    case "video":
      mediaContent = (
        <VideoPlayer
          key={media.mediaID}
          videoUrl={media.media_url}
          posterUrl={
            isNotEmpty(media.media_thumbnail_url) &&
            media.media_thumbnail_url.includes("http")
              ? media.media_thumbnail_url
              : process.env.PUBLIC_URL + media.media_thumbnail_url
          }
        />
      );
      break;
    default:
      mediaContent = <div></div>;
      break;
  }
  return (
    <div className="mt-12">
      {media.media_type !== "document" && isNotEmpty(media.media_title) && (
        <h4>{media.media_title}</h4>
      )}
      {isNotEmpty(media.media_description) && (
        <span
          dangerouslySetInnerHTML={{
            __html: media.media_description,
          }}
        ></span>
      )}
      {mediaContent}
    </div>
  );
}
