import { faChalkboardTeacher } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement } from "react";
import { Redirect, useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useGetResourceCatergories } from "../../api/endpoints";
import { Resource, ResourceCategory } from "../../api/types";
import { Button } from "../../components/Buttons";
import Loader from "../../components/layout/Loader";
import ModuleLayout from "../../components/layout/ModuleLayout";
import TopImage from "../../components/layout/TopImage";
import { isEmpty, isNotEmpty } from "../../util";

export default function ExtraResources(): ReactElement {
  const history = useHistory();
  const params = useParams<{ slug: string }>();
  const catQuery = useGetResourceCatergories({
    filter: { slug: params.slug },
    expand: "resources",
  });
  let resourceCat: ResourceCategory;

  const moduleTopImage = "/module-log.jpg";
  switch (catQuery.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    case "success":
      if (catQuery.data._meta.totalCount < 1) {
        return (
          <Redirect
            to={{
              pathname: "/",
              state: {
                alert: {
                  message: "Du har inte tillgång till detta.",
                  color: "error",
                  link: undefined,
                  timeoutLength: 5000,
                },
              },
            }}
          />
        );
      } else {
        resourceCat = catQuery.data.items[0];

        return (
          <ModuleLayout>
            <TopImage imagePath={moduleTopImage} />
            <div className="module-content-wrapper">
              <div className="mb-12">
                <h1 className="mb-10">{resourceCat.rc_title}</h1>
                <div className="mb-10 grid grid-rows-1 gap-12 sm:grid-cols-2 sm:gap-10 md:grid-cols-3 2xl:grid-cols-4 ">
                  {resourceCat.resources?.map((r: Resource) => (
                    <div key={r.resourceID}>
                      <div>
                        <Link
                          to={`/resurser/${resourceCat.rc_slug}/${r.resourceID}`}
                        >
                          <div className="relative">
                            {isNotEmpty(r.rsc_thumbnail) ? (
                              <img
                                className="w-full border border-dark-gray h-40 bg-soft-mint bg-opacity-60"
                                src={
                                  isNotEmpty(r.rsc_thumbnail)
                                    ? r.rsc_thumbnail
                                    : ""
                                }
                                alt={r.rsc_title}
                              />
                            ) : (
                              <div className="w-full h-40 bg-soft-mint bg-opacity-60"></div>
                            )}
                            {isEmpty(r.rsc_thumbnail) && (
                              <span className="absolute inset-0 flex items-center justify-center">
                                <FontAwesomeIcon
                                  icon={faChalkboardTeacher}
                                  size="3x"
                                />
                              </span>
                            )}
                          </div>
                        </Link>
                      </div>
                      <div>
                        <h4 className="mt-4 mb-0">{r.rsc_title}</h4>
                        <p
                          className="mb-4"
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {r.rsc_description}
                        </p>
                        <Button
                          size="small"
                          content="Öppna"
                          onClick={() =>
                            history.push(
                              `/resurser/${resourceCat.rc_slug}/${r.resourceID}`
                            )
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </ModuleLayout>
        );
      }
  }
}
