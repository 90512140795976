import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, InputHTMLAttributes, ReactElement, Ref } from "react";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  title: string;
  pointer?: string;
}

/**
 * TODO: Not working here, look at code in LicensesFormSection
 * @param props
 */
export const FormCheckbox = forwardRef(
  (props: CheckboxProps, ref: Ref<HTMLInputElement>): ReactElement => {
    return (
      <label
        className={`form-checkbox block pl-8 relative pr-2 flex align-center ${
          props.disabled ? "cursor-help" : "cursor-pointer"
        }`}
      >
        <input type="checkbox" {...props} {...ref} />
        {props.title}
        <span className="fancy-checkbox block">
          <span className="fc-icon hidden block leading-none">
            <FontAwesomeIcon icon={faCheckCircle} className="text-blue-gray" />
          </span>
        </span>
      </label>
    );
  }
);
