import { ReactElement } from "react";
import { UserRole } from "../api/types";
import { isNotEmpty } from "../util";

export default function UserOrganization(props: {
  usrRoles?: UserRole[] | undefined;
}): ReactElement {
  return (
    <>
      {props.usrRoles != null
        ? props.usrRoles
            .map((u: UserRole): string =>
              u.organization != null ? u.organization.org_name : ""
            )
            .filter((org_name: string) => isNotEmpty(org_name))
            .join(", ")
        : "Saknas"}
    </>
  );
}
