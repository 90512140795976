import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ReactElement, useCallback, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import { Button } from "../../components/Buttons";
import Header from "../../components/layout/Header";
import { SimpleLayout } from "../../components/layout/SimpleLayout";
import { isEmpty, isNotEmpty } from "../../util";
import { useQueryClient } from "react-query";
import { invalidateUsers } from "../../api/InvalidateQueriesHelper";
import Search from "../../components/Search";
import UsersTable from "./UsersTable";
import useServerPagination from "../../hooks/useServerPagination";
import PaginationUI from "../../components/layout/PaginationUI";
import { useGetUsers } from "../../api/endpoints";

export interface UsersFilter {
  searchString: string;
  org_organizationID: number | null;
  role: string;
}

export default function Users(): ReactElement {
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();
  const totalUsersCount = useRef(0);

  const searchParams = useMemo((): {
    page: string | null;
    filter: UsersFilter;
  } => {
    const urlParams = new URLSearchParams(location.search);
    const searchString = urlParams.get("s");
    return {
      page: urlParams.get("sida"),
      filter: {
        searchString: searchString != null ? searchString : "",
        org_organizationID: null,
        role: "",
      },
    };
  }, [location.search]);

  const query = useGetUsers({
    page: searchParams.page,
    filter: searchParams.filter,
    perPage: 25,
  });

  const urlChanged = useCallback(
    (filter: UsersFilter, pageNumber: string | null): void => {
      const params: string[] = [];
      if (isNotEmpty(filter.searchString)) {
        params.push(`s=${filter.searchString}`);
      }
      if (isNotEmpty(pageNumber)) {
        params.push(`sida=${pageNumber}`);
      }
      let path = "anvandare";
      if (params.length > 0) {
        path += `?${params.join("&")}`;
      }
      history.push(path);
    },
    [history]
  );

  const handlePaginationChange = useCallback(
    (pageNumber: number | undefined, previousPage: number | undefined) => {
      if (pageNumber !== previousPage) {
        const pageString = pageNumber != null ? pageNumber.toString() : null;
        urlChanged(searchParams.filter, pageString);
      }
    },
    [urlChanged, searchParams]
  );

  const handleSearch = useCallback(
    (searchString: string): void => {
      urlChanged(
        {
          searchString,
          org_organizationID: null,
          role: "",
        },
        null
      );
      if (isEmpty(searchString)) {
        void queryClient.invalidateQueries({
          predicate: invalidateUsers,
        });
      }
    },
    [queryClient, urlChanged]
  );

  const paginationProps = useServerPagination(
    query.data?._meta,
    searchParams.page,
    handlePaginationChange
  );

  if (query.status === "success") {
    totalUsersCount.current = query.data._meta.totalCount;
  }

  return (
    <SimpleLayout>
      <Header title={`Användare | ${totalUsersCount.current}st`}>
        <div className="flex items-center self-center space-x-2">
          <Button
            icon={faPlus}
            content="Lägg till användare"
            onClick={() => history.push("/anvandare/ny")}
            size="medium"
          />
          <Button
            icon={faPlus}
            content="Importera användare"
            onClick={() => history.push("/anvandare/importera")}
            size="medium"
          />
        </div>
      </Header>

      <div>
        <div className="filter-search-wrapper">
          <Search serverSideSearch onSearch={handleSearch} />

          <div className="filter med:flex w-full md:w-1/3">
            <div className="dropdown-licens"></div>
            <div className="dropdown-status"></div>
          </div>
        </div>
        <PaginationUI {...paginationProps}>
          <UsersTable usersQuery={query} />
        </PaginationUI>
      </div>
    </SimpleLayout>
  );
}
