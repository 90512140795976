import { ReactElement } from "react";
import { isNotEmpty } from "../../util";

export default function TopImage(props: {
  imagePath: string;
  className?: string;
}): ReactElement {
  return (
    <div
      className={`h-64 md:h-96 xl:h-96 w-full bg-ocean-blue bg-cover bg-bottom ${
        isNotEmpty(props.className) ? props.className : ""
      }`}
      style={{ backgroundImage: `url(${props.imagePath})` }}
    ></div>
  );
}
