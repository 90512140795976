import { ReactElement, ReactNode } from "react";
import { isNotEmpty } from "../../util";

/**
 *
 * @param props
 */
export function FormSectionTitle(props: { title: string }): ReactElement {
  return <h3 className="font-bold mb-4">{props.title}</h3>;
}

/**
 * Structure children in a two column grid. Pass in className to override
 * the structure
 * @param props
 */
export function FormSectionBody(props: {
  className?: string;
  children: ReactNode;
}): ReactElement {
  return (
    <div
      className={
        props.className != null
          ? props.className
          : "grid grid-cols-1 md:grid-cols-2 gap-x-8 items-baseline "
      }
    >
      {props.children}
    </div>
  );
}

/**
 * Wrapper for Form
 * @param props
 */
export function FormSection(props: {
  className?: string;
  children: ReactNode;
  title?: string;
}): ReactElement {
  return (
    <div
      className={`my-10 ${isNotEmpty(props.className) ? props.className : ""}`}
    >
      {isNotEmpty(props.title) ? <FormSectionTitle title={props.title} /> : ""}
      {props.children}
    </div>
  );
}
