import {
  GetListResponse,
  Organization,
  User,
  Module,
  Client,
  License,
  Course,
  UserDocument,
  ResourceCategory,
  ModuleLog,
  Message,
  ModuleProgress,
  Media,
} from "./types";
import {
  QueryKey,
  QueryObserverOptions,
  useQuery,
  UseQueryResult,
} from "react-query";
import { fetchApiDelete, fetchApiGet, fetchApi, RequestTypes } from "./api";
import { isNotEmpty } from "../util";
import { ApiError } from "./ApiError";

export interface UseQeryOnSettled<T> {
  (data?: GetListResponse<T> | undefined, error?: Error | null): void;
}

export function getQueryString(query: string[]): string {
  return query.length > 0 ? `?${query.join("&")}` : "";
}

export function useGetUserDocuments({
  expand,
  filter,
  options,
}: {
  userId?: string;
  expand?: string;
  filter?: { [x: string]: string };
  options?: QueryObserverOptions<
    GetListResponse<UserDocument>,
    Error,
    GetListResponse<UserDocument>,
    GetListResponse<UserDocument>,
    QueryKey
  >;
}): UseQueryResult<GetListResponse<UserDocument>, Error> {
  const key = ["user-documents"];

  const queryVars: string[] = [];
  if (filter != null) {
    for (const filterKey in filter) {
      key.push(filter[filterKey]);
      queryVars.push(`filter[${filterKey}]=${filter[filterKey]}`);
    }
  }
  if (isNotEmpty(expand)) {
    queryVars.push(expand);
  }

  const path = `user-documents` + getQueryString(queryVars);

  return useQuery<GetListResponse<UserDocument>, Error>(
    key,
    () => fetchApiGet<GetListResponse<UserDocument>>(path),
    options
  );
}

export function useGetResourceCatergories(props?: {
  page?: string | null;
  filter?: { slug?: string };
  perPage?: number;
  expand?: string;
}): UseQueryResult<GetListResponse<ResourceCategory>, Error> {
  let key = ["resource-categories"];
  const queryStrings = ["sort=rc_order"];

  if (props?.expand != null) {
    key = [...key, "expand", props.expand];
    queryStrings.push("expand=" + props.expand);
  }

  if (props?.filter?.slug != null) {
    key = [...key, "filter"];

    if (isNotEmpty(props.filter.slug)) {
      key = [...key, "slug", props.filter.slug];
      queryStrings.push("filter[rc_slug][like]=" + props.filter.slug);
    }
  }

  const path = "resource-categories" + getQueryString(queryStrings);
  return useQuery<GetListResponse<ResourceCategory>, Error>(key, () =>
    fetchApiGet<GetListResponse<ResourceCategory>>(path)
  );
}

export function useGetClients(props?: {
  page?: string | null;
  filter?: string;
  perPage?: number;
  expands?: string[];
}): UseQueryResult<GetListResponse<Client>, Error> {
  let key = ["clients"];

  let queryString = "?";
  if (props != null) {
    if (props.expands != null) {
      key = [...key, "expand", ...props.expands];
      queryString += `expand=${props.expands.join(",")}`;
    }
    if (isNotEmpty(props.page)) {
      key = [...key, "page", props.page];
      queryString += "&page=" + props.page;
    }

    let filterString = "";
    if (props.filter != null) {
      if (isNotEmpty(props.filter)) {
        key = [...key, "filter", props.filter];
        filterString += "&filter[or][0][cl_name][like]=" + props.filter;
        filterString += "&filter[or][1][cl_org_number][like]=" + props.filter;
        const isId = parseInt(props.filter);
        if (!isNaN(isId)) {
          filterString += "&filter[or][2][clientID]=" + props.filter;
        }
      }
      queryString += filterString;
    }
    if (props.perPage != null) {
      queryString += `&per-page=${props.perPage}`;
      key = [...key, `perPage=${props.perPage}`];
    }
  }

  const path = "clients" + queryString;

  return useQuery<GetListResponse<Client>, Error>(key, () =>
    fetchApiGet<GetListResponse<Client>>(path)
  );
}

export function useGetLicenses(props?: {
  clientId: string;
}): UseQueryResult<GetListResponse<License>, Error> {
  let key = ["licenses"];

  let queryString = "?expand=courses,resourceCategories";
  if (props != null) {
    let filterString = "";
    if (isNotEmpty(props.clientId)) {
      key = [...key, "clientId", props.clientId];
      filterString += "&filter[l_clientID]=" + props.clientId;
      queryString += filterString;
    }
  }

  const path = "licenses" + queryString;

  return useQuery<GetListResponse<License>, Error>(key, () =>
    fetchApiGet<GetListResponse<License>>(path)
  );
}

export function useGetModules(): UseQueryResult<
  GetListResponse<Module>,
  Error
> {
  return useGetListResponse(["modules"], "?sort=m_number");
}

export function useGetMedia(props: {
  mediaRefs: string[];
}): UseQueryResult<GetListResponse<Media>, Error> {
  const getParams: string[] = [];
  props.mediaRefs.map((mediaRef, index) =>
    getParams.push(`filter[or][${index}][media_ref]=${mediaRef}`)
  );
  getParams.push("sort=media_order");
  return useQuery<GetListResponse<Media>, Error>(
    ["media", ...props.mediaRefs],
    () => fetchApiGet<GetListResponse<Media>>(`media?${getParams.join("&")}`),
    {
      cacheTime: 0,
    }
  );
}

export function useGetCourses(): UseQueryResult<
  GetListResponse<Course>,
  Error
> {
  return useGetListResponse(["courses"], "?expand=modules");
}

export function useGetMessages(props: {
  moduleID: number;
  userID: number;
}): UseQueryResult<GetListResponse<Message>, Error> {
  return useQuery<GetListResponse<Message>, Error>(
    ["module", props.moduleID, "messages", "user", props.userID],
    () =>
      fetchApiGet<GetListResponse<Message>>(
        `messages?filter[msg_moduleID]=${props.moduleID}&filter[msg_userID]=${props.userID}`
      ),
    {
      cacheTime: 0,
    }
  );
}
export function useGetModuleLogs(props: {
  moduleID: number;
  userID: number;
}): UseQueryResult<GetListResponse<ModuleLog>, Error> {
  return useQuery<GetListResponse<ModuleLog>, Error>(
    ["module", props.moduleID, "moduleLogs", "user", props.userID],
    () =>
      fetchApiGet<GetListResponse<ModuleLog>>(
        `module-logs?filter[mlog_moduleID]=${props.moduleID}&filter[mlog_userID]=${props.userID}`
      ),
    {
      cacheTime: 0,
    }
  );
}

export function useGetUsers(props?: {
  page?: string | null;
  filter?: {
    searchString?: string | null;
  };
  perPage?: number;
}): UseQueryResult<GetListResponse<User>, Error> {
  let key = ["users"];

  let queryString = "?expand=userRoles.organization,userRoles.client";
  if (props != null) {
    if (isNotEmpty(props.page)) {
      key = [...key, "page", props.page];
      queryString += "&page=" + props.page;
    }

    let filterString = "";
    if (props.filter != null) {
      if (isNotEmpty(props.filter.searchString)) {
        key = [...key, "filter", props.filter.searchString];
        filterString +=
          "&filter[or][0][usr_firstname][like]=" + props.filter.searchString;
        filterString +=
          "&filter[or][1][usr_lastname][like]=" + props.filter.searchString;
        filterString +=
          "&filter[or][2][usr_email][like]=" + props.filter.searchString;
      }
      queryString += filterString;
    }
    if (props.perPage != null) {
      queryString += `&per-page=${props.perPage}`;
      key = [...key, `perPage=${props.perPage}`];
    }
  }

  const path = "users" + queryString;

  return useQuery<GetListResponse<User>, Error>(key, () =>
    fetchApiGet<GetListResponse<User>>(path)
  );
}

export function useGetListResponse<T>(
  key: QueryKey[],
  expand?: string,
  onSettled?: (
    data?: GetListResponse<T> | undefined,
    error?: Error | null
  ) => void
): UseQueryResult<GetListResponse<T>, Error> {
  const path = key.join("/");
  return useQuery<GetListResponse<T>, Error>(
    key,
    () =>
      fetchApiGet<GetListResponse<T>>(expand != null ? path + expand : path),
    {
      onSettled: (data, error) =>
        onSettled != null ? onSettled(data, error) : null,
    }
  );
}

/** CLIENT ENDPOINTS */
export function useGetViewClient(clientId: string): UseQueryResult<Client> {
  return useQuery<Client, Error>(
    ["clients", clientId, "users.userRoles.organization", "organizations"],
    () =>
      fetchApiGet<Client>(
        `clients/${clientId}?expand=users.userRoles.organization,organizations`
      )
  );
}

export function useGetEditClient(clientId: string): UseQueryResult<Client> {
  return useQuery<Client, Error>(
    ["clients", clientId, "licenses.licenseModules,licenseResources"],
    () =>
      fetchApiGet<Client>(
        `clients/${clientId}?expand=licenses.licenseModules,licenses.licenseResources`
      )
  );
}

/** ORGANIZATION ENDPOINTS */

export function useGetEditOrganization(
  orgId: string
): UseQueryResult<Organization> {
  return useQuery<Organization, Error>(["organizations", orgId, "client"], () =>
    fetchApiGet<Organization>(`organizations/${orgId}?expand=client`)
  );
}

export function useGetViewOrganization(
  orgId: string
): UseQueryResult<Organization> {
  return useQuery<Organization, Error>(
    ["organizations", orgId, "client", "users.userRoles"],
    () =>
      fetchApiGet<Organization>(
        `organizations/${orgId}?expand=client,users.userRoles`
      )
  );
}

/** USER ENDPOINS */
export function useGetAccountPageUser(userId: string): UseQueryResult<User> {
  return useQuery<User, Error>(
    ["users", userId, "userRoles.organization"],
    () => fetchApiGet<User>(`users/${userId}?expand=userRoles.organization`)
  );
}

export function useGetEditUser(userId: string): UseQueryResult<User> {
  return useQuery<User, Error>(
    ["users", userId, "userRoles.organization"],
    () => fetchApiGet<User>(`users/${userId}?expand=userRoles.organization`)
  );
}

export function useGetCourseModule(
  moduleId: string
): UseQueryResult<Module, ApiError> {
  return useQuery<Module, ApiError>(
    [moduleId, "moduleLogs", "moduleProgresses", "messages"],
    () =>
      fetchApiGet<Module>(
        `modules/${moduleId}?expand=moduleLogs,moduleProgresses,messages.user`
      ),
    {
      cacheTime: 0,
    }
  );
}

export function useGetDashboardModules(): UseQueryResult<
  GetListResponse<Module>,
  Error
> {
  return useQuery<GetListResponse<Module>, Error>(
    ["modules", "dashboard"],
    () => fetchApiGet<GetListResponse<Module>>("modules?fields=moduleID"),
    {
      refetchOnMount: "always",
    }
  );
}
export function useGetPlatformModules(): UseQueryResult<
  GetListResponse<Module>,
  Error
> {
  return useQuery<GetListResponse<Module>, Error>(
    ["modules", "platform"],
    () => fetchApiGet<GetListResponse<Module>>("modules?sort=m_number"),
    {
      refetchOnMount: "always",
    }
  );
}
export function useGetModuleProgresses(props: {
  userID: number;
}): UseQueryResult<GetListResponse<ModuleProgress>, Error> {
  return useQuery<GetListResponse<ModuleProgress>, Error>(
    ["module-progress", "user", props.userID.toString()],
    () =>
      fetchApiGet<GetListResponse<ModuleProgress>>(
        `module-progresses?filter[mprog_userID]=${props.userID}`
      )
  );
}

export function useGetUserRoles(): {
  [key: string]: { label: string; value: string };
} {
  return {
    admin: { label: "Administratör", value: "admin" },
    clientOrgAdmin: {
      value: "clientOrgAdmin",
      label: "Uppdragsgivaradministratör",
    },
    orgAdmin: {
      value: "orgAdmin",
      label: "Organisationsadministratör",
    },
    regUser: {
      label: "Användare",
      value: "regUser",
    },
  };
}

export function postData<T>(
  path: string,
  data: URLSearchParams | string | FormData
): Promise<T> {
  return fetchApi<T>(path, data);
}
export function putData<T>(
  path: string,
  data: URLSearchParams | string
): Promise<T> {
  return fetchApi<T>(path, data, RequestTypes.PUT);
}
export function deleteData<T>(path: string): Promise<T> {
  return fetchApiDelete<T>(path);
}

export function signout(): Promise<void> {
  return fetchApiDelete<void>("auth/refresh-token");
}
