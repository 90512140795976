import { yupResolver } from "@hookform/resolvers/yup";
import { ReactElement, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import { putData, deleteData, useGetEditOrganization } from "../api/endpoints";
import { Organization, DeleteResponse } from "../api/types";
import { OrganizationData } from "../api/form-types";
import FormActionButtons from "../components/formLayout/FormActionButtons";
import EditSubtitle from "../components/layout/EditSubtitle";
import Header from "../components/layout/Header";
import {
  OrganizationFormSection,
  organizationSchema,
} from "../forms/OrganizationForm";
import Loader from "../components/layout/Loader";
import { SimpleLayout } from "../components/layout/SimpleLayout";
import { invalidateUsers } from "../api/InvalidateQueriesHelper";
import {
  getReferencingDeleteError,
  getValidationGenericErrorMessages,
} from "../util";
import FormError from "../components/formLayout/FormError";
import { useConfirmDialog } from "../hooks/ProvideConfirmDialog";

export default function EditOrganization(): ReactElement {
  const params = useParams<{ id: string }>();
  const query = useGetEditOrganization(params.id);

  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      return <OrganizationsForm org={query.data} />;
  }
}

function OrganizationsForm(props: { org: Organization }): ReactElement {
  const confirmationDialog = useConfirmDialog();
  const [errors, setErrors] = useState<string[]>([]);
  const history = useHistory();
  const queryClient = useQueryClient();
  const org = props.org;

  const formDefaults = {
    defaultValues: {
      org_clientID: org.client?.clientID as unknown,
      org_name: org.org_name,
      org_number: org.org_number,
    } as OrganizationData,
  };
  const methods = useForm<OrganizationData>({
    ...formDefaults,
    resolver: yupResolver(organizationSchema),
  });
  const mutations = useMutation((data: string) =>
    putData<Organization>(`organizations/${org.organizationID}`, data)
  );

  const onFormSubmit = async (data: FieldValues): Promise<void> => {
    await mutations
      .mutateAsync(JSON.stringify(data))
      .then((savedOrganization) => {
        void queryClient.invalidateQueries("organizations", { exact: true });
        void queryClient.invalidateQueries({
          predicate: invalidateUsers,
        });
        void queryClient.invalidateQueries([
          "organizations",
          org.organizationID.toString(),
        ]);

        history.goBack();
      })
      .catch((error) => {
        // TODO: Handle error
      });
  };

  const handleDeleteButtonClicked = async (): Promise<void> => {
    await confirmationDialog
      .getConfirmation({
        title: "Varning!",
        message: "Är du säker på att du vill ta bort organisationen?",
      })
      .then((confirmed: boolean) => {
        if (!confirmed) return;
        deleteData<undefined>(`organizations/${org.organizationID}`)
          .then((response: undefined | DeleteResponse) => {
            if (response instanceof Object && "errorsItemized" in response) {
              const errors = [
                ...getValidationGenericErrorMessages(response.errorsItemized),
                ...getReferencingDeleteError(
                  "Organisationen",
                  response.errorsItemized
                ),
              ];
              setErrors(errors);
            } else {
              void queryClient.invalidateQueries("organizations", {
                exact: true,
              });
              void queryClient.invalidateQueries({
                predicate: invalidateUsers,
              });
              void queryClient.removeQueries([
                "organizations",
                org.organizationID.toString(),
              ]);
              history.goBack();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };

  return (
    <SimpleLayout>
      <EditSubtitle onDeletedClicked={handleDeleteButtonClicked} />
      <Header title={org.org_name}></Header>
      <form>
        <OrganizationFormSection methods={methods} />

        <div>
          {errors.map((e) => (
            <FormError key={e} message={e} />
          ))}
        </div>

        <FormActionButtons
          methods={methods}
          onFormSave={(data: FieldValues) => onFormSubmit(data)}
          onFormCancel={() => history.goBack()}
        />
      </form>
    </SimpleLayout>
  );
}
