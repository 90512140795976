import { ChangeEvent, ReactElement, useMemo, useState } from "react";
import Header from "../../components/layout/Header";
import { useController, useForm } from "react-hook-form";
import FormSelect from "../../components/formLayout/FormSelect";
import { FilledButton } from "../../components/Buttons";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { postData } from "../../api/endpoints";
import Loader from "../../components/layout/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { SimpleLayout } from "../../components/layout/SimpleLayout";
import FormError from "../../components/formLayout/FormError";
import { FormCheckbox } from "../../components/formLayout/FormChecbox";
import { useGetOrganizations } from "../Organizations/useGetOrganizations";

type ImportUsersData = {
  organizationID: number;
  userList: string;
  errors?: string[];
  notices?: string[];
  gdprAccepted: boolean;
};

const schema = yup.object({
  organizationID: yup.number(), // This should be required, but when I make it required, it causes problems. Not sure why.
  userList: yup.string().required("Detta fält är obligatoriskt"),
  gdprAccepted: yup.boolean().oneOf([true], "Samtycke krävs"),
});

export default function ImportUsers(): ReactElement {
  const query = useGetOrganizations();
  const orgOptions = useMemo(
    () =>
      query.isSuccess
        ? query.data.items.map((orgItem) => ({
            value: orgItem.organizationID,
            label: orgItem.org_name,
          }))
        : [],
    [query]
  );

  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      return (
        <SimpleLayout>
          <Header title="Importera användare" />
          <Form options={orgOptions} />
        </SimpleLayout>
      );
  }
}

const Form = ({
  options,
}: {
  options: { value: number; label: string }[];
}): ReactElement => {
  const [errorsAndNotices, setErrorsAndNotices] = useState<string[]>([]);
  const [emailsCount, setEmailsCount] = useState<number | undefined>(0);

  const methods = useForm<ImportUsersData>({
    resolver: yupResolver(schema),
    defaultValues: {
      organizationID: options[0].value,
      userList: "",
      gdprAccepted: false,
    },
  });

  const controller = useController({
    control: methods.control,
    name: "gdprAccepted",
  });

  const mutation = useMutation((importUsersData: string) => {
    return postData<ImportUsersData>("users/bulk", importUsersData);
  });
  const onSubmit = (data: ImportUsersData): void => {
    let newErrorsAndNoticies: string[] = [];
    mutation
      .mutateAsync(JSON.stringify(data))
      .then((res) => {
        if (res.notices != null && res.notices.length > 0) {
          newErrorsAndNoticies = res.notices.map((error) => error);
        }

        if (res.errors != null && res.errors.length > 0) {
          newErrorsAndNoticies = [
            ...newErrorsAndNoticies,
            ...res.errors.filter((error) => !errorsAndNotices.includes(error)),
          ];
        } else {
          setEmailsCount(
            data.userList.match(/@/g) != null
              ? data.userList.match(/@/g)?.length
              : 0
          );
        }

        setErrorsAndNotices(newErrorsAndNoticies);
      })
      .catch((error: { name: string; message: string }) => {
        newErrorsAndNoticies.push(
          "Tyvärr inträffade ett tekniskt fel. \n" +
            error.name +
            ": " +
            error.message +
            " [HTTP error]"
        );
        setErrorsAndNotices(newErrorsAndNoticies);
      });
  };

  const handleCsvFile = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files != null) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = function (e) {
        let text = e.target?.result;

        if (typeof text === "string") {
          while (text.includes('"')) {
            text = text.replace('"', "");
          }

          text = text.toString().replace(/,/g, ";");
          text = text.toString().replace(/\t/g, ";");

          const atSymbolsCount =
            text.match(/@/g) != null ? text.match(/@/g)?.length : 0;
          setEmailsCount(atSymbolsCount);
          methods.setValue("userList", text);
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <form className="flex mt-14">
      <div className="column w-1/2 mr-16">
        <h4>E-post</h4>
        <p className="">
          Bjud in användare genom att importera uppgifter från en CSV-fil eller
          kopiera från ett Exceldokument.
        </p>

        {/* IMPORT FROM CSV ------------------- */}
        <div className="mt-8 mb-6 font-bold">
          <span className="mr-4">Importera från CSV-fil</span>
          <input
            className="text-sm"
            type="file"
            accept=".csv"
            onChange={(e) => {
              handleCsvFile(e);
            }}
          />
        </div>
        {/* ------------------ */}

        <textarea
          className="block h-96 p-6 border-l-4 border-l-ocean-blue bg-medium-gray w-full"
          {...methods.register("userList")}
          placeholder="magnus@karlsson.se;Magnus;Karlsson"
        ></textarea>
        {methods.formState.errors.userList && (
          <FormError
            className="pt-2"
            message={methods.formState.errors.userList.message}
          />
        )}

        <p className="mt-2 mb-6 text-sm">
          Kopiera och klistra in tre kolumner med epost, förnamn och efternamn
          från ett excel dokument. Se till att det är en användare per rad.
          Varje rad kräver en e-post. Det är valfritt att även ange förnamn
          och/eller efternamn.
        </p>

        <div className="mt-12">
          <FormCheckbox
            title="Jag försäkrar att jag har tillåtelse att hantera dessa personuppgifter"
            checked={controller.field.value ? true : false}
            onChange={controller.field.onChange}
            onBlur={controller.field.onBlur}
            ref={controller.field.ref}
          />
          <p>
            Läs Integritetspolicyn{" "}
            <a
              href={process.env.REACT_APP_INTEGRITY_POLICY_LINK}
              rel="noopener noreferrer"
              target="_blank"
              className="cursor-pointer font-bold"
            >
              här
            </a>
          </p>
          {methods.formState.errors.gdprAccepted && (
            <FormError
              className="pt-2"
              message={methods.formState.errors.gdprAccepted.message}
            />
          )}
        </div>

        {errorsAndNotices.map((error, index) => (
          <FormError key={index} message={error} className="pt-2" />
        ))}

        <div className="flex items-center mt-12">
          <FilledButton
            content="Skicka"
            className="mr-4"
            onClick={methods.handleSubmit(onSubmit, (error) => {
              /* console.log(error)*/
            })}
          />

          {(methods.formState.isSubmitting ||
            methods.formState.isValidating) && <Loader inline />}
          {errorsAndNotices.length === 0 &&
            Object.keys(methods.formState.errors).length === 0 &&
            methods.formState.isSubmitted &&
            emailsCount !== 0 && (
              <div className="bg-soft-mint bg-opacity-40 pl-4 py-2 pr-2">
                <span className="mr-4">
                  <strong>{emailsCount} användare</strong>{" "}
                  {emailsCount === 1 ? "importerad" : "importerade"}
                </span>
                <FontAwesomeIcon size="xs" icon={faCheck} />
              </div>
            )}
          {Object.keys(methods.formState.errors).length > 0 ||
            (errorsAndNotices.length > 0 && (
              <FontAwesomeIcon icon={faExclamationTriangle} />
            ))}
        </div>
      </div>

      <div className="column">
        <h4>Organisation</h4>
        <p>Ange vilken organisation användarna ska tillhöra.</p>
        <FormSelect
          className={"mt-6"}
          options={options}
          registerReturn={methods.register("organizationID")}
        />
      </div>
    </form>
  );
};
