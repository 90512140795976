import { ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import AlertBar from "./AlertBar";

interface PageProps {
  className?: string;
  children: ReactNode;
}

export default function UnauthorizedPage(props: PageProps): ReactElement {
  const loginCheatSheetUrl =
    "https://media.forskoleutveckling.nu/persistence/media/mangfaldens-forskole/Lathund%20fo%CC%88r%20inloggning%20och%20anva%CC%88ndarhantering%201.2.pdf";
  return (
    <div className="flex flex-col min-h-screen bg-light-mint justify-items-center min-h-screen">
      <AlertBar />
      <Header />
      <div className="min-w-screen">
        <div className="ua-content-wrapper bg-white w-5/6 md:w-3/4 xl:w-2/4 mx-auto p-4 mt-8 md:p-12 ">
          {props.children}
        </div>
      </div>
      <div className="text-base text-center mt-6">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={loginCheatSheetUrl}
          className="cursor-pointer"
        >
          Lathund för inloggning
        </a>{" "}
        |{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={process.env.REACT_APP_INTEGRITY_POLICY_LINK}
          className="cursor-pointer"
        >
          Integritetspolicy
        </a>{" "}
        | Copyright förskoleutveckling utbildningsplattform
      </div>
    </div>
  );
}

function Header(): ReactElement {
  return (
    <div className="header sm:h-72 relative max-w-xl mx-auto mt-8 mb:8 md:mb-16">
      <Link to="/">
        <img
          className="max-w-full max-h-full"
          src={process.env.PUBLIC_URL + "/logo-centered-text.png"}
          alt=""
        />
      </Link>
    </div>
  );
}
