import { ReactElement, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import { deleteData, putData, useGetEditClient } from "../api/endpoints";
import { ClientData } from "../api/form-types";
import { Client, DeleteResponse } from "../api/types";
import FormActionButtons from "../components/formLayout/FormActionButtons";
import EditSubtitle from "../components/layout/EditSubtitle";
import Header from "../components/layout/Header";
import { ClientFormSection } from "../forms/ClientFormSection";
import { LicensesFormSection } from "../forms/LicensesFormSection";
import Loader from "../components/layout/Loader";
import { SimpleLayout } from "../components/layout/SimpleLayout";
import { SettingsFormSection } from "../forms/SettingsFormSection";
import { invalidateUsers } from "../api/InvalidateQueriesHelper";
import FormError from "../components/formLayout/FormError";
import { useConfirmDialog } from "../hooks/ProvideConfirmDialog";
import {
  getReferencingDeleteError,
  getValidationGenericErrorMessages,
} from "../util";
import { yupResolver } from "@hookform/resolvers/yup";
import { clientSchema } from "./AddClient";

export default function EditClient(): ReactElement {
  const params: { id: string } = useParams();
  const clientId: string = "id" in params ? params["id"] : "";
  const query = useGetEditClient(clientId);

  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      return <ClientForm client={query.data} />;
  }
}

function ClientForm({ client }: { client: Client }): ReactElement {
  const confirmationDialog = useConfirmDialog();
  const [errors, setErrors] = useState<string[]>([]);
  const history = useHistory();
  const queryClient = useQueryClient();
  const licenses = client.licenses ?? [];

  const formDefaults = {
    defaultValues: {
      cl_name: client.cl_name,
      cl_org_number: client.cl_org_number,
      cl_contact_name: client.cl_contact_name,
      cl_contact_email: client.cl_contact_email,
      cl_contact_phone: client.cl_contact_phone,
      licenses:
        licenses.length > 0
          ? licenses.map((l) => {
              const licenseModules = (l.licenseModules != null
                ? l.licenseModules
                : []) as { lm_moduleID: number }[];
              const licenseResources = (l.licenseResources != null
                ? l.licenseResources
                : []) as { lr_resourceID: number }[];
              return {
                licenseID: l.licenseID.toString(),
                l_start_date: l.l_start_date,
                l_end_date: l.l_end_date,
                moduleIDs: licenseModules.map((m) => m.lm_moduleID),
                resourceIDs: licenseResources.map((r) => r.lr_resourceID),
              };
            })
          : [],
    },
  };
  const methods = useForm<ClientData>({
    ...formDefaults,
    resolver: yupResolver(clientSchema),
  });
  const mutations = useMutation((data: string) =>
    putData<ClientData>(`clients/${client.clientID}`, data)
  );

  const onFormSubmit = async (data: FieldValues): Promise<void> => {
    await mutations
      .mutateAsync(JSON.stringify(data))
      .then((savedOrganization) => {
        void queryClient.invalidateQueries("clients", { exact: true });
        void queryClient.invalidateQueries("organizations", { exact: true });
        void queryClient.invalidateQueries([
          "clients",
          client.clientID.toString(),
        ]);
        void queryClient.invalidateQueries(["licenses"]);
        history.goBack();
      })
      .catch((error) => {
        // TODO: Handle error
      });
  };

  const handleDeleteButtonClicked = async (): Promise<void> => {
    await confirmationDialog
      .getConfirmation({
        title: "Varning!",
        message: "Är du säker på att du vill ta bort organisationen?",
      })
      .then((confirmed: boolean) => {
        if (!confirmed) return;
        deleteData<undefined>(`clients/${client.clientID}`)
          .then((response: undefined | DeleteResponse) => {
            if (response !== undefined && "errorsItemized" in response) {
              const errors = [
                ...getValidationGenericErrorMessages(response.errorsItemized),
                ...getReferencingDeleteError(
                  "Uppdragsivaren",
                  response.errorsItemized
                ),
              ];
              setErrors(errors);
            } else {
              void queryClient.invalidateQueries("clients", { exact: true });
              void queryClient.invalidateQueries("organizations", {
                exact: true,
              });
              void queryClient.invalidateQueries({
                predicate: invalidateUsers,
              });
              void queryClient.removeQueries([
                "clients",
                client.clientID.toString(),
              ]);
              history.goBack();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };

  return (
    <SimpleLayout>
      <EditSubtitle onDeletedClicked={handleDeleteButtonClicked} />
      <Header title={client.cl_name}></Header>
      <form>
        <ClientFormSection methods={methods} />
        <LicensesFormSection methods={methods} />

        <SettingsFormSection
          methods={methods}
          clientSettings={client.cl_settings}
        />

        <div>
          {errors.map((e) => (
            <FormError key={e} message={e} />
          ))}
        </div>

        <div className="flex items-center">
          <FormActionButtons
            methods={methods}
            onFormSave={(data: FieldValues) => onFormSubmit(data)}
            onFormCancel={() => history.goBack()}
          />
        </div>
      </form>
    </SimpleLayout>
  );
}
