import { ReactElement, ReactNode } from "react";

export default function TableHeader(props: {
  children: ReactNode;
}): ReactElement {
  return (
    <thead>
      <tr className="">{props.children}</tr>
    </thead>
  );
}
