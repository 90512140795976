import React, { ReactElement, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { postData } from "../api/endpoints";
import Header from "../components/layout/Header";
import { Button } from "../components/Buttons";
import { Organization, PostResponse } from "../api/types";
import AdminUsersFormSection, {
  OrganizationFormSection,
  organizationSchema,
} from "../forms/OrganizationForm";
import { OrganizationData } from "../api/form-types";
import { useHistory, useLocation } from "react-router";
import {
  getValidationGenericErrorMessages,
  isEmpty,
  removeEmptyFields,
  setFormErrors,
} from "../util";
import { yupResolver } from "@hookform/resolvers/yup";
import { SimpleLayout } from "../components/layout/SimpleLayout";
import FormError from "../components/formLayout/FormError";
import { invalidateUsers } from "../api/InvalidateQueriesHelper";

export default function AddOrganization(): ReactElement {
  const history = useHistory();
  const { state } = useLocation<{ clientId: number } | undefined>();
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const [errorsPresent, setErrorsPresent] = useState(false);
  const queryClient = useQueryClient();

  let formDefaults = {};
  formDefaults = {
    defaultValues: {
      org_clientID: state != null ? state.clientId : 0,
      users: [],
    },
  };
  const methods = useForm<OrganizationData>({
    ...formDefaults,
    criteriaMode: "all",
    resolver: yupResolver(organizationSchema),
  });
  const mutation = useMutation((orgData: string) =>
    postData<Organization | PostResponse>("organizations", orgData)
  );

  // const history = useHistory();

  const handleButtonSubmit = async (
    formData: OrganizationData,
    e?: React.BaseSyntheticEvent
  ): Promise<void> => {
    formData.users = formData.users.filter((d) => {
      return !(
        isEmpty(d.usr_email) &&
        isEmpty(d.usr_firstname) &&
        isEmpty(d.usr_lastname)
      );
    });
    await mutation
      .mutateAsync(JSON.stringify(removeEmptyFields(formData)))
      .then((res) => {
        if ("errorsItemized" in res) {
          setFormErrors<OrganizationData>(res.errorsItemized, methods);
          setServerErrors(
            getValidationGenericErrorMessages(res.errorsItemized)
          );
        } else {
          void queryClient.invalidateQueries("organizations", { exact: true });
          void queryClient.invalidateQueries({
            predicate: invalidateUsers,
          });
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);

        // TODO: HANDLE ERRORS
      });
  };

  return (
    <SimpleLayout>
      <Header title="Ny organisation"></Header>
      <form className="gap-x-8">
        <OrganizationFormSection methods={methods} />
        <AdminUsersFormSection methods={methods} />
        <div className="flex">
          {serverErrors.map((e, index) => (
            <FormError
              key={e + index.toString()}
              className="pt-1 pb-4"
              message={e}
            />
          ))}
        </div>
        <div className="flex items-center">
          <Button
            content="Skapa organisation"
            size="medium"
            onClick={methods.handleSubmit(handleButtonSubmit, () => {
              setErrorsPresent(true);
            })}
          ></Button>
          {errorsPresent && (
            <FormError className="pl-5" message="Något gick fel" />
          )}
        </div>
      </form>
    </SimpleLayout>
  );
}
