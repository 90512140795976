import { ReactElement, useState } from "react";
import { FieldValues, useController, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router";
import { postData } from "../api/endpoints";
import { PostResponse, User } from "../api/types";
import { ApiError } from "../api/ApiError";
import Header from "../components/layout/Header";
import UserForm from "../forms/UserForm";
import { SimpleLayout } from "../components/layout/SimpleLayout";
import * as yup from "yup";
import { schema } from "../forms/Users/users-schema";
import UserFormUserRoles from "../forms/Users/UserFormUserRoles";
import { UserRoleFormData } from "../forms/Users/user-form-types";
import UserFormFields from "../forms/Users/UserFormFields";
import FormActionButtons from "../components/formLayout/FormActionButtons";
import { getValidationGenericErrorMessages } from "../util";
import { useAuth } from "../hooks/ProvideAuth";
import { hasRole } from "../api/Auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormCheckbox } from "../components/formLayout/FormChecbox";
import FormError from "../components/formLayout/FormError";
import { invalidateUsers } from "../api/InvalidateQueriesHelper";

const addUserSchema = schema.concat(
  yup.object().shape({
    gdprAccepted: yup.boolean().oneOf([true], "Samtycke krävs"),
  })
);

type UserFormData = {
  userRoles: UserRoleFormData[];
  usr_firstname: string;
  usr_lastname: string;
  usr_email: string;
  usr_password_plain?: string;
  usr_confirmed_password?: string;
  deleteUserRoles: UserRoleFormData[];
  gdprAccepted?: boolean;
};

export default function AddUser(): ReactElement {
  const queryClient = useQueryClient();
  const auth = useAuth();
  const { state } = useLocation<
    | {
        role?: string;
        orgId?: number;
        clientId?: number;
      }
    | undefined
  >();

  const methods = useForm<UserFormData>({
    defaultValues: {
      gdprAccepted: false,
    },
    resolver: yupResolver(addUserSchema),
    context: { required: true },
  });

  const controller = useController({
    control: methods.control,
    name: "gdprAccepted",
  });

  const [errors, setErrors] = useState<string[]>([]);

  const history = useHistory();
  const mutation = useMutation((userData: string) =>
    postData<User | PostResponse>("users", userData)
  );

  const handleButtonSaveSubmit = async (
    formData: FieldValues
  ): Promise<void> => {
    await mutation
      .mutateAsync(JSON.stringify(formData))
      .then((res) => {
        if ("errorsItemized" in res) {
          setErrors(getValidationGenericErrorMessages(res.errorsItemized));
        } else {
          void queryClient.invalidateQueries({
            predicate: invalidateUsers,
          });
          void queryClient.invalidateQueries("organizations");
          history.goBack();
        }
      })
      .catch((error: ApiError) => {
        console.log(error.details.errorsItemized);
      });
  };

  return (
    <SimpleLayout>
      <Header title="Skapa ny användare" />
      <UserForm>
        {auth?.userRoles != null &&
          hasRole(auth.userRoles, ["admin", "clientOrgAdmin", "orgAdmin"]) && (
            <UserFormUserRoles
              methods={methods}
              prefilledUserRole={
                state?.role != null
                  ? {
                      orgId: state.orgId,
                      clientID: state.clientId,
                      roleName: state.role,
                    }
                  : undefined
              }
            />
          )}
        <UserFormFields methods={methods} errors={errors} />

        <div className="mt-2">
          {errors.map((e) => (
            <FormError key={e} message={e} />
          ))}
        </div>

        <div className="mt-12">
          <FormCheckbox
            title="Jag försäkrar att jag har tillåtelse att hantera dessa personuppgifter"
            checked={controller.field.value ? true : false}
            onChange={controller.field.onChange}
            onBlur={controller.field.onBlur}
            ref={controller.field.ref}
          />
          <p>
            {`Läs Integritetspolicyn `}
            <a
              href={process.env.REACT_APP_INTEGRITY_POLICY_LINK}
              rel="noopener noreferrer"
              target="_blank"
              className="cursor-pointer font-bold"
            >
              här
            </a>
          </p>
          {methods.formState.errors.gdprAccepted && (
            <FormError
              className="pt-2"
              message={methods.formState.errors.gdprAccepted.message}
            />
          )}
        </div>

        <FormActionButtons
          methods={methods}
          onFormSave={handleButtonSaveSubmit}
          onFormCancel={() => history.goBack()}
        />
      </UserForm>
    </SimpleLayout>
  );
}
