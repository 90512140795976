import { ReactElement } from "react";
import { MessageWithKey } from "./VideoPlayer";
import calculateVideoTime from "../../hooks/calculateVideoTime";
import "./VideoPlayer.css";
import Loader from "../layout/Loader";
import RemoveConfirmation from "../RemoveConfirmation";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function MessageItem({
  message,
  onDelete,
  onClick,
}: {
  message: MessageWithKey;
  onDelete: (messageId: string | number) => void;
  onClick: () => void;
}): ReactElement {
  return (
    <div
      className="vid-message w-full relative flex flex-row rounded-lg mt-2 justify-between bg-medium-gray bg-opacity-90 overflow-y-auto"
      key={message.message.messageID}
    >
      <div className="break-all px-3 py-4 w-full">
        {message.message.msg_text}
      </div>
      <div className={"flex flex-col w-20"}>
        {message.status === "pending" ? (
          <Loader inline />
        ) : (
          <div className="flex justify-center transition bg-ocean-blue-dark bg-opacity-20 hover:bg-red hover:bg-opacity-90 w-full pt-0.5 pb-0.5">
            <RemoveConfirmation
              className={"w-full"}
              text={"Är du säker på du vil ta bort anteckningen?"}
              small
              onRemove={() => onDelete(message.message.messageID)}
            />
          </div>
        )}
        {message.message.msg_position != null && (
          <button
            onClick={onClick}
            id="timestamp"
            className="flex justify-center text-xs w-full py-2 bg-ocean-blue bg-opacity-5 hover:bg-opacity-10 h-full transition"
          >
            <div className="flex items-center">
              <FontAwesomeIcon icon={faPlayCircle} className="mr-0.5" />
              {calculateVideoTime(message.message.msg_position)}
            </div>
          </button>
        )}
      </div>
    </div>
  );
}
