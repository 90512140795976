import { faFileUpload, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, ReactElement, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  useGetUserDocuments,
  postData,
  deleteData,
} from "../../../api/endpoints";
import {
  Module,
  PostResponse,
  UserDocument,
  UserStorageStats,
} from "../../../api/types";
import { ApiError } from "../../../api/ApiError";
import { useAuth } from "../../../hooks/ProvideAuth";
import {
  getValidationGenericErrorMessages,
  isEmpty,
  isNotEmpty,
} from "../../../util";
import Loader from "../../layout/Loader";
import RemoveConfirmation from "../../RemoveConfirmation";
import * as yup from "yup";
import ModuleInputWrapper from "../ModuleInputWrapper/ModuleInputWrapper";
import ModuleInputHeader from "../ModuleInputWrapper/ModuleInputHeader";
import LinkToFile from "../../LinkToFile";
import FormError from "../../formLayout/FormError";

interface UserDocumentData {
  udoc_userID: string;
  udoc_filename: string;
  udoc_type: string;
  udoc_moduleID: number;
  udoc_extension: string;
  udoc_url: string;
}

interface UserDocumentDataStorage {
  key: string;
  docData: UserDocumentData;
  status: "uploaded" | "pending" | "error";
  file?: File;
  errors?: string[];
}

const allowedFileType = [
  ".jpg",
  ".jpeg",
  ".png",
  ".doc",
  ".docx",
  ".pdf",
  ".mp3",
  ".mp4",
  ".wav",
  ".m4a",
  ".mov",
  ".wma",
];

const schema = yup.object({
  file: yup
    .mixed()
    .test("fileSize", "Filen är för stor", (value: File, context) => {
      const maxFileSize =
        "context" in context.options &&
        context.options.context != null &&
        "maxFileSize" in context.options.context
          ? (context.options.context["maxFileSize"] as number) * (1024 * 1024)
          : 2000000;
      return value.size <= maxFileSize;
    })
    .test("fileType", "Filformatet är ogilltigt", (value: File) => {
      const extension = value.name.split(".").pop()?.toLocaleLowerCase();
      return isNotEmpty(extension)
        ? allowedFileType.includes("." + extension)
        : false;
    })
    .test(
      "duplicate",
      "Det går inte att ladda upp filer med samma namn",
      (value: File, context) => {
        return "context" in context.options &&
          context.options.context != null &&
          "duplicate" in context.options.context
          ? false
          : true;
      }
    ),
});

export default function UserDocumentUploader({
  module,
}: {
  module: Module;
}): ReactElement {
  const auth = useAuth();
  const userID = auth?.user?.userID != null ? auth.user.userID.toString() : "";
  const query = useGetUserDocuments({
    filter: {
      udoc_userID: userID,
      udoc_type: "RAD",
      udoc_moduleID: module.moduleID.toString(),
    },
  });

  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      return (
        <UserDocumentUploadForm
          userDocuments={query.data.items}
          userStats={query.data.userStorageStats}
          userId={userID}
          module={module}
        />
      );
  }
}

function UserDocumentUploadForm({
  userDocuments,
  userId,
  module,
  userStats,
}: {
  userDocuments: UserDocument[];
  userId: string;
  module: Module;
  userStats?: UserStorageStats;
}): ReactElement {
  const queryClient = useQueryClient();
  const [userStorageStats, setUserStorageStats] = useState(userStats);
  const addMutation = useMutation((file: FormData) =>
    postData<{
      userDocument: UserDocument;
      userStorageStats: UserStorageStats;
    }>("user-documents", file)
  );

  const isSaving = useRef(false);

  const [documents, setDocuments] = useState<UserDocumentDataStorage[]>(
    userDocuments.map((ud) => ({
      key: `${ud.udoc_userID}-${ud.udoc_type}-${ud.udoc_moduleID}-${ud.udoc_filename}`,
      docData: {
        udoc_userID: ud.udoc_userID.toString(),
        udoc_filename: ud.udoc_filename,
        udoc_type: ud.udoc_type,
        udoc_moduleID: ud.udoc_moduleID,
        udoc_extension: ud.udoc_extension,
        udoc_url: ud.udoc_url,
      },
      status: "uploaded",
    }))
  );

  useEffect(() => {
    const save = async (): Promise<void> => {
      isSaving.current = true;
      let unsaved = documents.find((ud) => ud.status === "pending");
      while (unsaved != null) {
        const key = unsaved.key;
        if (unsaved.file != null) {
          const form = new FormData();
          form.append("udoc_userID", unsaved.docData.udoc_userID);
          form.append("udoc_type", unsaved.docData.udoc_type);
          form.append(
            "udoc_moduleID",
            unsaved.docData.udoc_moduleID.toString()
          );
          form.append("file", unsaved.file);
          await addMutation
            .mutateAsync(form)
            .then(
              (
                res:
                  | {
                      userDocument: UserDocument;
                      userStorageStats: UserStorageStats;
                    }
                  | PostResponse
              ) => {
                if ("errorsItemized" in res) {
                  const documentIndex = documents.findIndex(
                    (ud) => ud.key === key
                  );
                  let errors = getValidationGenericErrorMessages(
                    res.errorsItemized
                  );

                  if (
                    Object.keys(res.errorsItemized).includes("UploadedFile")
                  ) {
                    errors = [
                      ...errors,
                      ...res.errorsItemized.UploadedFile.map((e) => e),
                    ];
                  }

                  setDocuments((documents) => {
                    documents[documentIndex].status = "error";
                    documents[documentIndex].errors = errors;
                    return [...documents];
                  });
                } else {
                  setUserStorageStats(res.userStorageStats);
                  const documentIndex = documents.findIndex(
                    (ud) => ud.key === key
                  );
                  setDocuments((documents) => {
                    documents[documentIndex].status = "uploaded";
                    documents[documentIndex].docData.udoc_url =
                      res.userDocument.udoc_url;
                    return [...documents];
                  });
                  void queryClient.invalidateQueries([
                    "user-documents",
                    documents[documentIndex].docData.udoc_userID,
                    "RAD",
                    documents[documentIndex].docData.udoc_moduleID,
                  ]);
                }
              }
            )
            .catch((e: ApiError) => {
              const documentIndex = documents.findIndex((ud) => ud.key === key);
              setDocuments((documents) => {
                documents[documentIndex].status = "error";
                return [...documents];
              });
            });

          unsaved = documents.find((ud) => ud.status === "pending");
        }
      }
      isSaving.current = false;
    };
    if (!isSaving.current) {
      void save();
    }
  }, [addMutation, documents, queryClient]);

  const handleUploadFiles = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files != null && e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        void addDocument(file);
      }
    }
  };

  const addDocument = async (file: File): Promise<void> => {
    const splitName = file.name.split(".");
    const extension = splitName.pop();
    const currentFileName = splitName.join(".");
    let key = `${userId}-RAD-${module.moduleID}-${file.name}`;

    const validateContext: {
      context?: { maxFileSize?: number; duplicate?: boolean };
    } =
      userStorageStats != null
        ? { context: { maxFileSize: userStorageStats.megabytesAvailable } }
        : {};

    const duplicateFileNames = documents.filter((d) =>
      d.docData.udoc_filename.includes(currentFileName)
    );

    if (duplicateFileNames.length > 0) {
      key += duplicateFileNames.length > 0 ? `-${Date.now()}` : "";
      if (validateContext.context == null) {
        validateContext.context = { duplicate: true };
      } else {
        validateContext.context.duplicate = true;
      }
    }

    const newDocument: UserDocumentDataStorage = {
      key,
      docData: {
        udoc_userID: userId,
        udoc_filename: file.name,
        udoc_type: "RAD",
        udoc_extension: isNotEmpty(extension) ? extension : file.type,
        udoc_moduleID: module.moduleID,
        udoc_url: "",
      },
      file,
      status: "pending",
    };

    await schema
      .validate({ file }, validateContext)
      .catch((e: yup.ValidationError) => {
        newDocument.status = "error";
        newDocument.errors = e.errors;
      });

    setDocuments((files) => [...files, newDocument]);
  };

  const removeDocument = (key: string): void => {
    const doc = documents.find((ud) => ud.key === key);
    if (doc != null) {
      if (doc.status === "error") {
        setDocuments((files) => [...files.filter((f) => f.key !== key)]);
      } else {
        deleteData<PostResponse>(
          `user-documents?udoc_userID=${doc.docData.udoc_userID}&udoc_type=RAD&udoc_moduleID=${doc.docData.udoc_moduleID}&udoc_filename=${doc.docData.udoc_filename}`
        )
          .then((res: PostResponse) => {
            if (res.userStorageStats != null) {
              setUserStorageStats(res.userStorageStats);
            }
            setDocuments((files) => [...files.filter((f) => f.key !== key)]);
            void queryClient.invalidateQueries([
              "user-documents",
              doc.docData.udoc_userID,
              "RAD",
              doc.docData.udoc_moduleID,
            ]);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  return (
    <ModuleInputWrapper>
      <ModuleInputHeader title="Bifogade dokument">
        <div className="text-dark-gray pt-2">
          Bifoga gärna dokumentation, till exempel foton eller planerings- och
          utvärderingsdokument som synliggör det ni gjort och det resultat ni
          uppnått i den aktuella modulen.
        </div>
      </ModuleInputHeader>

      <div className="flex flex-col sm:flex-col lg:flex-row border gap-8 bg-white relative p-8">
        <div className="w-auto ">
          <form className="w-full h-full ">
            <input
              id="files"
              name="files"
              type="file"
              multiple
              className="hidden"
              onChange={(e) => handleUploadFiles(e)}
              accept={allowedFileType.join(",")}
            />
            <label
              htmlFor="files"
              className="file-upload-label block bg-medium-gray border border-dark-gray border-dashed py-4 px-2 text-center rounded hover:bg-white cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faFileUpload}
                size="8x"
                className="m-4 file-upload-icon"
              />
              <div className="text-center px-4 my-4 font-bold text-blue-gray">
                Klicka här för att ladda upp dokument/fil
              </div>
            </label>
            <div className="mt-2">
              <div>
                Uppladdade filer: {userStorageStats?.filesUploaded} av{" "}
                {userStorageStats != null
                  ? userStorageStats.filesUploaded +
                    userStorageStats.filesRemaining
                  : 0}{" "}
                möjliga
              </div>
              <div>
                Tillgängligt utrymme: {userStorageStats?.megabytesAvailable}MB
              </div>
            </div>
          </form>
        </div>
        <div className="w-auto flex-1 flex flex-col divide-dark-gray divide-y">
          {documents.map((f) => (
            <div key={f.key} className="flex flex-row relative w-full p-4 pl-0">
              <div className="mr-8">
                <div
                  className={`${
                    f.status === "error" ? "bg-red" : "bg-soft-mint"
                  } h-20 w-20 rounded flex items-center justify-center`}
                >
                  <span className="text-white font-bold self-center text-center lowercase">
                    {f.docData.udoc_extension}
                  </span>
                </div>
              </div>
              <div className="flex-auto flex-row">
                <div className="font-bold text-blue-gray break-all">
                  {isEmpty(f.docData.udoc_url) ? (
                    f.docData.udoc_filename
                  ) : (
                    <LinkToFile
                      path={`${
                        isNotEmpty(process.env.REACT_APP_PERSISTENTDATA_URL)
                          ? process.env.REACT_APP_PERSISTENTDATA_URL
                          : ""
                      }${f.docData.udoc_url}`}
                      displayName={f.docData.udoc_filename}
                      displayIcon={false}
                    />
                  )}
                </div>

                <div>
                  {f.status === "uploaded" && (
                    <div className="mt-2 text-blue-gray">Uppladdad</div>
                  )}

                  {f.status === "error" && (
                    <div className="mt-2  text-blue-gray">
                      Det gick inte att ladda upp filen!
                      {f.errors == null || f.errors.length < 0 ? (
                        <div className="text-blue-gray">
                          Något gick fel vid uppladdning av filen. Prova med
                          någon anna fil.
                        </div>
                      ) : (
                        <div>
                          {f.errors.map((e, index) => (
                            <FormError key={`${index}-${e}`} message={e} />
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {f.status === "pending" ? (
                <FontAwesomeIcon icon={faSpinner} pulse />
              ) : (
                <RemoveConfirmation
                  text="Är du säker på att du vill ta bort dokumentet?"
                  onRemove={() => removeDocument(f.key)}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </ModuleInputWrapper>
  );
}
