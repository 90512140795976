import { PostResponse } from "./types";

export class ApiError extends Error {
  readonly statusCode: number;
  readonly status: string;
  readonly details: PostResponse;

  constructor(response: Response, postResponse: PostResponse) {
    super(response.statusText);
    this.status = response.statusText;
    this.statusCode = response.status;
    this.details = postResponse;
  }
}
