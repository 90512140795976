import { createContext, ReactElement, ReactNode, useContext } from "react";
/**
 * Export ReadOnlyContext
 */
const ReadOnlyContext = createContext<boolean>(false);
export function useIsReadOnly(): boolean {
  return useContext(ReadOnlyContext);
}

export function ReadOnlyProvider({
  value,
  children,
}: {
  value?: boolean;
  children: ReactNode;
}): ReactElement {
  return (
    <ReadOnlyContext.Provider value={value != null ? value : false}>
      {children}
    </ReadOnlyContext.Provider>
  );
}
