import { ReactElement, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { deleteData, putData, useGetAccountPageUser } from "../api/endpoints";
import { User } from "../api/types";
import { useHistory } from "react-router";
import Header from "../components/layout/Header";
import UserForm from "../forms/UserForm";
import { useAuth } from "../hooks/ProvideAuth";
import {
  getValidationGenericErrorMessages,
  isEmpty,
  isNotEmpty,
} from "../util";
import { ApiError } from "../api/ApiError";
import { SimpleLayout } from "../components/layout/SimpleLayout";
import { UserFormData } from "../forms/Users/user-form-types";
import { yupResolver } from "@hookform/resolvers/yup";
import EditSubtitle from "../components/layout/EditSubtitle";
import { useConfirmDialog } from "../hooks/ProvideConfirmDialog";
import { signout } from "../api/endpoints";

import { schema } from "../forms/Users/users-schema";
import UserFormFields from "../forms/Users/UserFormFields";
import FormActionButtons from "../components/formLayout/FormActionButtons";
import Loader from "../components/layout/Loader";
import { IconButtonLink } from "../components/Buttons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import FormError from "../components/formLayout/FormError";
import inMemoryToken from "./../hooks/TokenManager";
import { invalidateUsers } from "../api/InvalidateQueriesHelper";

export default function AccountPage(): ReactElement {
  const history = useHistory();
  const auth = useAuth();

  const query = useGetAccountPageUser(
    auth?.user != null ? auth.user.userID.toString() : ""
  );

  if (auth?.user == null) {
    auth?.signout();
    history.push("logga-in");
    return <></>;
  }

  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      return <EditUserForm user={query.data} />;
  }
}

function EditUserForm(props: { user: User }): ReactElement {
  const auth = useAuth();
  const history = useHistory();
  const confirmationDialog = useConfirmDialog();

  const [errors, setErrors] = useState<string[]>([]);
  const [exportDataErrors, setExportDataErrors] = useState("");
  const queryClient = useQueryClient();
  const mutation = useMutation((formData: URLSearchParams) =>
    putData<User>(`users/${props.user.userID}`, formData)
  );

  const formDefaults = { defaultValues: {} };

  const userRoles = props.user.userRoles != null ? props.user.userRoles : [];
  formDefaults.defaultValues = {
    usr_firstname: props.user.usr_firstname,
    usr_lastname: props.user.usr_lastname,
    usr_email: props.user.usr_email,
    ...(userRoles.length > 0 && {
      userRoles: userRoles.map((ur) => {
        return {
          role_name: ur.role_name,
          role_organizationID:
            ur.role_organizationID != null ? ur.role_organizationID : null,
          role_userID: ur.role_userID,
          role_clientID: ur.role_clientID,
          previouslySaved: true,
        };
      }),
    }),
  };

  const methods = useForm<UserFormData>({
    ...formDefaults,
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async (formData: FieldValues): Promise<void> => {
    const params: URLSearchParams = new URLSearchParams(formData);

    if (
      ("usr_password_plain" in formData &&
        isEmpty(formData["usr_password_plain"])) ||
      ("usr_confirmed_password" in formData &&
        isEmpty(formData["usr_confirmed_password"]))
    ) {
      params.delete("usr_password_plain");
      params.delete("usr_confirmed_password");
    }

    await mutation
      .mutateAsync(params)
      .then((user) => {
        void queryClient.invalidateQueries({
          predicate: invalidateUsers,
        });
        void queryClient.invalidateQueries([
          "users",
          props.user.userID.toString(),
        ]);
        if (auth != null) {
          auth.updateUser(user);
          methods.reset({
            usr_firstname: user.usr_firstname,
            usr_lastname: user.usr_lastname,
            usr_email: user.usr_email,
          });
        }
      })
      .catch((error: ApiError) => {
        setErrors(
          getValidationGenericErrorMessages(error.details.errorsItemized)
        );
      });
  };

  const handleExportMyDataClicked = (): void => {
    // Handle export request differently the all other. Doesn't need body to be parsed into json

    const config: RequestInit = { method: "GET", credentials: "include" };

    if (isEmpty(process.env.REACT_APP_API_URL)) {
      throw new Error("Missing base url");
    }

    const baseUrl = process.env.REACT_APP_API_URL;

    const token = inMemoryToken.getToken();
    if (isNotEmpty(token)) {
      config.headers =
        config.headers == null
          ? { Authorization: `Bearer ${token}` }
          : { ...config.headers, ...{ Authorization: `Bearer ${token}` } };
    }
    void fetch(baseUrl + "/users/account-export?download=1", config)
      .then((r) => r.blob()) // Convert the data into a blob
      .then((blob: BlobPart) => {
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "förskoleutveckling-konto-export.pdf";

        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode?.removeChild(link);
      })
      .catch((r) => {
        setExportDataErrors(
          "Något gick fel när din export skulle hämtas. Kontakt din administratör för att få hjälp."
        );
      });
  };
  const handleDeleteButtonClicked = async (): Promise<void> => {
    await confirmationDialog
      .getConfirmation({
        title: "Är du säker på att du vill radera ditt konto?",
        message:
          "Ditt konto, all din data och dina loggar kommer att förvinna. Åtgärden går inte att ångra.",
      })
      .then((confirmed: boolean) => {
        if (confirmed) {
          deleteData<void>(`users/${props.user.userID}`)
            .then(() => {
              void queryClient.invalidateQueries({
                predicate: invalidateUsers,
              });
              void queryClient.removeQueries([
                "users",
                props.user.userID.toString(),
              ]);

              // Account is deleted but needs to be signed out
              signout()
                .then(() => {
                  auth?.signout();
                  history.push("/logga-in", {
                    alert: {
                      message: "Ditt konto har blivit raderat.",
                      color: "success",
                    },
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
  };

  return (
    <SimpleLayout>
      <EditSubtitle
        deletedButtonText="Radera konto"
        onDeletedClicked={handleDeleteButtonClicked}
      />
      <Header title={props.user.usr_firstname}></Header>
      <div className="flex items-center self-center space-x-2 mt-2">
        <IconButtonLink
          className="font-bold hover:text-dull-mint"
          icon={faDownload}
          content="Exportera min data"
          onClick={handleExportMyDataClicked}
          size="medium"
        />
        <FormError message={exportDataErrors} />
      </div>
      <UserForm>
        <UserFormFields methods={methods} editForm={true} errors={errors} />

        <div>
          {errors.map((e) => (
            <FormError key={e} message={e} />
          ))}
        </div>

        <FormActionButtons
          methods={methods}
          onFormSave={handleFormSubmit}
          onFormCancel={() => history.goBack()}
        />
      </UserForm>
    </SimpleLayout>
  );
}
