import { ReactElement } from "react";
import styles from "./ProgressRing.module.css";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import Loader from "../layout/Loader";

const cx = classNames.bind(styles);

interface ProgressRingProps {
  radius: number;
  stroke: number;
  progress: number;
  number: number;
  loading: boolean;
  label?: string;
  caption?: string;
  url?: string;
  error: boolean;
}

const ProgressRing = ({
  radius,
  stroke,
  progress,
  number,
  loading,
  label,
  caption,
  url,
  error,
}: ProgressRingProps): ReactElement => {
  const history = useHistory();
  const normalizedRadius = radius - stroke * 0.5;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  const handleClick = (): void => {
    if (url !== undefined) {
      history.push(url);
    }
  };

  return (
    <div
      className={cx(
        styles.base,
        caption !== undefined ? styles.withCaption : ""
      )}
      style={{ width: radius * 2 }}
    >
      <svg
        height={radius * 2}
        width={radius * 2}
        onClick={handleClick}
        className={cx(url !== undefined ? styles.clickable : "")}
      >
        <circle
          stroke="rgb(246,246,246)"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={
            circumference.toString() + " " + circumference.toString()
          }
          style={{
            strokeDashoffset: circumference - 1 * circumference,
            boxSizing: "border-box",
          }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        {!loading && (
          <circle
            stroke="#c7e1c9"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={
              circumference.toString() + " " + circumference.toString()
            }
            style={{
              strokeDashoffset: !isNaN(strokeDashoffset) ? strokeDashoffset : 0,
              boxSizing: "border-box",
            }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        )}
      </svg>

      <div className={styles.centerText}>
        {loading ? (
          <Loader inline />
        ) : (
          <>
            <h1>{number}</h1>
            <p>{label}</p>
          </>
        )}
      </div>

      <div className={styles.bottomText}>
        <p className="uppercase">{caption}</p>
      </div>
    </div>
  );
};

export default ProgressRing;
