import { forwardRef, InputHTMLAttributes, ReactElement, Ref } from "react";

export const FormInput = forwardRef(
  (
    props: InputHTMLAttributes<HTMLInputElement>,
    ref: Ref<HTMLInputElement>
  ): ReactElement => {
    return <input className="mb-4" {...props} ref={ref} />;
  }
);
