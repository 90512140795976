import { ReactElement } from "react";
import FormError from "../../components/formLayout/FormError";
import { FormInput } from "../../components/formLayout/FormInput";
import FormInputWrapper from "../../components/formLayout/FormInputWrapper";
import FormLabel from "../../components/formLayout/FormLabel";
import { FormSectionBody } from "../../components/formLayout/FormSection";
import { UseFormReturn } from "react-hook-form";
import { UserFormData } from "./user-form-types";

export default function UserFormFields(props: {
  methods: UseFormReturn<UserFormData>;
  editForm?: boolean;
  errors: string[];
}): ReactElement {
  return (
    <FormSectionBody>
      <FormInputWrapper>
        <FormLabel htmlFor="usr_firstname" label="Förnamn" />
        <FormInput {...props.methods.register("usr_firstname")} type="text" />
        <FormError
          message={props.methods.formState.errors.usr_firstname?.message}
        />
      </FormInputWrapper>
      <FormInputWrapper>
        <FormLabel htmlFor="usr_lastname" label="Efternamn" />
        <FormInput {...props.methods.register("usr_lastname")} type="text" />
        <FormError
          message={props.methods.formState.errors.usr_lastname?.message}
        />
      </FormInputWrapper>

      <FormInputWrapper>
        <FormLabel htmlFor="usr_email" label="E-post" />
        <FormInput {...props.methods.register("usr_email")} type="text" />
        <FormError
          message={props.methods.formState.errors.usr_email?.message}
        />
      </FormInputWrapper>
      <FormInputWrapper>
        <FormLabel htmlFor="usr_password_plain" label="Nytt lösenord" />
        <FormInput
          {...props.methods.register("usr_password_plain")}
          type="password"
        />
        {props.editForm ? (
          <div className="text-sm mt-2">
            Lämna tomt för att behålla befintligt lösenord
          </div>
        ) : (
          ""
        )}
        <FormError
          message={props.methods.formState.errors.usr_password_plain?.message}
        />
      </FormInputWrapper>
      <FormInputWrapper>
        <FormLabel
          htmlFor="usr_confirmed_password"
          label="Bekräfta nytt lösenord"
        />
        <FormInput
          {...props.methods.register("usr_confirmed_password")}
          type="password"
        />
        <FormError
          message={
            props.methods.formState.errors.usr_confirmed_password?.message
          }
        />
      </FormInputWrapper>
    </FormSectionBody>
  );
}
