import { useMutation } from "react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { postData, putData } from "../api/endpoints";

export default function useAutoSave<T, R>(
  endpoint: string,
  objectId: string,
  hasUnsavedChanges: (data: R, obj: T) => boolean
): {
  lastSavedObject: T | undefined;
  unsavedChanges: boolean;
  setSaveObject(objData: R): void;
} {
  const [lastSavedObject, setLastSavedObject] = useState<T>();
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [saveInProgress, setSaveInProgress] = useState<R>();
  const [saveObject, setSaveObject] = useState<R>();
  const [hasErrors, setHasErrors] = useState(false);
  const editMutation = useMutation((data: string) => {
    return putData<T>(`${endpoint}/${objectId}`, data);
  });
  const addMutation = useMutation((data: string) =>
    postData<T>(endpoint, data)
  );

  useEffect(() => {
    if (saveObject === lastSavedObject) {
      // if they are both undefined
      setUnsavedChanges(false);
    } else if (saveObject === undefined || lastSavedObject === undefined) {
      setUnsavedChanges(true);
    } else {
      setUnsavedChanges(hasUnsavedChanges(saveObject, lastSavedObject));
    }
    setHasErrors(false);
  }, [saveObject, lastSavedObject, hasUnsavedChanges]);

  const addObject = useCallback(async (): Promise<void> => {
    await addMutation
      .mutateAsync(JSON.stringify(saveObject))
      .then((obj: T) => {
        if (!("errorsItemized" in obj)) {
          setLastSavedObject(obj);
        } else {
          setHasErrors(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setSaveInProgress(undefined);
  }, [addMutation, saveObject]);

  const editObject = useCallback(async (): Promise<void> => {
    try {
      const result = await editMutation.mutateAsync(JSON.stringify(saveObject));
      if (!("errorsItemized" in result)) {
        setLastSavedObject(result);
      } else {
        setHasErrors(true);
      }
    } catch (error) {
      console.log(error);
    }
    setSaveInProgress(undefined);
  }, [editMutation, saveObject]);

  useEffect(() => {
    if (
      !hasErrors &&
      unsavedChanges &&
      (saveInProgress == null ||
        JSON.stringify(saveObject) !== JSON.stringify(saveInProgress))
    ) {
      setSaveInProgress(saveObject);
      if (objectId.includes("new")) {
        if (!addMutation.isLoading) {
          void addObject();
        }
      } else {
        if (!editMutation.isLoading) {
          void editObject();
        }
      }
    }
  }, [
    hasErrors,
    saveObject,
    editMutation.isLoading,
    editObject,
    saveInProgress,
    objectId,
    unsavedChanges,
    addObject,
    addMutation.isLoading,
  ]);

  return useMemo(() => ({ lastSavedObject, unsavedChanges, setSaveObject }), [
    lastSavedObject,
    unsavedChanges,
    setSaveObject,
  ]);
}
