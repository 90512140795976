import { ReactElement, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { ClientData } from "../api/form-types";
import FormCheckboxWrapper from "../components/formLayout/FormCheckboxWrapper";
import {
  FormSection,
  FormSectionBody,
} from "../components/formLayout/FormSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { ClientSetting } from "../api/types";
import { useSettings } from "../providers/SettingsProvider";

export function SettingsFormSection({
  methods,
  clientSettings,
}: {
  methods: UseFormReturn<ClientData>;
  clientSettings: ClientSetting | null;
}): ReactElement | null {
  const settingsProvider = useSettings();

  const populatedSettingsArray = useMemo((): {
    label: string;
    value: boolean | null;
    setting_ref: string;
  }[] => {
    const settingsMap = settingsProvider.defaultSettings;
    const futureSettingsArray = [];

    for (const setting_ref in settingsMap) {
      const populatedSetting = {
        label: settingsMap[setting_ref].label,
        setting_ref: setting_ref,
        value:
          clientSettings?.[setting_ref] != null
            ? clientSettings[setting_ref]
            : settingsMap[setting_ref].defaultValue,
      };

      futureSettingsArray.push(populatedSetting);
    }

    return futureSettingsArray;
  }, [clientSettings, settingsProvider.defaultSettings]);

  return (
    <>
      <FormSection title="Inställningar">
        <FormSectionBody className="w-2/3">
          <FormCheckboxWrapper title="Behörigheter">
            <div className="flex flex-col flex-wrap mt-8 mb-12">
              {populatedSettingsArray.map((setting) => (
                <label key={setting.setting_ref}>
                  <input
                    defaultChecked={
                      setting.value === null ? undefined : setting.value
                    }
                    type="checkbox"
                    {...methods.register(
                      `cl_settings.${setting.setting_ref}` as const
                    )}
                  />
                  {setting.label}
                  <span className="fancy-checkbox block">
                    <span className="fc-icon hidden block leading-none">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-blue-gray"
                      />
                    </span>
                  </span>
                </label>
              ))}
            </div>
          </FormCheckboxWrapper>
        </FormSectionBody>
      </FormSection>
    </>
  );
}
