import { ReactElement, ReactNode } from "react";
import { isNotEmpty } from "../../../util";

export default function ModuleInputWrapper(props: {
  children: ReactNode;
  className?: string;
}): ReactElement {
  return (
    <div
      className={`mt-4 ${isNotEmpty(props.className) ? props.className : ""}`}
    >
      {props.children}
    </div>
  );
}
