import { ChangeEvent, ReactElement, useMemo, useState } from "react";
import { ModuleProgressObjectData } from "../../api/form-types";
import { getMediaByRefAndType, isEmpty, isNotEmpty } from "../../util";
import LinkToFile from "../LinkToFile";
import LinkToAudio from "../LinkToAudio";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { ModuleLogForm } from "./ModuleLogForm";
import ModuleProgressChecked, {
  useModuleProgressForm,
} from "./ModuleProgressCheckbox";
import inMemoryToken from "./../../hooks/TokenManager";

import * as yup from "yup";
import ModuleLogPropositionForm from "./ModuleLogPropositionForm";
import { Media, Module } from "../../api/types";
import { useIsReadOnly } from "./LectureModule/ReadOnyProvider";
import ModuleInputWrapper from "./ModuleInputWrapper/ModuleInputWrapper";
import ModuleInputHeader from "./ModuleInputWrapper/ModuleInputHeader";
import UserDocumentUploader from "./LectureModule/UserDocumentUploader";
import ExtraModuleMaterial from "./ExtraModuleMaterial";
import { useSettings } from "../../providers/SettingsProvider";
import TopImage from "../layout/TopImage";
import FormError from "../formLayout/FormError";
import { IconButtonLink } from "../Buttons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import ResourceHint from "../ResourcesHint";

const schema = yup.object({
  module_progressID: yup.string(),
  mprog_moduleID: yup.string(),
  mprog_step: yup.string(),
  mprog_completed: yup.boolean().nullable(),
  mprog_data: yup.string().nullable(),
});

export default function LectureModule({
  module,
  readOnlyInput,
}: {
  module: Module;
  readOnlyInput?: boolean;
}): ReactElement {
  return (
    <div>
      <TopImage imagePath="/hallbarhet-global-mangfald.jpg" />
      <div className="module-content-wrapper">
        <div className="mb-12">
          <h4>Modul {module.m_number}</h4>
          <h1>{module.m_title}</h1>
        </div>

        <Introduction module={module} />
        <WorkProcess module={module} />
        <ModuleLog module={module} />
        <Lecture module={module} />
        <StudyMaterial module={module} />
        <ReflextionAnalysis module={module} />
        <Feedback module={module} />
      </div>
    </div>
  );
}

function Introduction(props: { module: Module }): ReactElement {
  const chapterAudioMedia = useMemo(
    () =>
      getMediaByRefAndType(props.module.all_media, "chapter_audio", "audio"),
    [props.module.all_media]
  );
  return (
    <div className="module-block">
      <div className="intro">
        <h4>Presentation av kapitel {props.module.m_number}</h4>
        <div>{props.module.m_intro_text}</div>
        {chapterAudioMedia.length > 0 && (
          <div className="flex space-x-8">
            {chapterAudioMedia.map((media: Media) => (
              <LinkToAudio
                key={media.mediaID}
                path={media.media_url}
                displayName={media.media_title}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
function WorkProcess(props: { module: Module }): ReactElement {
  const workProcessMedia = useMemo(
    () => getMediaByRefAndType(props.module.all_media, "workflow", "document"),
    [props.module.all_media]
  );
  return (
    <div className="module-block">
      <div className="intro">
        <h4>Arbetsgång</h4>
        <div>
          I dokumentet "Arbetsgång för modul {props.module.m_number}" finner du
          en översikt, ett förslag på ett upplägg för arbetet i den aktuella
          modulen. Det är en fördel om ni läser det här dokumentet innan ni
          påbörjar en ny modul så att ni innan ni startar får en god uppfattning
          om till exempel tidsåtgången och om hur ni bäst strukturerar arbetet i
          den aktuella modulen. Har ni någon utvald person/personer som ska leda
          kompetensutvecklingen är det särskilt viktigt för den eller de att ta
          del av arbetsgången.
        </div>
        {/* <div>{props.module.m_workflow_text}</div> */}
        {workProcessMedia.map((media: Media) => (
          <LinkToFile
            key={media.mediaID}
            path={media.media_url}
            displayName={media.media_title}
          />
        ))}
      </div>
    </div>
  );
}

function ModuleLog(props: { module: Module }): ReactElement {
  const [exportDataErrors, setExportDataErrors] = useState("");
  const progress = props.module.moduleProgresses?.find(
    (p) => p.mprog_step === "main_module_logs"
  );

  const form = useModuleProgressForm(
    "main_module_logs",
    schema,
    props.module,
    true,
    progress
  );
  const readingLogMedia = useMemo(
    () =>
      getMediaByRefAndType(props.module.all_media, "reading_log", "document"),
    [props.module.all_media]
  );

  const getTodaysDate = (): string => {
    const todaysFullDate = new Date();
    const todaysDate = todaysFullDate.getDate();
    const formatedDate = todaysDate < 10 ? `0${todaysDate}` : todaysDate;
    return `${todaysFullDate.getFullYear()}-${
      todaysFullDate.getMonth() + 1
    }-${formatedDate}_${todaysFullDate.getHours()}-${todaysFullDate.getMinutes()}-${todaysFullDate.getSeconds()}`;
  };

  const handleExportMyDataClicked = (): void => {
    // Handle export request differently the all other. Doesn't need body to be parsed into json

    const config: RequestInit = { method: "GET", credentials: "include" };

    if (isEmpty(process.env.REACT_APP_API_URL)) {
      throw new Error("Missing base url");
    }

    const baseUrl = process.env.REACT_APP_API_URL;

    const token = inMemoryToken.getToken();
    if (isNotEmpty(token)) {
      config.headers =
        config.headers == null
          ? { Authorization: `Bearer ${token}` }
          : { ...config.headers, ...{ Authorization: `Bearer ${token}` } };
    }
    void fetch(
      baseUrl +
        `/users/module-export?download=1&moduleID=${props.module.moduleID}`,
      config
    )
      .then((r) => r.blob()) // Convert the data into a blob
      .then((blob: BlobPart) => {
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `förskoleutveckling_läslogg_modul_${
          props.module.moduleID
        }_${getTodaysDate()}.pdf`;

        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode?.removeChild(link);
      })
      .catch((r) => {
        setExportDataErrors(
          "Något gick fel när din export skulle hämtas. Kontakt din administratör för att få hjälp."
        );
      });
  };

  return (
    <div className="module-block">
      <div className="intro">
        <h3 className="flex justify-between items-center">
          Läsning och läslogg
          <IconButtonLink
            className="font-bold hover:text-dull-mint ml-4 text-sm float-right"
            icon={faDownload}
            content="Exportera läslogg"
            onClick={handleExportMyDataClicked}
            size="medium"
          />
        </h3>
        <FormError message={exportDataErrors} />
        <div>
          <i>Ett stöd för fördjupning</i>
          <p>
            Läsningen av boken Mångfaldens förskola är huvudingrediensen i den
            här kompetensutvecklingsinsatsen. Ett stöd under läsningen och
            bearbetningen av texten kan vara att skriva läslogg. Det hjälper en
            att stanna upp och närläsa delar av kapitlets innehåll, att komma
            ner på djupet i texten och att sortera ut det mest väsentliga för
            just mig/oss. När du använder läsloggen reflekterar du över och
            analyserar textinnehållet på ett sätt som också bidrar till att det
            blir lättare att tänka kring konsekvenser för praktiken. Vad som är
            klokt att göra i den pedagogiska praktiken sett till det du läst och
            tänkt om. Låt frågeställningarna i era läsloggar leda er i vad som
            är viktigt för just er, för att ni ska nå de mål ni formulerat för
            kompetensutvecklingsinsatsen.
          </p>
          <div className="mt-4">
            <i>Läsloggen kan användas av alla</i>
            <p>
              Är du verksamhetschef, rektor, specialpedagog, förskollärare,
              barnskötare, pedagogista, ateljérista eller student? Läsloggen kan
              användas av er alla oavsett vilket uppdrag du har. Var bara lite
              kreativ i tanken sett till ditt ansvar och din roll när du läser
              ett kapitel och bearbetar det genom läsloggen.
            </p>
          </div>
          <div className="mt-4">
            <i>
              För ytterligare stöttning i att använda läsloggen, se dokumenten
              nedan
            </i>
            <div className="flex space-x-8">
              <LinkToFile
                path={`https://media.forskoleutveckling.nu/persistence/media/mangfaldens-forskole/St%C3%B6ttande-text-till-l%C3%A4sloggen.pdf`}
                displayName="Stöttande text till läsloggen"
              />
              <LinkToFile
                path={`https://media.forskoleutveckling.nu/persistence/media/mangfaldens-forskole/Fördjupande%20samtal%20genom%20läslogg_1.0.docx`}
                displayName="Fördjupande samtal genom läslogg/loggbok"
              />
            </div>
          </div>
          <div className="mt-8">
            <div>
              Om du väljer att dokumentera dina tankar på annat sätt än via
              läsloggen här på sidan laddar du upp dokumentationen under
              rubriken{" "}
              <a
                className="underline underline-offset-1"
                href="#reflection-and-analysis"
                rel="noopener noreferrer"
              >
                "Reflektera och analysera"
              </a>
              . Därefter markerar du att läsloggen är genomförd.
            </div>
          </div>
        </div>
      </div>
      {readingLogMedia.map((media: Media) => (
        <LinkToFile
          key={media.mediaID}
          path={media.media_url}
          displayName={media.media_title}
        />
      ))}
      <div className="module-reading-log">
        <ModuleLogForm module={props.module} />
        <div className="mt-12">
          <i>
            Omformulera dina citat och tankar till en eller flera relevanta
            frågeställningar som bidrar till fördjupning, förändring/förbättring
          </i>
          <ModuleLogPropositionForm module={props.module} />
        </div>
        <form>
          <ModuleProgressChecked step="main_module_logs" form={form} />
        </form>
      </div>
    </div>
  );
}

const lectureSchema = yup.object({
  module_progressID: yup.string(),
  mprog_moduleID: yup.string(),
  mprog_step: yup.string(),
  mprog_completed: yup.boolean().nullable(),
  mprog_data: yup.string(),
});
function Lecture(props: { module: Module }): ReactElement {
  const progress = props.module.moduleProgresses?.find(
    (p) => p.mprog_step === "m_lecture_video"
  );

  const media = useMemo(
    () => getMediaByRefAndType(props.module.all_media, "lecture", "video"),
    [props.module.all_media]
  );

  const hasAvailableResources = useMemo(
    () =>
      getMediaByRefAndType(props.module.all_media, "has-resources", "info")
        .length > 0,
    [props.module.all_media]
  );

  const data =
    progress != null && isNotEmpty(progress.mprog_data)
      ? (JSON.parse(progress.mprog_data) as ModuleProgressObjectData[])
      : null;

  const form = useModuleProgressForm(
    "m_lecture_video",
    lectureSchema,
    props.module,
    data != null
      ? data.filter((p: ModuleProgressObjectData) => p.reachedEnded != null)
          .length === media.length
      : false, // Condition that checks if this step meets the requirement.
    progress
  );

  // Callback that sets if requirement is met
  const handleOnEnded = (mediaId: number): void => {
    const data = form.methods.getValues("mprog_data");
    let progressData: ModuleProgressObjectData[] = [];
    let performSave = false;

    if (data != null && isNotEmpty(data)) {
      progressData = JSON.parse(data) as ModuleProgressObjectData[];
      const index = progressData.findIndex((pd) => pd.mediaId === mediaId);
      if (index < 0) {
        progressData.push({
          mediaId: mediaId,
          playbackPosition: 0,
          reachedEnded: new Date(),
        });
        performSave = true;
      } else if (progressData[index].reachedEnded == null) {
        progressData[index].reachedEnded = new Date();
        performSave = true;
      }
    } else {
      progressData.push({
        mediaId: mediaId,
        playbackPosition: 0,
        reachedEnded: new Date(),
      });
      performSave = true;
    }
    if (performSave) {
      void form.methods.setValue(`mprog_data`, JSON.stringify(progressData));
      void form.saveModuleProgress();
    }

    form.setMeetRequirement(
      progressData.filter(
        (p: ModuleProgressObjectData) => p.reachedEnded != null
      ).length === media.length
    );
  };

  return (
    <div className="module-block mt-10">
      <div className="intro">
        <h3>Film</h3>
        <div>
          Ha gärna boken tillgänglig när du tar del av filmerna eftersom jag
          hänvisar till olika sidor och textavsnitt. I filmerna ber jag dig
          också att pausa en stund för kollegial reflektion. Utöver det vill jag
          uppmuntra dig/er att på eget initiativ pausa filmen när du/ni känner
          behov av att stanna upp för samtal om det ni sett och hört. Läs gärna
          igenom samtalsunderlaget (se längre ner på sidan) innan du börjar
          titta på en film och ha det till hands under tiden du ser filmen.
        </div>
      </div>
      <div className=" pb-10">
        <div className="module-video">
          {media.map((media: Media) => (
            <VideoPlayer
              mediaID={media.mediaID}
              moduleID={props.module.moduleID}
              messages={
                props.module.messages
                  ? [
                      ...props.module.messages.filter(
                        (message) =>
                          message.msg_type === "LVC" &&
                          message.msg_mediaID === media.mediaID
                      ),
                    ]
                  : []
              }
              key={media.mediaID}
              videoUrl={media.media_url}
              posterUrl={
                isNotEmpty(media.media_thumbnail_url) &&
                media.media_thumbnail_url.includes("http")
                  ? media.media_thumbnail_url
                  : process.env.PUBLIC_URL + media.media_thumbnail_url
              }
              onEnded={() => handleOnEnded(media.mediaID)}
            />
          ))}
        </div>
        {hasAvailableResources && <ResourceHint className="my-12" />}
        <form>
          <ModuleProgressChecked step="m_lecture_video" form={form} />
        </form>
      </div>
    </div>
  );
}

function StudyMaterial(props: { module: Module }): ReactElement {
  const assignmentsMedia = useMemo(
    () =>
      getMediaByRefAndType(props.module.all_media, "assignments", "document"),
    [props.module.all_media]
  );
  return (
    <div className="module-block pb-12">
      <div className="intro">
        <h3>Stödmaterial</h3>
        {/* <div>{props.module.m_study_material_text}</div> */}
        <div>
          Här finner du olika typer av stödmaterial för fortsatt fördjupning av
          kapitel {props.module.m_number}
        </div>
      </div>
      <div>
        <div>
          <h4>Samtalsunderlag och uppgifter att pröva i praktiken</h4>
          {/* <div>{props.module.m_assignments_text}</div> */}
          <div>
            I dokumentet "Samtalsunderlag och uppgifter att pröva i praktiken"
            finns frågeställningar att reflektera över under tiden ni tittar på
            en film, efter att ni sett en film samt fördjupat innehållet i det
            aktuella kapitlet. Här finns även förslag på uppgifter att pröva i
            praktiken.
          </div>
          {assignmentsMedia.map((media: Media) => (
            <LinkToFile
              key={media.mediaID}
              path={media.media_url}
              displayName={media.media_title}
            />
          ))}
        </div>
        <ExtraModuleMaterial module={props.module} />
      </div>
    </div>
  );
}

const reflectionAnalysisSchema = yup.object({
  module_progressID: yup.string(),
  mprog_moduleID: yup.string(),
  mprog_step: yup.string(),
  mprog_completed: yup.boolean().nullable(),
  mprog_data: yup.string().max(1500),
});

function ReflextionAnalysis(props: { module: Module }): ReactElement {
  const isFormReadOnly = useIsReadOnly();
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const settings = useSettings();

  const progress = props.module.moduleProgresses?.find(
    (p) => p.mprog_step === "m_analysis_text"
  );

  const [textCount, setTextCount] = useState<number>(
    progress?.mprog_data != null ? progress.mprog_data.length : 0
  );
  const form = useModuleProgressForm(
    "m_analysis_text",
    reflectionAnalysisSchema,
    props.module,
    textCount > 500,
    progress
  );
  const text = form.methods.register("mprog_data");

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    void text.onChange(e);
    void form.methods.setValue(`mprog_data`, e.target.value);
    setTextCount(e.target.value.length);
    if (e.target.value.length > 500 !== form.meetRequirement)
      form.setMeetRequirement(e.target.value.length > 500);

    if (timer) {
      setTimer(undefined);
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        void form.saveModuleProgress();
      }, 1000)
    );
  };
  const defaultValue = form.methods.getValues("mprog_data");

  return (
    <>
      <div id="reflection-and-analysis" className="module-block pb-12">
        <div className="intro w-full">
          <h3> Reflektion och analys</h3>
          {/* <div>{props.module.m_analysis_text}</div> */}
          <div>
            <div className="mt-4">
              När alla moment är genomförda i en modul avslutar du modularbetet
              genom att formulera dig skriftligt i en kortfattad text. Det
              hjälper dig att se din egen lärprocess och allas röster blir
              hörda. Har du av någon anledning svårt för att skriva kommer du
              och din rektor eller annan ledare överens om hur du ska
              sammanfatta dina tankar och lärdomar. Kanske du kan spela in det
              du vill säga via telefonen eller annat verktyg så att den som
              leder arbetet får möjlighet att ta del av det du vill uttrycka.
            </div>
          </div>
        </div>
        <div className="reflection-analysis-text">
          <ModuleInputWrapper>
            <ModuleInputHeader title="Dokumentation">
              <div className="text-dark-gray pt-2">
                Dokumentera kortfattat de viktigaste insikterna, lärdomarna,
                förändringarna ni gjort/vill göra och vad det ni gjort gett för
                resultat. Jämför då och nu! Delge även vilken eventuell
                stöttning du själv kan behöva och av vem i den fortsatta
                kompetensutvecklingsinsatsen.
              </div>
            </ModuleInputHeader>
            <div className="border h-80">
              <textarea
                disabled={isFormReadOnly}
                defaultValue={defaultValue != null ? defaultValue : ""}
                maxLength={1500}
                className="w-full h-full resize-none p-2 text-lg"
                onBlur={text.onBlur}
                ref={text.ref}
                onChange={handleChange}
              ></textarea>
            </div>
            <div>{textCount}/1500 tecken</div>
          </ModuleInputWrapper>

          {settings.isEnabled("permissionRegUserUploadDocs") && (
            <UserDocumentUploader module={props.module} />
          )}

          <ModuleProgressChecked step="m_analysis_text" form={form} />
        </div>
      </div>
    </>
  );
}

function Feedback({ module }: { module: Module }): ReactElement {
  const feebackMessages = useMemo(() => {
    return module.messages != null
      ? module.messages.filter((m) => m.msg_type === "FBC")
      : [];
  }, [module]);

  return (
    <div className="module-block">
      <div className="intro">
        <h3>Återkoppling på reflektion och analys</h3>

        {feebackMessages.length > 0 &&
        isNotEmpty(feebackMessages[0].msg_text) ? (
          <div className="flex mt-8">
            <div className="rounded-full h-24 w-24 flex items-center justify-center bg-ocean-blue mr-4">
              <span className="w-full semi-bold text-xl text-white self-center text-center">
                {feebackMessages[0].user != null
                  ? feebackMessages[0].user.usr_firstname.charAt(0) +
                    feebackMessages[0].user.usr_lastname.charAt(0)
                  : ""}
              </span>
            </div>
            <div className="border rounded w-4/5">
              <textarea
                disabled={true}
                defaultValue={feebackMessages[0].msg_text}
                className="w-full h-full resize-none p-2 text-lg"
              ></textarea>
            </div>
          </div>
        ) : (
          <div>
            <div className="p-8">
              <div className="italic">
                "Om jag vill lyckas med att föra en människa mot ett bestämt mål
                måste jag först finna henne där hon är och börja just där"
              </div>
              <div>(Sören Kierkegaard).</div>
            </div>
            <div>
              Det här citatet går att omsätta både till mötet med barnen i
              förskolan och med vuxna. Genom varje användares skriftliga eller
              inspelade reflektion kan den som leder insatsen få en uppfattning
              om vad användaren förstår, var hen befinner sig i processen och
              vad hen kan behöva stöttning i. Den som leder processen kan få
              många nya tankar, kan anpassa ledarskapet och får själv möjlighet
              att utvecklas och växa.
            </div>
            <div className="mt-4">
              Rektor eller annan ledare läser de enskilda skriftliga
              reflektionerna och analyserna och ger om möjligt kort återkoppling
              till de enskilda deltagarna i insatsen. Besluta gemensamt om och
              hur ofta återkoppling ska ges. Ibland kan en mening räcka för att
              användaren ska känna sig sedd och bekräftad.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
