import { ReactElement } from "react";
import { isNotEmpty } from "../../util";
interface FormErrorProps {
  message: string | undefined;
  className?: string;
}

export default function FormError({
  message,
  className,
}: FormErrorProps): ReactElement {
  return (
    <p
      className={`text-red text-sm font-bold ${
        isNotEmpty(className) ? className : ""
      }`}
    >
      {isNotEmpty(message) ? message : ""}
    </p>
  );
}
