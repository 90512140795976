import { ReactElement, useMemo, useState } from "react";
import { PostResponse } from "../../../api/types";
import { setFormErrors } from "../../../util";
import { FeedbackCommentData, MessageData } from "../../../api/form-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FilledButton } from "../../Buttons";
import * as yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import { postData, putData } from "../../../api/endpoints";
import Loader from "../../layout/Loader";
import { Module, Message } from "../../../api/types";
import ModuleInputWrapper from "../ModuleInputWrapper/ModuleInputWrapper";
import ModuleInputHeader from "../ModuleInputWrapper/ModuleInputHeader";
import { useHistory } from "react-router";
import { useAuth } from "../../../hooks/ProvideAuth";
import FormError from "../../formLayout/FormError";

// FBC is short for FeedbackComment. See backend documentation for more details.

const feedBackSchema = yup.object({
  msg_text: yup
    .string()
    .trim()
    .required("Fältet för återkoppling måste innehålla text"),
});
export default function FeedbackForm({
  module,
  userId,
}: {
  module: Module;
  userId: number;
}): ReactElement {
  const history = useHistory<undefined | { previousPath: string }>();
  const auth = useAuth();
  const queryClient = useQueryClient();
  const redirectPath =
    history.location.state != null && "previousPath" in history.location.state
      ? history.location.state.previousPath
      : "/genomforda-moduler";

  const feedbackMessages = useMemo(
    () =>
      module.messages != null
        ? module.messages.filter((m) => m.msg_type === "FBC")
        : [],
    [module]
  );

  const [arcMessageId, setArcMessageId] = useState<number | undefined>(
    feedbackMessages.length > 0 ? feedbackMessages[0].messageID : undefined
  );

  const addMutation = useMutation((data: string) =>
    postData<Message | PostResponse>("messages", data)
  );

  const editMutation = useMutation((data: string) =>
    putData<Message | PostResponse>(
      `messages/${arcMessageId != null ? arcMessageId.toString() : ""}`,
      data
    )
  );

  const handleSubmit = (messageData: MessageData): void => {
    if (arcMessageId != null) {
      // EDIT
      void editMutation
        .mutateAsync(JSON.stringify(messageData))
        .then((d: Message | PostResponse) => {
          if ("errorsItemized" in d) {
            setFormErrors(d.errorsItemized, methods);
          } else {
            void queryClient.invalidateQueries([
              userId.toString(),
              module.moduleID.toString(),
            ]);

            history.push(redirectPath);
          }
        })
        .catch((e) => console.log(e));
    } else {
      void addMutation
        .mutateAsync(JSON.stringify(messageData))
        .then((d: Message | PostResponse) => {
          if ("errorsItemized" in d) {
            setFormErrors(d.errorsItemized, methods);
          } else if ("messageID" in d) {
            setArcMessageId(d.messageID);
            void queryClient.invalidateQueries([
              "module-progresses",
              "completed-modules",
            ]);
            void queryClient.invalidateQueries([
              userId.toString(),
              module.moduleID.toString(),
            ]);
            history.push(redirectPath);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const methods = useForm<FeedbackCommentData>({
    defaultValues:
      feedbackMessages.length > 0
        ? {
            msg_type: feedbackMessages[0].msg_type,
            msg_text: feedbackMessages[0].msg_text,
            messageID: feedbackMessages[0].messageID.toString(),
            msg_for_userID: feedbackMessages[0].msg_for_userID,
          }
        : {
            msg_type: "FBC",
            msg_text: "",
            msg_for_userID: userId,
            msg_moduleID: module.moduleID,
          },
    resolver: yupResolver(feedBackSchema),
  });

  return (
    <form onSubmit={methods.handleSubmit(handleSubmit)}>
      <div>
        <div className="p-8">
          <div className="italic">
            "Om jag vill lyckas med att föra en människa mot ett bestämt mål
            måste jag först finna henne där hon är och börja just där"
          </div>
          <div>(Sören Kierkegaard).</div>
        </div>
        <div>
          Det här citatet går att omsätta både till mötet med barnen i förskolan
          och med vuxna. Genom varje användares skriftliga eller inspelade
          reflektion kan den som leder insatsen få en uppfattning om vad
          användaren förstår, var hen befinner sig i processen och vad hen kan
          behöva stöttning i. Den som leder processen kan få många nya tankar,
          kan anpassa ledarskapet och får själv möjlighet att utvecklas och
          växa.
        </div>
        <div className="mt-4">
          Rektor eller annan ledare läser de enskilda skriftliga reflektionerna
          och analyserna och ger om möjligt kort återkoppling till de enskilda
          deltagarna i insatsen. Besluta gemensamt om och hur ofta återkoppling
          ska ges. Ibland kan en mening räcka för att användaren ska känna sig
          sedd och bekräftad.
        </div>
      </div>
      <ModuleInputWrapper className={`mt-12 mb-4`}>
        <ModuleInputHeader title={`Återkoppling`} />

        <div className="border h-80">
          <textarea
            disabled={
              (feedbackMessages.length > 0 &&
                auth?.user?.userID !== feedbackMessages[0].msg_userID) ||
              addMutation.isLoading ||
              editMutation.isLoading
            }
            {...methods.register("msg_text")}
            className="w-full h-full resize-none p-2 text-lg"
          ></textarea>
        </div>
        <FormError message={methods.formState.errors.msg_text?.message} />
      </ModuleInputWrapper>

      <FilledButton
        disabled={
          feedbackMessages.length > 0 &&
          auth?.user?.userID !== feedbackMessages[0].msg_userID
        }
        type="submit"
        size="large"
        content="Spara"
        className="disabled:cursor-default disabled:opacity-60"
      />

      {methods.formState.isSubmitting && (
        <Loader className={"ml-2"} noLabel inline />
      )}
    </form>
  );
}
