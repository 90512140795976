import { yupResolver } from "@hookform/resolvers/yup";
import { ReactElement, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import { putData } from "../../api/endpoints";
import { Notice, PostResponse } from "../../api/types";
import Header from "../../components/layout/Header";
import Loader from "../../components/layout/Loader";
import { SimpleLayout } from "../../components/layout/SimpleLayout";
import UseQueryError from "../../components/UseQueryError";
import { getValidationGenericErrorMessages, setFormErrors } from "../../util";
import { useGetNotice } from "./endpoints";
import NoticeForm, { NoticeData, schema } from "./NoticeForm";

export default function EditNotice(): ReactElement {
  const params: { id: string } = useParams();
  const noticeId: string = "id" in params ? params["id"] : "";

  const noticeQuery = useGetNotice(noticeId);

  let editNoticeMarkup: ReactElement;
  switch (noticeQuery.status) {
    case "error":
      editNoticeMarkup = <UseQueryError />;
      break;
    case "success":
      editNoticeMarkup = <EditNoticeForm notice={noticeQuery.data} />;
      break;
    default:
      editNoticeMarkup = <Loader />;
      break;
  }

  return (
    <SimpleLayout>
      <Header title="Redigera notis" />
      {editNoticeMarkup}
    </SimpleLayout>
  );
}
function EditNoticeForm(props: { notice: Notice }): ReactElement {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [serverErrors, setServerErrors] = useState<string[]>([]);

  const mutation = useMutation((noticeData: string) =>
    putData<Notice | PostResponse>(
      `messages/${props.notice.messageID}`,
      noticeData
    )
  );
  const methods = useForm<NoticeData>({
    defaultValues: {
      msg_type: props.notice.msg_type,
      msg_text: props.notice.msg_text,
      msg_mediaID: props.notice.msg_mediaID,
      msg_moduleID: props.notice.msg_moduleID,
      msg_userID: props.notice.msg_userID,
    },
    resolver: yupResolver(schema),
  });

  const handleButtonSubmit = async (
    formData: FieldValues,
    e?: React.BaseSyntheticEvent
  ): Promise<void> => {
    await mutation
      .mutateAsync(JSON.stringify(formData))
      .then((res) => {
        if ("errorsItemized" in res) {
          setFormErrors<NoticeData>(res.errorsItemized, methods);
          setServerErrors(
            getValidationGenericErrorMessages(res.errorsItemized)
          );
        } else {
          void queryClient.invalidateQueries("messages");
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);

        // TODO: HANDLE ERRORS
        // Set server errors like this
        // methods.setError(f, { message: m });
      });
  };

  return (
    <NoticeForm
      methods={methods}
      serverErrors={serverErrors}
      onFormSubmit={handleButtonSubmit}
      submitBtnText="Uppdatera notis"
    />
  );
}
