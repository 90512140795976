import { ReactElement } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

export function FormDateInput(props: {
  regRef: UseFormRegisterReturn;
  title: string;
  defaultValue: string;
}): ReactElement {
  return (
    <div
      key={props.regRef.name}
      className="border border-blue-gray flex flex-row w-full h-12 inline-block mb-4"
    >
      <h5 className="border-r border-blue-gray px-2 w-16 items-center flex font-bold">
        {props.title}{" "}
      </h5>
      <input
        className="p-4 w-full font-bold bg-none"
        {...props.regRef}
        defaultValue={props.defaultValue}
        placeholder="2021-01-01"
      />
    </div>
  );
}
