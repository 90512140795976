import { ReactElement } from "react";
import { Redirect, Route } from "react-router";
import { hasUserCapabilities } from "../api/Auth";
import { useAuth, Capabilities } from "../hooks/ProvideAuth";

export default function ProtectedRoute(props: {
  path: string;
  exact?: boolean;
  element: ReactElement;
  capabilities: Capabilities[];
}): ReactElement {
  const auth = useAuth();
  const hasAccess = hasUserCapabilities(
    props.capabilities,
    auth?.userCapabilities ? auth.userCapabilities : []
  );

  return (
    <Route
      exact={props.exact}
      path={props.path}
      render={({ location }) =>
        hasAccess ? (
          props.element
        ) : (
          <Redirect
            to={{
              pathname: "/logga-in",
              state: {
                from: location,
                alert: {
                  message: "Du har inte tillgång till detta.",
                  color: "error",
                },
              },
            }}
          />
        )
      }
    />
  );
}
