import { ReactElement, useEffect, useRef } from "react";
import { Button, FilledButton } from "./Buttons";

interface ConfirmDialogProps {
  open: boolean;
  title?: string;
  message?: string;
  onConfirm: () => void;
  onDismiss: () => void;
}
export default function ConfirmDialog(props: ConfirmDialogProps): ReactElement {
  const ref = useRef<HTMLDivElement>(null); // Create ref for confirmation box
  useEffect(() => {
    const onBodyClick = (event: MouseEvent): undefined | (() => void) => {
      // contains belong to all dom elements
      if (
        ref.current != null &&
        event.target instanceof Node &&
        ref.current.contains(event.target)
      ) {
        return;
      }
      props.onDismiss();
    };

    document.body.addEventListener("click", onBodyClick, { capture: true });

    return () => {
      document.body.removeEventListener("click", onBodyClick, {
        capture: true,
      });
    };
  }, [props]);

  if (props.open) {
    return (
      <div className="absolute bg-ocean-blue-dark bg-opacity-60 flex items-center justify-center inset-0 z-40">
        <div
          className="h-auto w-90 bg-white p-10 text-center border border-ocean-blue"
          ref={ref}
        >
          <h3 className="font-bold">{props.title}</h3>
          <div className="mt-4">{props.message}</div>

          <FilledButton content="Ja" size="medium" onClick={props.onConfirm} />
          <Button
            className="mt-4 ml-2"
            content="Nej, avbryt"
            size="medium"
            onClick={props.onDismiss}
          />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
