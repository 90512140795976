import { ReactElement, ReactNode } from "react";

export default function ViewHeader(props: {
  title: string;
  children?: ReactNode;
  className?: string;
}): ReactElement {
  return (
    <div {...props}>
      <h2 className="object-left inline pr-8 font-serif">{props.title}</h2>
      {props.children != null ? props.children : ""}
    </div>
  );
}
