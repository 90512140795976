import { ReactElement } from "react";

export default function FormLabel(props: {
  htmlFor: string;
  label: string;
}): ReactElement {
  return (
    <label {...props} className="uppercase text-blue-gray font-semibold mb-2">
      {props.label}
    </label>
  );
}
