import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ReactElement, useCallback, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { GetListResponse, Organization } from "../api/types";
import { Button } from "../components/Buttons";
import Header from "../components/layout/Header";
import Search from "../components/Search";
import { useAuth } from "../hooks/ProvideAuth";
import { isEmpty, isNotEmpty } from "../util";
import Loader from "../components/layout/Loader";
import { SimpleLayout } from "../components/layout/SimpleLayout";
import { useQueryClient, UseQueryResult } from "react-query";
import useServerPagination from "../hooks/useServerPagination";
import PaginationUI from "../components/layout/PaginationUI";
import { useGetOrganizations } from "./Organizations/useGetOrganizations";

export default function Organizations(): ReactElement {
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const queryClient = useQueryClient();
  const totalOrganizationCount = useRef(0);
  const [defaultFilterValue] = useState(
    new URLSearchParams(location.search).get("s")
  );

  const searchParams = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    return {
      page: urlParams.get("sida"),
      filter: urlParams.get("s"),
    };
  }, [location.search]);

  const query = useGetOrganizations({
    page: searchParams.page,
    filter: searchParams.filter != null ? searchParams.filter : "",
    perPage: 25,
  });

  const urlChanged = useCallback(
    (filter: string | null, pageNumber: string | null): void => {
      const params: string[] = [];
      if (isNotEmpty(filter)) {
        params.push(`s=${filter}`);
      }
      if (isNotEmpty(pageNumber)) {
        params.push(`sida=${pageNumber}`);
      }
      let path = "organisationer";
      if (params.length > 0) {
        path += `?${params.join("&")}`;
      }
      history.push(path);
    },
    [history]
  );

  const handlePaginationChange = useCallback(
    (
      pageNumber: number | undefined,
      previousPage: number | undefined
    ): void => {
      if (pageNumber !== previousPage) {
        const pageString = pageNumber != null ? pageNumber.toString() : null;
        urlChanged(searchParams.filter, pageString);
      }
    },
    [urlChanged, searchParams]
  );

  const handleSearch = useCallback(
    (searchString: string) => {
      if (isEmpty(searchString)) {
        void queryClient.invalidateQueries("organizations");
      }
      urlChanged(searchString, null);
    },
    [queryClient, urlChanged]
  );

  const paginationProps = useServerPagination(
    query.data?._meta,
    searchParams.page,
    handlePaginationChange
  );

  if (query.status === "success") {
    totalOrganizationCount.current = query.data._meta.totalCount;
  }

  return (
    <SimpleLayout>
      <Header title={`Organisationer | ${totalOrganizationCount.current}st`}>
        <div className="flex items-center self-center space-x-2">
          {["admin", "clientOrgAdmin", "orgAdmin"].includes(
            auth?.highestRole != null ? auth.highestRole : ""
          ) && (
            <Button
              icon={faPlus}
              content="Lägg till organisation"
              onClick={() => history.push("organisationer/ny")}
              size="medium"
            />
          )}
        </div>
      </Header>
      <div className="filter-search-wrapper">
        <Search
          onSearch={handleSearch}
          defaultValue={
            isNotEmpty(defaultFilterValue) ? defaultFilterValue : ""
          }
        ></Search>

        <div className="filter med:flex w-full md:w-1/3">
          <div className="dropdown-licens"></div>
          <div className="dropdown-status"></div>
        </div>
      </div>

      <div>
        <PaginationUI {...paginationProps}>
          <OrganizationsTable
            query={query}
            authIsAdmin={
              auth?.userRoles.find((ur) => ur.role_name === "admin")
                ? true
                : false
            }
          />
        </PaginationUI>
      </div>
    </SimpleLayout>
  );
}

function OrganizationsTable({
  query,
  authIsAdmin,
}: {
  query: UseQueryResult<GetListResponse<Organization>, Error>;
  authIsAdmin: boolean;
}): ReactElement {
  switch (query.status) {
    case "idle":
    case "loading":
      return <Loader />;
    case "error":
      return (
        <div>
          Nej! Något gick fel... Försök ladda om sidan eller logga in och ut.
        </div>
      );
    case "success":
      return (
        <>
          <table className="table w-full fsu-table ">
            <thead>
              <tr>
                <th>Organisation</th>
                {authIsAdmin ? <th>Uppdragsgivare</th> : null}
                <th className="w-32">Åtgärder</th>
              </tr>
            </thead>
            <tbody>
              {query.data.items.map((o: Organization) => {
                return (
                  <tr key={o.org_name}>
                    <td>
                      <Link to={`/organisationer/${o.organizationID}`}>
                        {o.org_name}{" "}
                      </Link>
                    </td>
                    {authIsAdmin ? (
                      <td>{o.client != null ? o.client.cl_name : ""}</td>
                    ) : null}
                    <td>
                      <Link to={`/organisationer/redigera/${o.organizationID}`}>
                        Redigera
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );
  }
}
