import { ReactElement } from "react";
import { Route } from "react-router";
import { useAuth } from "../hooks/ProvideAuth";
import AdminDashboard from "../pages/AdminDashboard";
import ClientDashboard from "../pages/ClientDashboard";
import OrgAdminDashboard from "../pages/OrgAdminDashboard";
import Platform from "../pages/Platform";

export default function StartPageRoute(): ReactElement {
  const auth = useAuth();
  let startPageRoute: ReactElement;

  switch (auth?.highestRole) {
    case "admin":
      startPageRoute = <Route path="/" component={AdminDashboard} />;
      break;
    case "clientOrgAdmin":
      startPageRoute = <Route path="/" component={ClientDashboard} />;
      break;
    case "orgAdmin":
      startPageRoute = <Route path="/" component={OrgAdminDashboard} />;
      break;
    case "regUser":
      startPageRoute = <Route path="/" component={Platform} />;
      break;
    default:
      startPageRoute = <Route path="/" exact component={Platform} />;
      break;
  }
  return startPageRoute;
}
